import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";

interface ITrackPayload {
  customer_id: string;
  debt_contract: string;
  partner: string;
  email: string;
}

@Injectable({
  providedIn: "root",
})
export class EngageService {
  constructor(private http: HttpClient) {}

  trackDeal(trackPayload: ITrackPayload) {
    const payload = {
      workspace_slug: environment.engageWorkspace,
      event_slug: "agreement_settled",
      created_at: Date.now(),
      ...trackPayload,
    };

    const headers = {
      "Content-Type": "application/json",
      "x-api-key": environment.engageApiKey,
    };

    if (trackPayload.partner === "havan") {
      this.http
        .post<any>(`${environment.apiEngageUrl}/v2/tracking`, payload, {
          headers,
        })
        .toPromise();
    }
  }
}
