import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AppConfig } from "@app/configs/app.config";
import { AuthenticationService } from "@app/core/authentication/authentication.service";
import { CookieHelperService } from "@app/core/services/storage/cookie-helper.service";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import EncoderUtils from "@app/core/services/utils/encoder.utils";
import { environment } from "@env/environment";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private cookieHelperService: CookieHelperService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authenticationService.hasTokenCookie() && (request['url'].indexOf(environment['rootDomain']) > -1 || request['url'].indexOf("ac-financialdata-api") > -1)) {
      request = request.clone({ setHeaders: this.createAuthenticatedHeader(request) });
    } else if (request['url'].indexOf(environment['sortingHatUrl']) > -1) {
      request = request.clone({ setHeaders: this.createSortingHatHeader(request) });
    }
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        this.handleErrorResponse(err, request['url']);
        return throwError(err);
      })
    );
  }

  private createAuthenticatedHeader(request: HttpRequest<any>): any {
    const headers = {};
    if (request.url.includes('/admin/')) {
      headers[AppConfig.auth.tokenHeader] = this.addAdminTokenHeader();
    }
    else {
      headers[AppConfig.auth.tokenHeader] = this.authenticationService.getToken();
    }

    if (
      this.cookieHelperService.check("mood_utm") &&
      request["url"].indexOf("ac-partner-has-debt-proxy") > -1
    ) {
      headers[AppConfig.auth.utmHeader] = this.createUserUtmHeader();
    }

    return headers;
  }

  private addAdminTokenHeader(): string {
    return this.authenticationService.getTokenAdminCookie();
  }

  private createSortingHatHeader(request: HttpRequest<any>): any {
    const headers = {};
    headers['x-api-key'] = environment['sortingHatKey'];

    return headers;
  }

  private createUserUtmHeader() {
    const utm = JSON.parse(this.cookieHelperService.get("mood_utm", false));
    const userContext = {
      "partner": utm["partner"],
      "utm": utm,
    };

    return EncoderUtils.encode(JSON.stringify(userContext));
  }

  private handleErrorResponse(err: HttpErrorResponse, url: string = "") {
		console.log(url)
    if ((err.status === 401 || err.status === 403) && url.indexOf(environment.financialDataApi) == -1) {
      this.authenticationService.logout();
      this.router.navigate(["/login"]);
    } else if (err.status === 429) {
      const urlArray = err.url.split("/");
      const origin = `${urlArray[0]}//${urlArray[2]}`;
      this.router.navigate(["/hit-captcha", origin], {
        queryParamsHandling: "preserve",
      });
    }
  }
}
