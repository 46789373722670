import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { EnvContextResolverService } from "../env-context-resolver/env-context-resolver.service";

@Injectable({
  providedIn: "root",
})
export class RecordHandlerService {
  constructor(private envContextResolverService: EnvContextResolverService) {}

  public startRecord(customerIdHash?: string): void {
    this.tagRecord(customerIdHash);
    this.hotjarRecord(customerIdHash);
  }

  public tagRecord(customerIdHash?: string): void {
    const envContext = this.envContextResolverService.current();

    const srec = (window as any).srec;
    if (typeof srec === "undefined") {
      console.log("[srec] not defined.");
    } else {
      srec("init", environment.uxLensToken);

      if (customerIdHash) {
        srec("uid", customerIdHash);
      }
      srec("tag", envContext.partnerId);
    }
  }

  public hotjarRecord(
    customerIdHash?: string,
    experiment?: string,
    experimentResult?: string
  ): void {
    const envContext = this.envContextResolverService.current();

    const hj = (window as any).hj;
    if (typeof hj === "undefined") {
      console.log("[hj] not defined.");
    } else {
      if (experiment) {
        hj("identify", customerIdHash, {
          experiment_key: experiment,
          variation_value: experimentResult,
        });
      }
      if (envContext.partnerId) {
        hj("identify", customerIdHash, {
          partnerId: envContext.partnerId,
        });
      }
    }
  }

  public hotjarIdentify(customerIdHash: string, hotjarObject: any): void {
    const hj = (window as any).hj;

    if (typeof hj === "undefined") {
      console.log("[hj] not defined.");
    } else {
      hj("identify", customerIdHash, {
        hotjarObject,
      });
    }
  }

  public hotjarEvent(event: string) {
    const hj = (window as any).hj;

    if (typeof hj !== "undefined") {
      hj("event", event);
    }
  }

  public configure(): void {
    setTimeout(() => this.removeMaskFromInputs(), 500);
  }

  private removeMaskFromInputs(): void {
    const inputs = document.querySelectorAll("input");
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      if (
        input.type === "text" ||
        input.type === "tel" ||
        input.type === "email"
      ) {
        input.setAttribute("data-srec-nomask", "");
        input.setAttribute("data-hj-whitelist", "");
      }
    }
  }
}
