import { LocalStorageHelperService } from "./../storage/local-storage-helper.service";
import { Injectable } from "@angular/core";
import { GtagConfig, AppConfig } from "@app/configs";
import { EnvContext } from "@app/models";
import { CookieHelperService } from "../storage/cookie-helper.service";

@Injectable({
  providedIn: "root",
})
export class GtagService {
  constructor(
    private cookieHelperService: CookieHelperService,
    private localStorageHelperService: LocalStorageHelperService
  ) { }

  addToDataLayer(params: any, properties?: any): void {
    const partnerSystem = this.recoveryPartnerSystemId();

    if (
      partnerSystem &&
      params["event"] &&
      params["event"].substr(0, 1) === "/"
    ) {
      if (!params["partner_id"]) {
        params["partner_id"] = partnerSystem.id;
      }
      if (!params["partner_name"]) {
        params["partner_name"] = partnerSystem.nome;
      }
    }

    params["userId"] = this.recoveryUserId();

    const w = window as any;
    w.dataLayer = w.dataLayer || [];
    w.dataLayer.push(params);
  }

  populateDataLayer(
    firstEnvContext: EnvContext,
    currentEnvContext: EnvContext,
    isAuthenticated: boolean
  ) {
    const params = {
      AmbienteInicial: firstEnvContext.partnerId,
      Ambiente: currentEnvContext.partnerId,
      Autenticado: isAuthenticated,
      OriginalLocation: this.getOriginalLocation(),
      OriginalSearch: this.getOriginalSearch(),
    };
    this.addToDataLayer(params);
  }

  addEventToDataLayer(eventName: string, properties?: any) {
    this.addToDataLayer({ event: eventName, ...properties } );
  }

  private getOriginalLocation(): string {
    let originalLocation: string;
    if (this.cookieHelperService.check(GtagConfig.originalLocationCookie)) {
      originalLocation = this.cookieHelperService.get(
        GtagConfig.originalLocationCookie
      );
    } else {
      originalLocation =
        document.location.protocol +
        "//" +
        document.location.host +
        document.location.pathname +
        document.location.search;
      this.cookieHelperService.add(
        GtagConfig.originalLocationCookie,
        originalLocation,
        true,
        0.05
      );
    }
    return originalLocation;
  }

  private getOriginalSearch(): string {
    return document.location.pathname + document.location.search;
  }

  private recoveryPartnerSystemId() {
    const partnerSystem = JSON.parse(
      this.localStorageHelperService.get(AppConfig.currentPartnerSystem)
    );
    return partnerSystem;
  }

  recoveryUserId(): string {
    let userID = "";

    if (this.cookieHelperService.check(AppConfig.auth.subjectCookie)) {
      const user = JSON.parse(
        this.cookieHelperService.get(AppConfig.auth.subjectCookie)
      );
      userID = user.id;
    }

    return userID;
  }
}
