import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FacebookPixelService {
  getFBP() {
    return this.getSpecificCookie("_fbp");
  }

  getFBC() {
    return this.getSpecificCookie("_fbc");
  }

  getAvailableCookies(): { fbc?: string; fbp?: string } {
    const fbp = this.getFBP();
    const fbc = this.getFBC();
    if (!fbp && !fbc) return {};

    return {
      ...(!!fbp ? { fbp } : {}),
      ...(!!fbc ? { fbc } : {}),
    };
  }

  addFacebookToRequest(request: { [keys: string]: any; parameters?: any }) {
    const cookiesMap = this.getAvailableCookies();
    if (Object.keys(cookiesMap).length) {
      const baseParams = request.parameters || {};
      request.parameters = { ...baseParams, ...cookiesMap };
    }
  }

  private getSpecificCookie(value: string) {
    const cookies = document.cookie ? document.cookie.split("; ") : [];
    const cookieString = cookies.find((row) => row.startsWith(`${value}=`));
    if (cookieString) {
      return cookieString.split("=")[1];
    }
  }
}
