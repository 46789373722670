export const MaskConfig = {
  date: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  password: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  cel: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  cpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  agencia: [/\d/, /\d/, /\d/, /\d/, '-', /\d/],
  conta: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
  dateCC: [/\d/, /\d/, '/', /\d/, /\d/],
  codeCC: [/\d/, /\d/, /\d/, /\d/],
  cep: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/,  /\d/, /\d/],
  numAdress: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  token: [/\d/, /\d/, /\d/, /\d/],
};
