import { Injectable } from '@angular/core';
import { User } from '@app/models';
import { CookieHelperService } from '@app/core/services/storage/cookie-helper.service';
import { LocalStorageHelperService } from '@app/core/services/storage/local-storage-helper.service';

@Injectable({
  providedIn: 'root'
})
export class IndicativeSenderService {

  readonly indicativeCookieKey: string = "IND_LST";
  readonly indicativeCookieExpires: number = 15; // days

  readonly indicativeCookieUtmKey: string = "IND_UTM";
  readonly indicativeCookieUtmExpires: number = 365; // days

  lastUserId: string = '';
  isFirstEvent: boolean = true;

  constructor(
    private cookieHelperService: CookieHelperService,
    private localStorageHelperService: LocalStorageHelperService,
  ) {
    //this.loadScript(); // sera carregado via Tag Manager
    this.saveCampaignParams();
    this.lastUserId = this.cookieHelperService.get(this.indicativeCookieKey);
  }


  sendEvent(eventName: string, params?: any, user?: User, retries?: number): void {    
    var iuid: string = '';

    try {

      this.isFirstEvent = false;

      this.setCustomValues(params);


      if (!user) {
        this.clearControls();
      }
    } catch (e) {
      console.log(e);
    }
    
  }

  formatDate(source: string): string {
    let result: string;
    if (source) {
      var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
      result = source.replace(pattern, '$3-$2-$1') + 'T12:00:00';
    }
    return result;
  }

  clearControls() {
  }

  getQueryParam(url, param) {
    param = param.replace(/[[]/, "\[").replace(/[]]/, "\]");
    var regexS = "[\?&]" + param + "=([^&#]*)",
      regex = new RegExp(regexS);

    let results = null;
    results = regex.exec(url);
    if (results === null || (results && typeof (results[1]) !== 'string' && results[1].length)) {
      return '';
    } else {
      return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
    }
  };

  saveCampaignParams() {
    var campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term utm_cluster utm_sou'.split(' ');
    var kw = '';
    var params = {};
    for (var index = 0; index < campaign_keywords.length; ++index) {
      kw = this.getQueryParam(document.URL, campaign_keywords[index]);
      if (kw.length) {
        params[campaign_keywords[index]] = kw;
      }
    }
    if (Object.keys(params).length > 0) {
      var utm_map = JSON.stringify(params);
      this.cookieHelperService.add(this.indicativeCookieUtmKey, utm_map, true, this.indicativeCookieUtmExpires);
    }
  }

  setCustomValues(params) {
    try {
      params.user_agent = window.navigator.userAgent;
      params.device = this.getDevice(params.user_agent);
      params.screen_avail_width = screen.availWidth;
      params.screen_avail_height = screen.availHeight;
      params.screen_width = screen.width;
      params.screen_height = screen.height;
    } catch (e) {
      console.log(e);
    }
  }

  getDevice(user_agent) {
    if (user_agent.indexOf("Android") >= 0) {
      return "Android";
    } else
    if (user_agent.indexOf("webOS") >= 0) {
      return "webOS";
    } else
    if (user_agent.indexOf("iPhone") >= 0) {
      return "iPhone";
    } else
    if (user_agent.indexOf("iPad") >= 0) {
      return "iPad";
    } else
    if (user_agent.indexOf("iPod") >= 0) {
      return "iPod";
    } else
    if (user_agent.indexOf("BlackBerry") >= 0) {
      return "BlackBerry";
    } else
    if (user_agent.indexOf("Windows Phone") >= 0) {
      return "Windows Phone";
    }
    return "Web";
  }

}