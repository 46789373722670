export const EventConstants = {
  origins: {
    ac: "AC",
    demo: "demo",
    offline: "offline",
  },

  events: {
    click_acordo: "clicou concluir acordo",
    acordo: "Acordo",
    alterarSenha: "Alterar senha",
    boleto: "Boleto",
    boletoErro: "Boleto - Erro",
    boletoEmail: "Boleto por E-mail",
    boletoSms: "Boleto por SMS",
    cadastro: "Cadastro",
    cadastroComSucesso: "Cadastro com sucesso",
    cadastroDesativado: "Cadastro Desativado",
    clickDetalheAcordo: "Click - Detalhe Acordo",
    clickSair: "Click - Sair",
    dividaLocalizada: "Dívida localizada",
    dividaEncontrada: "Visualizou Oferta",
    dividaEncontradaOculta: "Visualizou Oferta - Divida Oculta",
    dividaNaoLocalizada: "Dívida não localizada",
    dividaNaoVisualizada: "Dívida não visualizada",
    dividaVisualizada: "Dívida visualizada",
    dividaVisualizadaOculta: "Dívida visualizada - Divida Oculta",
    naoReconhecoDivida: "Não reconheço essa dívida",
    modalContinuado: "Click - TJ Continuar",
    modalFechado: "Click - TJ Cancelar",
    erro: "Erro",
    esqueciSenha: "Esqueci minha senha",
    esqueciSenhaSucesso: "Esqueci minha senha - Sucesso",
    esqueciSenhaErro: "Esqueci minha senha - Erro",
    excecao: "Excecao",
    home: "Home",
    login: "Login",
    loginAutenticacao: "Login-Autenticação",
    userAutenthicated: "Usuario Autenticado",
    loginSucesso: "Login com sucesso",
    logon: "Logon",
    loginErro: "Erro de login",
    cadastroErro: "Erro de cadastro",
    meusDados: "Meus Dados",
    meusDadosSalvo: "Meus dados - Sucesso",
    modalCodigoEnviado: "Modal envio de código com sucesso",
    modalCodigoErro: "Modal código com erro de validação",
    modalCodigoValidado: "Modal código validado com sucesso",
    modalCodigoVisualizado: "Modal de validação de código",
    msVisualizar: "MS - Visualizar",
    msVisualizarDebt: "MS Visualizar",
    showDebt: "Dívida Exibida",
    viewMoreDebts: "Click - Mostrar mais débitos",
    viewLessDebts: "Click - Mostrar menos débitos",
    opcaoSelecionada: "Dívida visualizada - Opção selecionada",
    proposta: "Proposta enviada",
    senhaAlterada: "Alterar senha - Sucesso",
    simulacao: "Simulação",
    usuarioComCadastro: "Usuário com cadastro",
    usuarioSemCadastro: "Usuário sem cadastro",
    clickAbrirChat: "Click - Abrir Chat",
    clickFecharChat: "Click - Fechar Chat",
    clickSharedWhatsapp: "Click - Compartilhamento whatsapp",
    // clickEmbarcaFacil: 'Click - Embarca Fácil',
    clickConhecaParceria: "Click - Conheça a parceria Ac/Riachuelo",
    // clickFlixResidencial: 'Click - Flix Residencial',
    // clickFlixSaude: 'Click - Produto Saúde',
    clickEmprestimoNegativadoConsultarCPF:
      "Click - LP Empréstimo - Consultar CPF",
    clickEmprestimoNegativadoSolicitar:
      "Click - LP Empréstimo - Solicitar Empréstimo",
    buscarMinhasDividas: "Buscar minhas dividas",
    criarConta: "Criar conta",
    lpcasasbahia: "LP - Casas bahia - Home",
    lppontofrio: "LP - Ponto frio - Home",
    clickPortalLojasRenner: "Click - Portal Renner",
    checkout: "Checkout",
    acLinkClick: "ac_link_click",
    tokenResend: "ac_click",
    tokenSend: "ac_send_token",
    tokenValidation: "ac_input_validation",
    hiddenDebt: "Ocultar a dívida",
    paymentMethod: "Método de pagamento",
    eventoNotificacao: "notification_update",
    descadastroTelefone: "descadastro_telefone",
    eventoDetalhesDivida: "Tela_Detalhes_Dívida",
    clickDividaVerificada: "Click - Dívida Verificada",
    eventoTransferiuDinheiro: "transferiu_dinheiro",
    descadastroContato: "descadastro_contato",
    payWithPix: "Click - Pagar com Pix",
    pageViewUrl: "Page View",
    clickIndique: "ac_indica_click",
    viewTermos: "View - Termos e condições",
    clickTermos: "Click - Termos e condições",
    clickFecharTermos: "Click - Fechar Termos e condições",
    eventoClick: "ac_click",
    eventoFormStep: "ac_form_step",
    eventoFormErro: "ac_form_error",
    clickPrecisoAjudaCadastro: "preciso_ajuda_cadastro",
    scoreSaibaMais: "score_saiba_mais",
    ofertaAds: "Oferta Terceiros Visualizada",
    leadDirecionado: "leaddirecionado",
    pageNoDebt: "pageViewNoDebt",
    userWithAds: "user_with_ad",
    abTextExperiment: "Experiment",
    scoreConfirmSolicitation: "score_confirma_solicitacao",
    scoreKbaDisabled: "score_kba_nao_habilitado",
    scoreKbaEnabled: "score_kba_habilitado",
    scoreFound: "score_localizado",
    scoreNotFound: "score_nao_localizado",
    scoreSuccessScreen: "score_tela_sucesso",
    scoreTemporaryBlocked: "score_bloqueio_temporario",
    scoreSolicitation: "score_solicitacao",
    scoreHistory: "click_score_monitorar",
    scoreKnowMore: "score_saiba_mais",
    clickFakeDoor: "Click–Fake Door",
    scoreStatusCredito: "Status de crédito",
    scoreVisualizado: "score_visualizado",
    negativacaoLocalizada: "score_negativacao_localizada",
    negativacaoExibida: "score_negativacao_exibida",
    negativacaoVisualizada: "score_negativacao_visualizada",
    scoreDetalhesNegativacao: "score_detalhes_negativacao",
    scoreErroDetalhesNegativacao: "score_erro_detalhes_negativacao",
    scoreErroHistorico: "score_erro_consulta_historico",
    scoreHistoryNotFound: "score_historico_nao_encontrado",
    scoreErroMonitorarCpf: "score_erro_monitorar_cpf",
    scoreSucessoMonitorarCpf: "score_sucesso_monitorar_cpf",
    semDividasNegativadas: "monitoramento_sem_dividas_negativadas",
    paginaParcerias: "Click_PaginaParcerias",
    pageLead: "Envio de lead",
    acessoMinhaConta: "acesso_minha_conta",
    minhaConta: "minha_conta",
    revisarDetalhesAcordo: "revisar detalhes acordo",
    Pageview: "Pageview",
    ClickBotao: "Click Botao",
    ClickCheckbox: "Click Checkbox",
    ClickLink: "Click Link",
    PreencherCampo: "Preencher Campo",
    VirtualPageview: "Virtual Pageview",
    Simulacao: "Simulacao",
    FeedbackErro: "Feedback Erro",
    CheckboxLGPD: "Checkbox LGPD",
    CheckboxKBA: "Checkbox KBA",
    ErroCadastro: "Erro Cadastro",
    loginSuccess: "Sucesso Login",
    SucessoCadastro: "Sucesso Cadastro",
    Scroll: "Scroll",
    AlterarCampo: "Alterar Campo",
    PromoImpression: "Promo Impression",
    VisualizarSecao: "Visualizar Secao",
    ClickDivida: "Click Divida",
    MonitorarCpf: "Monitorar CPF",
    PromoView: "Promo view",
    ExclusiveOfferShow: "Oferta Exclusiva Exibida",
    negativeOfferShow: "Exibicao de ofertas negativadas",
    noNegativeOfferShow: "Exibicao de ofertas nao negativadas",
    ofertasPreAprovadasDisponiveis: "Ofertas Pre Aprovadas Disponiveis",
    ofertaPreAprovadaExibida: "Oferta Pre Aprovada Exibida",
    clickCancelarAcordo: "Click - Cancelar Acordo",
    acordoCancelado: "Acordo Cancelado",
    erroForm: "Formulario - Erro",
    viewOfferTrigger: "Visualizou Gatilho de Ofertas",

    // Especifico feirão
    screeningConducted: "Triagem Realizada",
  },

  // NEW EVENTS - DASHBOARD
  noDebtsMonitoring: "monitoramento_sem_dividas_negativadas",
  debtFound: "Dívida localizada",
  debtViewed: "Dívida Exibida",
  debtNotFound: "Dívida não localizada",
  clickDebt: "Click Divida",
  simulationDone: "Simulacao Realizada",
  clickButton: "Click Botao",
  clickMenu: "Click Menu",
  pageView: "Page View",
  virtualPageView: "Virtual Pageview",
  creditStatus: "Status de crédito",
  simulateDone: "Simulacao Realizada",
  promoImpression: "Promo Impression",
  scoreKbaEnabled: "score_kba_habilitado",
  scoreKbaDisabled: "score_kba_nao_habilitado",
  scoreFound: "score_localizado",
  scoreNotFound: "score_nao_localizado",
  offerPreApprovedViewed: "Oferta Pre Aprovada Exibida",
  checkout: "Checkout",
  paymentOptionSelected: "Opcao de Pagamento Selecionada",
  clickFinishDeal: "clicou concluir acordo",
  deal: "Acordo",
  dealPaidPix: "Acordo - Pago Pix",
  generatePix: "Pix",
  errorPix: "Pix - Erro",
  errorPixApi: "Pix - Falha de Geracao",
  successPix: "Pix - Sucesso",
  generateBillet: "Boleto",
  errorBillet: "Boleto - Erro",
  successBillet: "Boleto - Sucesso",
};
