import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { GtagConfig } from "@app/configs";
import { AuthenticationService } from "@app/core/authentication";
import {
	EnvContextResolverService,
	EventConstants,
	EventHandlerService,
	GtagService,
	SeoService
} from "@app/core/services";
import { PreviousRouteService } from "@app/core/services/previous-route/previous-route.service";
import { RecordHandlerService } from "@app/core/services/record-handler/record-handler.service";
import { ScoreService } from "@app/modules/score/score.service";
import { Subscription, finalize } from "rxjs";
import { PushNotificationService } from "./core/services/push-notification/push-notification.service";
import { RedlineService } from "./core/services/redline/redline-config.service";
import { EnvContext, User } from "./models";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  user: User;
  userSubscription: Subscription;
	isCurrentSitePolicy: false

  constructor(
    private router: Router,
    private envContextResolverService: EnvContextResolverService,
    private authenticationService: AuthenticationService,
    private gtagService: GtagService,
    private seoService: SeoService,
    private recordHandlerService: RecordHandlerService,
    private previousRouteService: PreviousRouteService,
    private pushNotificationService: PushNotificationService,
    private eventHandlerService: EventHandlerService,
    private scoreService: ScoreService,
    private redlineService: RedlineService
  ) {
    this.seoService.redirectDomain();
    this.previousRouteService.start();
    this.router.events.subscribe((event) => {
			if (this.isValidEvent(event)) {
				const envContext = this.envContextResolverService.current();
        this.configureSeo(envContext);
        this.configureGtag();
        this.configureRecord();
        this.removeLoadingContainer();
        this.configureOptimize();
        this.redlineService.onRouteChange();
      }
    });
  }

  public ngOnInit(): void {
    this.redlineService.start();
    this.userSubscription = this.authenticationService.user$.subscribe(
      (user) => {
        this.user = user;
        this.trackPushNotification();
      }
    );

    this.sendEventsLoggedUser();
  }

  sendEventsLoggedUser() {
    const isAuthenticated = this.authenticationService.isAuthenticated();
		const params = new URLSearchParams(document.location.search);
		const isRedirectReact = params.get("redirect") === 'new'

    if (isAuthenticated && !isRedirectReact) {
			const eventParans = {
				accept_legal_text: true,
				fluxo: "login",
				origem_consumidor: ""
			}
      this.eventHandlerService.sendEvent(
        EventConstants.events.home,
        { ...eventParans, usuario_autenticado: true }
      );
      this.eventHandlerService.sendEvent(
        EventConstants.events.usuarioComCadastro,
        { ...eventParans, usuario_autenticado: true }
      );
      this.eventHandlerService.sendEvent(
				EventConstants.events.loginSucesso,
				{ ...eventParans, usuario_autenticado: true, step_name: 'login_padrao' }
			);
      this.eventHandlerService.sendEvent(
				EventConstants.events.logon,
				{ ...eventParans }
			);
    }

		if(isRedirectReact) {
			const newUrl = window.location.pathname;
			window.history.replaceState({}, '', newUrl);
		}

		if(isAuthenticated) {
			const onDebtsNegativeSubsription = this.scoreService
	      .getNegativeDebts()
	      .pipe(
	        finalize(() => {
	          onDebtsNegativeSubsription.unsubscribe();
	        })
	      )
	      .subscribe(
	        (response) => {
	          this.eventHandlerService.sendEvent(EventConstants.events.scoreStatusCredito, {
	            status_credito: response.length > 0 ? 'sim' : 'nao',
	          });
	        }
	      );
		}
  }

  public ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  private isValidEvent(event: any): boolean {
    return event instanceof NavigationEnd && event.url !== "/redirect-handler";
  }

  private configureSeo(envContext: EnvContext): void {
    this.seoService.configure(envContext);
  }

  private configureGtag(): void {
    this.gtagService.populateDataLayer(
      this.envContextResolverService.first(),
      this.envContextResolverService.current(),
      this.authenticationService.isAuthenticated()
    );

    setTimeout(
      () => this.gtagService.addEventToDataLayer(GtagConfig.ready),
      500
    );
  }

  private configureRecord(): void {
    this.recordHandlerService.configure();
  }

  private removeLoadingContainer(): void {
    const body = document.querySelector("body");
    const skeleton = document.querySelector(".skeleton-main");
    if (body && skeleton) {
      body.removeChild(skeleton);
    }
  }

  private trackPushNotification() {
    if (this.user) {
      this.pushNotificationService.track(this.user);
    }
  }

  private configureOptimize() {
    if (window["dataLayer"]) {
      window["dataLayer"].push({ event: "optimize.activate" });
    }
  }
}
