import { Debt, PaymentOption } from "@app/models";
import { PartnerSystemsResponse } from "@app/models/my-debts/my-debts-response.model";
export default class RLPayloadCreator {
  static EventsDebtsContext(props: {
    debt: Debt;
    paymentOption: PaymentOption;
    agreement?: any;
    customPaymentMethod?: string;
  }) {
    const { debt, paymentOption, agreement, customPaymentMethod } = props;

    return {
      correlationId: RLPayloadCreator.createCorrelationId(debt),
      partnerContext: RLPayloadCreator.partnerContext(debt?.partner),
      debtContext: RLPayloadCreator.debtContext(debt, paymentOption),
      ...(agreement && {
        agreementContext: RLPayloadCreator.agreementContext(
          agreement,
          paymentOption,
          customPaymentMethod
        ),
      }),
    };
  }

  static createCorrelationId(debt) {
    return `${debt?.id}|${
      debt?.partner?.partnerIdentifier || debt?.partnerIdentifier
    }`;
  }

  static partnerContext(partnerSystem: PartnerSystemsResponse) {
    return {
      partnerId: partnerSystem?.id,
      partnerIdentifier: partnerSystem?.partnerIdentifier,
      identifier: partnerSystem?.identifier,
    };
  }

  // TODO[Types]:  debt and paymentOption from SortingDebt
  static debtContext(debt: Debt | any, paymentOption: PaymentOption | any) {
    const splittedDateHour = (date) => date?.split("T")[0];

    const originalDate =
      splittedDateHour(debt?.detalhes?.[0]?.dataOriginalDivida) ||
      splittedDateHour(debt?.details?.[0]?.originalDebtDate);

    return {
      debtId: Number(debt?.id),
      isNegative: debt?.debtIsNegative,
      originalValue: debt?.valor || paymentOption?.originalDebtValue,
      currentValue: debt?.valorDivida || paymentOption?.currentDebtValue,
      ...(debt?.valorMinimo && { minimalValue: debt?.valorMinimo }),
      discountPercentage:
        paymentOption?.percentualDesconto || paymentOption?.discountPercentage,
      totalInstallments:
        paymentOption?.qtdParcela || paymentOption?.numberInstallment,
      product: debt?.produto || debt?.product,
      contract: debt?.contrato || debt?.contract,
      identifier: debt?.identificador || debt?.identifier,
      isLeadRedirect: debt?.partner?.isLeadRedirect,
      originalDebtDate: originalDate,
    };
  }

  static agreementContext(
    agreement,
    paymentOption: PaymentOption,
    customPaymentMethod: string
  ) {
    return {
      paymentMethod: customPaymentMethod || paymentOption?.paymentMethodName,
      agreementId: agreement?.id,
      paymentOptionId: paymentOption?.id,
      agreementValue: paymentOption?.valorAcordo,
      numberOfInstallments: paymentOption?.qtdParcela,
      installmentValue: paymentOption?.valorParcela,
      entryValue: paymentOption?.valorEntrada,
    };
  }
}
