import { registerLocaleData } from "@angular/common";
import localeExtraPt from "@angular/common/locales/extra/pt";
import localePt from "@angular/common/locales/pt";
import { LOCALE_ID, NgModule, ɵLocaleDataIndex } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ErrorHandlerModule } from "./core/error/error-handler.module";
import { HttpClientModule } from "@angular/common/http";
import { OAuthModule } from 'angular-oauth2-oidc';
import { GlobalComponentsModule } from "@app/core/components/components.module";

registerLocaleData(localePt, "pt", localeExtraPt);
localePt[ɵLocaleDataIndex.Currencies]["USD"] = ["R$", "R$"];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    ErrorHandlerModule,
		HttpClientModule,
		OAuthModule.forRoot(),
		GlobalComponentsModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "pt" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
