import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { EnvContextResolverService } from "../services";

@Injectable({
  providedIn: "root",
})
export class PartnerGuard implements CanActivate {
  constructor(
    private router: Router,
    private envContextResolverService: EnvContextResolverService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const currentEnv = this.envContextResolverService.current(state.url);
    if (!currentEnv.isPartner) {
      this.router.navigate(["/not-found"], {
        queryParams: { path: state.url },
      });
    }
    return currentEnv.isPartner;
  }
}
