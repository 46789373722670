import { Component } from '@angular/core';
import { EventConstants, EventHandlerService } from '@app/core/services';

@Component({
  selector: 'app-no-data-register',
  templateUrl: './no-data-register.component.html',
  styleUrls: ['./no-data-register.component.scss']
})
export class NoDataRegisterComponent {

  constructor(private eventHandlerService: EventHandlerService) { }

  ngOnInit(): void {
    this.eventHandlerService.sendEvent(EventConstants.events.pageViewUrl, {
      fluxo: 'cadastro',
      nome: 'usuario_figura_publica'
    });
    this.sendEventUserMinor()
    this.sendEventErroCadastro()
  }

  private sendEventUserMinor(): void {
    const properties = {
      category: 'cadastro',
      step: 4,
      step_name: 'menor 18 anos'
    }

    this.eventHandlerService.sendEvent(EventConstants.events.VirtualPageview, properties)
  }

  private sendEventErroCadastro(): void {
    const properties = {
      category: 'cadastro',
      error_type: 'autenticacao cpf',
      step: 4,
      step_name: 'menor 18 anos'
    }

    this.eventHandlerService.sendEvent(EventConstants.events.ErroCadastro, properties)
  }

  handleOkClick(): void {
    this.eventHandlerService.sendEvent(EventConstants.events.ClickBotao, {
      fluxo: 'cadastro',
      nome: 'fazer_solicitacao'
    });
    this.sendEventOkClick()
  }

  private sendEventOkClick(): void {
    const properties = {
      category: 'cadastro',
      button: 'cta ok',
      step: 4,
      step_name: 'menor 18 anos'
    }

    this.eventHandlerService.sendEvent(EventConstants.events.ClickBotao, properties)
  }

}
