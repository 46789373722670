import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AnonymousGuard, AuthenticationGuard } from './guards';
import { ApiInterceptor } from './http';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ], providers: [
    CookieService,
    AuthenticationGuard,
    AnonymousGuard,
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
  ], declarations: []
})
export class CoreModule { }
