import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AcCarouselComponent } from "./ac-carousel.component";
import { AcCarouselItemComponent } from "./ac-carousel-item/ac-carousel-item.component";
import { AcCarouselArrowsComponent } from "./ac-carousel-arrows/ac-carousel-arrows.component";
import { AcCarouselDotsComponent } from "./ac-carousel-dots/ac-carousel-dots.component";

@NgModule({
  declarations: [
    AcCarouselComponent,
    AcCarouselItemComponent,
    AcCarouselArrowsComponent,
    AcCarouselDotsComponent,
  ],
  imports: [CommonModule],
  exports: [AcCarouselComponent, AcCarouselItemComponent],
})
export class AcCarouselModule {}
