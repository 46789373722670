import { Injectable } from "@angular/core";
import { User } from "@app/models";

@Injectable({
  providedIn: "root",
})
export class PushNotificationService {
  constructor() {}

  public track(user: User): void {
    const OneSignal = window["OneSignal"];
    try {
      OneSignal.push(function () {
        OneSignal.setExternalUserId(user?.documento);
      });
    } catch (err) {
      console.log("OneSignal not configured.");
    }

    const hermesService = window["hermesService"];
    try {
      hermesService.pushOn();
      hermesService.setUserId(user?.documento);
    } catch (err) {
      console.log("Hermes Service not configured.");
    }
  }
}
