import { EventName } from "redline-client-sdk";

export const eventNames: Record<string, EventName> = {
  adViewed: "userTracking.AdViewed.v1",
  checkRegisterStatus: "userAuthentication.CheckRegisterStatus.v1",
  signUpStarted: "userAuthentication.SignUpStarted.v1",
  signUpCompleted: "userAuthentication.SignUpCompleted.v1",
  signUpErrored: "userAuthentication.SignUpErrored.v1",
  formStarted: "userTracking.FormStarted.v1",
  formContinued: "userTracking.FormContinued.v1",
  formSubmitted: "userTracking.FormSubmitted.v1",
  formErrored: "userTracking.FormErrored.v1",
  fieldSelected: "userTracking.FieldSelected.v1",
  experimentStarted: "userTracking.ExperimentStarted.v1",
  knowledgeBasedAuthenticationResult:
    "userAuthentication.KnowledgeBasedAuthenticationResult.v1",
  signInStarted: "userAuthentication.SignInStarted.v1",
  signInCompleted: "userAuthentication.SignInCompleted.v1",
  signInErrored: "userAuthentication.SignInErrored.v1",
  userAuthenticated: "userAuthentication.UserAuthenticated.v1",
  debtLocated: "debts.DebtLocated.v1",
  debtSimulated: "debts.DebtSimulated.v1",
  debtViewed: "debts.DebtViewed.v1",
  debtClicked: "debts.DebtClicked.v1",
  dealStarted: "debts.DealStarted.v1",
  dealSubmitted: "debts.DealSubmitted.v1",
  dealOutcomeReceived: "debts.DealOutcomeReceived.v1",
  dealErrored: "debts.DealErrored.v1",
  billetGenerated: "debts.BilletGenerated.v1",
  billetGenerationErrored: "debts.BilletGenerationErrored.v1",
  pixGenerated: "debts.PixGenerated.v1",
  pixGenerationErrored: "debts.PixGenerationErrored.v1",
  pixPayed: "debts.PixPayed.v1",
  scoreViewed: "score.ScoreViewed.v1",
};

export const stepId = {
  cpf: "acordo-certo-sign-up-cpf",
  kba: "acordo-certo-sign-up-kba",
  email: "acordo-certo-sign-up-email",
  phone: "acordo-certo-sign-up-phone",
  password: "acordo-certo-sign-up-password",
};

export const steps = Object.keys(stepId);
