import { Injectable } from "@angular/core";
import { User } from "@app/models";
import { GtagService } from "../gtag/gtag.service";
import { EventSender } from "./event-sender";

@Injectable({
  providedIn: "root",
})
export class DataLayerEventSenderService implements EventSender {
  constructor(private gtagService: GtagService) {}

  sendEvent(eventName: string, params?: any, user?: User): void {
    const w = window as any;

    if (params) {
      params.event = eventName;
    } else {
      params = { event: eventName };
    }

    params.userId = this.gtagService.recoveryUserId();

    if (user) {
      params.customerId = user.customerIdHash;
    }

    w.dataLayer = w.dataLayer || [];
    w.dataLayer.push(params);
  }
}
