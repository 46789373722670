import { Redline } from "redline-client-sdk";

import * as TPayload from "./types/types-debts-payload";
import * as TProps from "./types/types-debts-props";

import RLPayloadCreator from "./rl-debts-payload.creator";
import {
  controlEventSending,
  removeCtrlCookieOnEventError,
} from "./send-events-by-session";

import { AppConfig } from "@app/configs";

export default function RedlineDebts(rl: Redline) {
  return {
    /**
     * @description Deve disparar uma vez para cada parceiro onde for localizada dívida (condicionada à resposta da sorting-debts).
     *
     * @param {PartnerSystem} props.partnerSystem - Objeto que contém as informações do sistema do parceiro.
     *
     * @example
     * this.redlineService.debts.DebtLocated({
     *   partnerSystem: this.partnerSystem,
     * });
     */
    debtLocated(props: TProps.DebtLocatedProps) {
      const { partnerSystem } = props;

      const event = "debts.DebtLocated.v1";
      const idEvent = `Located|${partnerSystem.id}|${partnerSystem.partnerIdentifier}`;

      const data: TPayload.DebtLocated = {
        correlationId: RLPayloadCreator.createCorrelationId(partnerSystem),
        partnerContext: RLPayloadCreator.partnerContext(partnerSystem),
      };

      controlEventSending(AppConfig.rlCtrlDebtsCookieKey, idEvent, () => {
        rl.track(event, { ...data }).catch((err: Error) => {
          removeCtrlCookieOnEventError(
            AppConfig.rlCtrlDebtsCookieKey,
            idEvent,
            err
          );
        });
      });
    },

    /**
     * @description Deve disparar uma vez para cada dívida simulada (condicionada à resposta da sorting-debts).
     *
     * @param {paymentOption} props.paymentOption - Opção de pagamento da dívida.
     * @param {Debt} props.debt - Objeto da dívida atual.
     *
     * @example
     * this.redlineService.debts.debtSimulated({
     *   paymentOption: this.paymentOption,
     *   debt: this.debt,
     * });
     */
    debtSimulated(props: TProps.DebtSimulatedProps) {
      const event = "debts.DebtSimulated.v1";
      const idEvent = `Simulated|${props.debt.id}`;

      const data: TPayload.DebtSimulated = {
        ...RLPayloadCreator.EventsDebtsContext(props),
      };

      controlEventSending(AppConfig.rlCtrlDebtsCookieKey, idEvent, () => {
        rl.track(event, { ...data }).catch((err: Error) => {
          removeCtrlCookieOnEventError(
            AppConfig.rlCtrlDebtsCookieKey,
            idEvent,
            err
          );
        });
      });
    },

    /**
     * @description Deve disparar uma vez para cada dívida exibida no carrossel, deve estar no “campo de visão do usuário.
     *
     * @param {string} props.position - Posição da dívida no carrossel (index).
     * @param {paymentOption} props.paymentOption - Opção de pagamento da dívida.
     * @param {Debt} props.debt - Objeto da dívida atual.
     *
     * @example
     * this.redlineService.debts.debtViewed({
     *   position: parseInt(this.index) + 1,
     *   paymentOption: this.debt.paymentOptions[0],
     *   debt: this.debt,
     * });
     */
    debtViewed(props: TProps.DebtViewedProps) {
      const event = "debts.DebtViewed.v1";
      const idEvent = `Viewed|${props.debt.id}`;

      const data: TPayload.DebtViewed = {
        position: props.position,
        ...RLPayloadCreator.EventsDebtsContext(props),
      };

      controlEventSending(AppConfig.rlCtrlDebtsCookieKey, idEvent, () => {
        rl.track(event, { ...data }).catch((err: Error) => {
          removeCtrlCookieOnEventError(
            AppConfig.rlCtrlDebtsCookieKey,
            idEvent,
            err
          );
        });
      });
    },

    /**
     * @description Deve disparar quando o usuário clica no botão “Negociar Agora” de qualquer dívida no carrossel.
     *
     * @param {string} props.position - Posição da dívida no carrossel (index).
     * @param {paymentOption} props.paymentOption - Opção de pagamento da dívida.
     * @param {Debt} props.debt - Objeto da dívida atual.
     *
     * @example
     * this.redlineService.debts.debtViewed({
     *   position: parseInt(this.index) + 1,
     *   paymentOption: this.debt.paymentOptions[0],
     *   debt: this.debt,
     * });
     */
    debtClicked(props: TProps.DebtClickedProps) {
      const data: TPayload.DebtClicked = {
        position: props.position,
        ...RLPayloadCreator.EventsDebtsContext(props),
      };

      rl.track("debts.DebtClicked.v1", { ...data });
    },

    /**
     * @description Dispara quando um boleto é gerado com sucesso através da API.
     *
     * @param {string} props.barcode - Código de barras do boleto gerado, responseBillet?.linhaDigitavel.
     * @param {paymentOption} props.paymentOption - Opção de pagamento selecionada.
     * @param {Debt} props.debt - Objeto da dívida atual.
     * @param {any} props.agreement - Objeto do acordo fechado.
     *
     * @example
     * this.redlineService.debts.billetGenerated({
     *   barcode: responseBillet?.linhaDigitavel,
     *   paymentOption: this.paymentOption,
     *   debt: this.debt,
     *   agreement: this.agreement,
     * });
     */
    billetGenerated(props: TProps.BilletGeneratedProps) {
      const data: TPayload.BilletGenerated = {
        barcode: props?.barcode || "",
        ...RLPayloadCreator.EventsDebtsContext(props),
      };

      rl.track("debts.BilletGenerated.v1", { ...data });
    },

    /**
     * @description Dispara em caso de erro ao tentar gerar o boleto através da chamada à API /billet.
     *
     * @param {string} props.errorDetails - Detalhes adicionais sobre o erro ocorrido.
     * @param {string} props.errorMessage - Mensagem descritiva do erro.
     * @param {string} props.errorType - Tipo de erro ocorrido.
     * @param {paymentOption} props.paymentOption - Opção de pagamento selecionada.
     * @param {Debt} props.debt - Objeto da dívida atual.
     * @param {any} props.agreement - Objeto do acordo fechado.
     *
     * @example
     * this.redlineService.debts.billetGenerationErrored({
     *		errorDetails: err.error?.fields?.[0]?.field_name,
     *		errorMessage: err.error?.message,
     *		errorType: err.error?.error_slug,
     *  	paymentOption: this.paymentOption,
     *  	debt: this.debt,
     *  	agreement: this.agreement,
     * });
     */
    billetGenerationErrored(props: TProps.BilletGenerationErroredProps) {
      const { errorDetails, errorMessage, errorType } = props;

      const data: TPayload.BilletGenerationErrored = {
        errorDetails,
        errorMessage,
        errorType,
        ...RLPayloadCreator.EventsDebtsContext(props),
      };

      rl.track("debts.BilletGenerationErrored.v1", { ...data });
    },

    /**
     * @description Dispara quando um código PIX é gerado com sucesso através da API.
     *
     * @param {string} props.pixCode - Código PIX gerado.
     * @param {paymentOption} props.paymentOption - Opção de pagamento selecionada.
     * @param {Debt} props.debt - Objeto da dívida atual.
     * @param {any} props.agreement - Objeto do acordo fechado.
     *
     * @example
     * this.redlineService.debts.pixGenerated({
     *   pixCode: responsePix?.data?.pix_code,
     *   paymentOption: this.paymentOption,
     *   debt: this.debt,
     *   agreement: this.agreement,
     * });
     */
    pixGenerated(props: TProps.PixGeneratedProps) {
      const data: TPayload.PixGenerated = {
        pixCode: props.pixCode || "",
        ...RLPayloadCreator.EventsDebtsContext(props),
      };

      rl.track("debts.PixGenerated.v1", { ...data });
    },

    /**
     * @description Dispara em caso de erro ao tentar gerar o PIX através da chamada à API para /qrcode.
     *
     * @param {string} props.errorDetails - Detalhes adicionais sobre o erro ocorrido.
     * @param {string} props.errorMessage - Mensagem descritiva do erro.
     * @param {string} props.errorType - Tipo de erro ocorrido.
     * @param {paymentOption} props.paymentOption - Opção de pagamento selecionada.
     * @param {Debt} props.debt - Objeto da dívida atual.
     * @param {any} props.agreement - Objeto do acordo fechado.
     *
     * @example
     * this.redlineService.debts.pixGenerationErrored({
     *		errorDetails: err.error?.fields?.[0]?.field_name,
     *		errorMessage: err.error?.message,
     *		errorType: err.error?.error_slug,
     *  	paymentOption: this.paymentOption,
     *  	debt: this.debt,
     *  	agreement: this.agreement,
     * });
     */
    pixGenerationErrored(props: TProps.PixGenerationErroredProps) {
      const { errorDetails, errorMessage, errorType } = props;

      const tempValidateErrorMessage = () => {
        if (typeof errorMessage === "string" && errorMessage?.length === 0)
          return "Error message is empty";
        return errorMessage;
      };

      const tempValidateErrorType = () => {
        if (typeof errorType === "string" && errorType?.length === 0)
          return "Error message is empty";
        return errorType;
      };

      const data: TPayload.PixGenerationErrored = {
        errorDetails,
        errorMessage: tempValidateErrorMessage(),
        errorType: tempValidateErrorType(),
        ...RLPayloadCreator.EventsDebtsContext(props),
      };
      rl.track("debts.PixGenerationErrored.v1", { ...data });
    },

    /**
     * @description Dispara quando o status do PIX evolui para pago através da chamada à API de status do PIX.
     *
     * @param {string} props.pixCode - Código PIX gerado.
     * @param {paymentOption} props.paymentOption - Opção de pagamento selecionada.
     * @param {Debt} props.debt - Objeto da dívida atual.
     * @param {any} props.agreement - Objeto do acordo fechado.
     *
     * @example
     * this.redlineService.debts.pixPayed({
     *   pixCode: responsePix?.data?.pix_code,
     *   paymentOption: this.paymentOption,
     *   debt: this.debt,
     *   agreement: this.agreement,
     * });
     */
    pixPayed(props: TProps.PixPayedProps) {
      const idEvent = `PixPayed|${props?.pixCode}`;

      const data: TPayload.PixPayed = {
        pixCode: props.pixCode || "",
        ...RLPayloadCreator.EventsDebtsContext(props),
      };
      controlEventSending(AppConfig.rlCtrlPaymentCookieKey, idEvent, () => {
        rl.track("debts.PixPayed.v1", { ...data });
      });
    },

    /**
     * @description Rastreia o evento de quando o usuário chega na página /confirmacao.
     *
     * @param {paymentOption} props.paymentOption - Opção de pagamento selecionada.
     * @param {Debt} props.debt - Objeto da dívida atual.
     *
     * @example
     * this.redlineService.debts.agreementStarted({
     *   paymentOption: this.paymentOption,
     *   debt: this.debt,
     * });
     */
    agreementStarted(props: TProps.AgreementStartedProps) {
      const data = {
        ...RLPayloadCreator.EventsDebtsContext(props),
      };

      rl.track("debts.AgreementStarted.v1", { ...data });
    },

    /**
     * @description Rastreia o evento de clique do usuário no botão "Concluir acordo" na página /confirmacao.
     *
     * @param {paymentOption} props.paymentOption - Opção de pagamento selecionada.
     * @param {Debt} props.debt - Objeto da dívida atual.
     * @param {any} props.agreement - Objeto do acordo fechado.
     *
     * @example
     * this.redlineService.debts.agreementSubmitted({
     *   paymentOption: this.paymentOption,
     *   debt: this.debt,
     *   agreement: {},
     * });
     */
    agreementSubmitted(props: TProps.AgreementSubmittedProps) {
      rl.track("debts.AgreementSubmitted.v1", {
        ...RLPayloadCreator.EventsDebtsContext(props),
      });
    },

    /**
	 * @description Rastreia o evento da opção de pagamento selecionada.
	 *
	 * @param {boolean} props.autofilled - Valor que se autopreenchido deve ser verdadeiro, caso seja um valor selecionado pelo usuário, deve ser falso.
	 * @param {string} props.fieldId - Identificador único do campo.
	 * @param {string} props.fieldLabel - Label do campo exibida para o usuário.
	 * @param {string} props.fieldName - Nome do campo.
	 * @param {string} props.fieldValue - Identificador do valor do campo selecionado.
	 *
	 * @example
	 * this.redlineService.debts.fieldSelected({
			userInputField: {
				autofilled: isAutofilled,
				fieldId: "example_id",
				fieldLabel: "label_example",
				fieldName: "field_name_example",
				fieldValue: 123456,
			},
	 * });
	 */
    fieldSelected(props: TProps.FieldSelectedProps) {
      const { userInputField } = props;

      rl.track("userTracking.FieldSelected.v1", {
        userInputField,
      });
    },

    /**
     * @description Rastreia o evento de sucesso no retorno da /fechar, sinalizando que o acordo foi fechado com êxito.
     *
     * @param {paymentOption} props.paymentOption - Opção de pagamento selecionada.
     * @param {Debt} props.debt - Objeto da dívida atual.
     * @param {any} props.agreement - Objeto do acordo fechado.
     *
     * @example
     * this.redlineService.debts.agreementOutcomeReceived({
     *  	paymentOption: this.paymentOption,
     *  	debt: this.debt,
     *  	agreement: this.agreement,
     * });
     */
    agreementOutcomeReceived(props: TProps.AgreementOutcomeReceivedProps) {
      rl.track("debts.AgreementOutcomeReceived.v1", {
        ...RLPayloadCreator.EventsDebtsContext(props),
      });
    },

    /**
     * @description Rastreia o evento de quando ocorre um erro na chamada para a rota /fechar.
     *
     * @param {paymentOption} props.paymentOption - Opção de pagamento selecionada.
     * @param {Debt} props.debt - Objeto da dívida atual.
     * @param {any} props.agreement - Objeto do acordo fechado.
     * @param {string} props.errorDetails - Detalhes adicionais sobre o erro ocorrido.
     * @param {string} props.errorMessage - Mensagem descritiva do erro.
     * @param {string} props.errorType - Tipo de erro ocorrido.
     *
     *  @example
     * this.redlineService.debts.agreementErrored({
     *		errorDetails: err.error?.fields?.[0]?.field_name,
     *		errorMessage: err.error?.message,
     *		errorType: err.error?.error_slug,
     *  	paymentOption: this.paymentOption,
     *  	debt: this.debt,
     *  	agreement: {},
     * });
     *
     */
    agreementErrored(props: TProps.AgreementErroredProps) {
      const { errorDetails, errorMessage, errorType } = props;

      const data = {
        errorDetails,
        errorMessage,
        errorType,
        ...RLPayloadCreator.EventsDebtsContext(props),
      };
      rl.track("debts.AgreementErrored.v1", { ...data });
    },
  };
}
