import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";

import { AuthenticationService } from "@app/core/authentication/authentication.service";

@Injectable({
  providedIn: "root",
})
export class AnonymousGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isAuthenticated = this.authenticationService.isAuthenticated();
    if (isAuthenticated) {
      this.router.navigate(["/redirect-handler"], {
        queryParamsHandling: "merge",
        queryParams: next.queryParams,
        skipLocationChange: true,
      });
    }
    return !isAuthenticated;
  }
}
