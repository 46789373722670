import { Redline } from "redline-client-sdk";

import * as TPayload from "./types-score-payload";

export default function RedlineDebts(rl: Redline) {
  return {
    /**
     * @description O evento rastreia quando o usuário visualizou o score.
     *
     * @param {number} props.score - Valor do score.
     *
     * @example
     * this.redlineService.scoreViewed({
     *   score: 780,
     * });
     */
    scoreViewed(props: TPayload.ScoreViewed) {
      rl.track("score.ScoreViewed.v1", {
        ...props,
      });
    },
  };
}