export class SeoContext {
  title: string;
  description: string;
  mainBannerTitle: string;
  mainBannerSubtitle: string;
  btnBannerCampaign: string;
  mainBannerCTA: string;

  constructor(
    title: string,
    description?: string,
    mainBannerTitle?: string,
    mainBannerSubtitle?: string,
    btnBannerCampaign?: string,
    mainBannerCTA?: string
  ) {
    this.title = title;
    this.description = description;
    this.mainBannerTitle = mainBannerTitle;
    this.mainBannerSubtitle = mainBannerSubtitle;
    this.btnBannerCampaign = btnBannerCampaign;
    this.mainBannerCTA = mainBannerCTA;
  }
}
