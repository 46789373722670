import { Redline } from "redline-client-sdk";

import * as TPayload from "./types/types-userAuthentication-payload";

export default function RedlineUserAuthentication(rl: Redline) {
  return {
    /**
     * @description Rastreia eventos de autenticação do usuário ao entrar na área logada.
     * O evento indica o método de entrada do usuário, podendo ser login, cadastro ou autenticação prévia(cookie).
     *
     * @param {("sign-in" | "sign-up" | "cached-sign-in")} props.authenticationType - ("sign-in" | "sign-up" | "cached-sign-in") Tipo de autenticação.
     * @param {string} props.correlationId - [opcional] - (string) Identificador único para correlacionar eventos.
     * @param {string} props.customerId - documento - (string) "CPF com máscara ex: 000.111.222-33".
     *
     * @example
     * this.redlineService.userAuthenticated({
     * 	 correlationId: "sakdd2k3rov0soed0we0"
     *   authenticationType: "sign-in",
     *   customerId: "000.111.222-33",
     * });
     */
    userAuthenticated(props: TPayload.UserAuthenticated) {
      // rl.debounceBy("session", {
      //   maxCalls: 1,
      //   uniqueId: `userAuthenticated-${props.customerId}`,
      // }).

			rl.track("userAuthentication.UserAuthenticated.v1", {
        ...props,
      });
    },

    /**
     * @description O evento rastreia o CPF do nosso usuário. Deve ser disparado junto com o evento CheckRegisterStatus e com o evento de UserAuthenticated.
     *
     * @param {string} props.document - documento - (string) "CPF com máscara ex: 000.111.222-33".
     *
     * @example
     * this.redlineService.userIdentified({
     *   document: "000.111.222-33",
     * });
     */
    userIdentified(props: TPayload.UserIdentified) {
      // rl.debounceBy("session", {
      //   maxCalls: 1,
      //   uniqueId: `userIdentified-${props.document}`,
      // }).

			rl.track("userAuthentication.UserIdentified.v1", {
        ...props,
      });
    },
  };
}
