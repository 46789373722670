import { Component, OnInit } from '@angular/core';
import { AcNotifyService } from '@app/core/services';

@Component({
  selector: 'app-ac-notify',
  templateUrl: './ac-notify.component.html',
  styleUrls: ['./ac-notify.component.scss']
})
export class AcNotifyComponent implements OnInit {
	showError: boolean = true;
  status: string = 'error';
  message: string = '';

	constructor(private acNotifyService: AcNotifyService) {}

	ngOnInit() {
    this.acNotifyService.errorOccurred.subscribe((message: string) => {
      this.showError = true;
      this.status = 'error active';
      this.message = message;

      setTimeout(() => {
				this.status = 'error';
        this.message = '';
      }, 6000);
    });
  }

  closeMessage(){
		this.status = 'error'

		setTimeout(() => {
			this.showError = false;
		}, 1000);
  }
}
