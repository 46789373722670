export default class HtmlUtils {
  static addCanonical(href: string): void {
    this.removeCanonicalLink();
    const canonicalElement: HTMLLinkElement = document.createElement("link");
    canonicalElement.setAttribute("rel", "canonical");
    canonicalElement.setAttribute("href", href);
    document.head.appendChild(canonicalElement);
  }

  static removeCanonicalLink(): void {
    const head = document.head;
    const els = document.querySelectorAll<HTMLElement>('[rel="canonical"]');
    Array.prototype.filter.call(els, (el: HTMLElement) => {
      head.removeChild(el);
    });
  }
}
