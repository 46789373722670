export class SharerParams {
    classGroup: string;
    sharer: string;
    message: string;
    title: string;
    url: string;
    web: boolean;
    icon: string;

    constructor(classGroup: string, sharer: string, message: string, title: string, url: string,  web: boolean, icon: string) {
        this.classGroup = classGroup;
        this.sharer = sharer;
        this.message = message;
        this.title = title;
        this.url = url;
        this.web = web;
        this.icon = icon;
    }

}