<div class="register-page">
  <div class="register-container">
    <img
      src="assets/icons/ac-icon.svg"
      alt="acordo-certo-logo"
      class="ac-logo -m-bottom-3"
    />
    <h2 class="description">
      Ops! <strong>Não conseguimos prosseguir com seu cadastro por aqui</strong>
    </h2>
    <p class="error-description">
      Identificamos que o seu CPF é de uma pessoa menor de 18 anos.
      Infelizmente, neste caso, não podemos continuar com o seu cadastro.
    </p>

    <a href="https://acordocerto.com.br">
      <button
        type="submit"
        class="button-primary -m-auto"
        (click)="handleOkClick()"
      >
        <span>Ok, entendi</span>
      </button>
    </a>

    <div class="error-image-container">
      <img class="error-image" src="assets/img/pesquisa-erro.svg" alt="" />
    </div>
  </div>

  <div class="side-container">
    <img class="side-image" src="assets/img/pesquisa-erro.svg" alt="" />
  </div>
</div>
