import { SeoContext } from './seo-context.model';

export const SeoConfig = {
    marketplace: new SeoContext('Negociar Dívidas Online | Acordo Certo', 'Está negativado? Negocie sua dívida e quite já seus débitos. O site da Acordo Certo é confiável, rápido e seguro!'),
    claro: new SeoContext('Negociação e limpa nome Claro | Acordo Certo', 'Buscando limpar nome na Claro? A Acordo Certo é a mais confiável e segura para negociar seus débitos!'),
    claroresidencial: new SeoContext('Negocie Fácil sua dívida na Minha Claro Residencial | Acordo Certo'),
    santander: new SeoContext('Negociar Dívida Santander? Acordos com desconto até 90% | Acordo Certo', 'Negocie sua dívida do Santander na Acordo Certo, a maior empresa de negociação de dívidas do Brasil. Faça um acordo online e consiga até 90% de desconto!'),
    santanderfin: new SeoContext('Negociar Dívida Santander? Acordos com desconto até 90% | Acordo Certo', 'Negocie sua dívida do Santander na Acordo Certo, a maior empresa de negociação de dívidas do Brasil. Faça um acordo online e consiga até 90% de desconto!'),
    tribanco: new SeoContext('Negociar Dívidas Tricard | Acordo Certo'),
    pernambucanas: new SeoContext('Negociar Dívidas Pernambucanas | Acordo Certo'),
    portoseguro: new SeoContext('Negociar Dívidas Porto Seguro | Acordo Certo'),
    colombo: new SeoContext('Negociar Dívidas Colombo | Acordo Certo'),
    credz: new SeoContext('Negocie sua dívida no Credz em um acordo online | Adoro Certo'),
    biva: new SeoContext('Negociar Dívidas Biva | Acordo Certo'),
    pagbank: new SeoContext('Negociar Dívidas PagBank | Acordo Certo'),
    kroton: new SeoContext('Negociar Dívidas com diversas universidades | Acordo Certo'),
    anhangueraead: new SeoContext('Negociar Dívidas Anhanguera | Acordo Certo'),
    anhanguera: new SeoContext('Negociar Dívidas Anhanguera | Acordo Certo'),
    pitagoras: new SeoContext('Negociar Dívidas Pitágoras | Acordo Certo'),
    fama: new SeoContext('Negociar Dívidas Fama | Acordo Certo'),
    unic: new SeoContext('Negociar Dívidas Unic | Acordo Certo'),
    uniderp: new SeoContext('Negociar Dívidas Uniderp | Acordo Certo'),
    unime: new SeoContext('Negociar Dívidas Unime | Acordo Certo'),
    unopar: new SeoContext('Negociar Dívidas Unopar | Acordo Certo'),
    riachuelo: new SeoContext('Negociação e limpa nome Riachuelo | Acordo Certo', 'Nome sujo na Riachuelo? A Acordo Certo é a mais confiável e segura para sua negociação. Clique e quite já!'),
    ipanema: new SeoContext('Negociar Dívidas Ipanema | Acordo Certo'),
    telefonia: new SeoContext('Quer negociar dívidas da TIM, Oi, Claro ou NET? | Acordo Certo'),
    bvfinanceira: new SeoContext('Negociar Dívidas BV | Acordo Certo'),
    dscards: new SeoContext('Negociar Dívidas Di Santinni | Acordo Certo'),
    credigy: new SeoContext('Negociar Dívidas da Crediativos | Acordo Certo'),
    fundoatlantico: new SeoContext('Negociar Dívidas do Fundo Atlântico | Acordo Certo'),
    recovery: new SeoContext('Negociação e limpa nome Recovery | Acordo Certo', 'A Acordo Certo é confiável e segura para você negociar com a Recovery e conquistar o nome limpo. Quite já!'),
    itau: new SeoContext('Negociar Dívidas Itaú | Acordo Certo'),
    viavarejo: new SeoContext('Negociar Dívidas Via Varejo | Acordo Certo'),
    casasbahia: new SeoContext('Negociação e limpa nome Casas Bahia | Acordo Certo', 'Sua renegociação com as Casas Bahia de forma rápida e segura! Pague boletos com desconto na Acordo Certo!', 'Renegocie com a Casas Bahia sem sair de casa, de forma 100% segura.'),
    pontofrio: new SeoContext('Negociação Ponto Frio | Acordo Certo', 'Renegocie Ponto Frio e garanta seu bem-estar financeiro! A Acordo certo é segura, rápida e confiável.', 'Negocie seu acordo Ponto Frio sem sair de casa, de forma 100% segura.'),
    itapeva: new SeoContext('Negociar Dívidas Itapeva | Acordo Certo'),
    brk: new SeoContext('Negociar Dívidas BRK Ambiental | Acordo Certo'),
    brkmairinque: new SeoContext('Negociar Dívidas Saneaqua Mairinque | Acordo Certo'),
    vivo: new SeoContext('Negociação Limpa Nome Vivo | Acordo Certo', 'Nome sujo na Vivo? A Acordo Certo é confiável e segura para negociar e ficar livre das dívidas! Clique e quite já!'),
    lojasrenner: new SeoContext('Negociar Dívidas Lojas Renner | Acordo Certo'),
    bancopan: new SeoContext('Negociar Dívidas Banco Pan | Acordo Certo'),
    oi: new SeoContext('Negociar Dívidas Oi | Acordo Certo'),
    havan: new SeoContext('Negocie Fácil sua dívida na Havan | Acordo Certo'),
    uzecartoes: new SeoContext('Negocie Fácil sua dívida na Uze Cartões | Acordo Certo'),
    ativossa: new SeoContext('Acordo Certo Ativos S.A.', 'Quite sua dívida da Ativos S.A. na maior empresa de negociação de dívidas online do Brasil.'),
    bradesco: new SeoContext('Negociar Dívidas Bradesco | Acordo Certo'),
    credsystem: new SeoContext('Negociar Dívidas Credsystem | Acordo Certo'),
    sky: new SeoContext('Negociar Dívidas Sky | Acordo Certo'),
};
