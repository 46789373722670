import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, catchError, map, mergeMap, of } from 'rxjs';
import { BaseResponse } from './models/base.model';
import { NegativeDebt, NegativeDebtGroup, NegativeDebtResponse } from './models/negative-debts.model';

@Injectable({
  providedIn: 'root'
})
export class NegativeDebtsService {

  private baseUrl: string
  
  constructor(private http: HttpClient) { 
    this.baseUrl = environment.financialDataApi
  }

  getGroupedNegativeDebts(): Observable<NegativeDebtGroup[]> {
    return this.getNegativeDebtsFromApi()
      .pipe(map(response => response.data.negativeDebts))
  }

  getAllNegativeDebts(): Observable<NegativeDebt[]> {
    return this.getNegativeDebtsFromApi()
      .pipe(map(response => response.data.negativeDebts))
      .pipe(mergeMap(groupedNegativeDebts => groupedNegativeDebts.map(group => group.debts)))
  }
  
  private getNegativeDebtsFromApi(): Observable<BaseResponse<NegativeDebtResponse>> {
    return this.http.get<BaseResponse<NegativeDebtResponse>>(`${this.baseUrl}/api/v1/negative`)
      .pipe(catchError(err => of({ data: { negativeDebts: [] } } as BaseResponse<NegativeDebtResponse>)))
  }
  
}
