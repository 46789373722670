import { Injectable } from "@angular/core";
import EncoderUtils from "@app/core/services/utils/encoder.utils";
import { environment } from "@env/environment";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class CookieHelperService {
  private path = "/";

  constructor(private cookieService: CookieService) {}

  check(key: string): boolean {
    return this.cookieService.check(key);
  }

  get(key: string, decrypt?: boolean): string {
    const valueEncoded = this.cookieService.get(key);
    return this.shouldEncryptOrDecrypt(valueEncoded, decrypt)
      ? EncoderUtils.decode(valueEncoded)
      : valueEncoded;
  }

  add(
    key: string,
    value: string,
    encrypt?: boolean,
    expires?: number | Date
  ): void {
    expires = expires || 30;
    const valueEncoded = this.shouldEncryptOrDecrypt(value, encrypt)
      ? EncoderUtils.encode(value)
      : value;
    if (environment.production) {
      this.cookieService.set(
        key,
        valueEncoded,
        expires,
        this.path,
        environment.rootDomain,
        true
      );
    } else {
      this.cookieService.set(key, valueEncoded, expires, this.path);
    }
  }

  remove(key: string): void {
		this.cookieService.delete(key, this.path, 'acordocerto.com.br');
		this.cookieService.delete(key, this.path, environment.rootDomain);
		this.cookieService.delete(key, this.path);
  }

  removeWithKeyIncludes(partialKey: string): void {
    Object.keys(this.cookieService.getAll()).forEach((key) => {
      if (key.includes(partialKey)) {
        this.remove(key);
      }
    });
  }

  private shouldEncryptOrDecrypt(value?: string, encrypt?: boolean) {
    return value && (encrypt === undefined || encrypt);
  }
}
