import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { eventNames } from "./constants";
import { FormatterRedlineService } from "./formatters";

import { createID, createRedline } from "redline-client-sdk";

import RedlineDebts from "./debts";
import RedlineScore from "./score";
import RedlineUserAuthentication from "./userAuthentication";

import { getCorrelationId } from "../utils/getCorrelationId";
import {
  formatFireboltFormFieldValues,
  generateFormEventsDefaultPayload,
  getDataProps,
} from "./payloadFormatters";

import {
  DecisionEventProps,
  DecisionOutcomeReceivedCreditEventProps,
  DecisionProvidedProps,
  ElementClickedEventProps,
  ExperimentStartedProps,
  FormEventProps,
  ProductCreditEventProps,
  ProductListFilteredCreditEventProps,
  ProductListViewedCreditEventProps,
  RedirectRequestEventProps,
  RedirectRequestedCreditEventProps,
} from "@app/models/redline/redline.model";
import { RlUserAuthenticationService } from "./userAuthentication/rl-user-authentication.service";
import { CookieHelperService } from "../storage/cookie-helper.service";
@Injectable({
  providedIn: "root",
})
export class RedlineService {
  alreadyRanFirstPageload = false;
  redline = null;
  debts = null;
  score = null;
  userAuthentication = null;

  constructor(
    private formatterRedlineService: FormatterRedlineService,
    private rlUserAuthenticationService: RlUserAuthenticationService,
    private cookieHelperService: CookieHelperService
  ) {
		this.redline = createRedline({
      writeKey: environment.redlineWriteKey,
      mode: environment.production ? "prod" : "dev",
      appName: "ac-app-angular",
      appVersion: this.cookieHelperService.get("appVersion", false),
    });

    this.debts = RedlineDebts(this.redline);
    this.score = RedlineScore(this.redline);
    this.userAuthentication = RedlineUserAuthentication(this.redline);
	}

  start() {
    this.redline.start();
  }

  pageNotFound() {
    this.redline.track("experience.PageNotFound.v1", {});
  }

  formStarted({ partnerData, formData }: FormEventProps) {
    const event = "userTracking.FormStarted.v1";

    const { position, stepSlug, fireboltConfigName, partnerSlug } =
      getDataProps({ partnerData, formData });

    const payload = {
      correlationId: getCorrelationId(fireboltConfigName),
      ...generateFormEventsDefaultPayload("formStarted", partnerSlug, {
        stepSlug,
        position,
      }),
    };

    this.redline.track(event, payload);
  }

  formContinued({ partnerData, formData }: FormEventProps) {
    const event = "userTracking.FormContinued.v1";

    const {
      position,
      stepSlug,
      fireboltConfigName,
      partnerSlug,
      capturedData,
      fields,
    } = getDataProps({ partnerData, formData });

    const formContinuedPayload = {
      correlationId: getCorrelationId(fireboltConfigName),
      ...generateFormEventsDefaultPayload("formContinued", partnerSlug, {
        stepSlug,
        position,
      }),
      field: [...formatFireboltFormFieldValues(fields, stepSlug, capturedData)],
    };

    this.redline.track(event, formContinuedPayload);
  }

  formBack({ partnerData, formData }: FormEventProps) {
    const event = "userTracking.FormBacked.v1";
    const {
      position,
      stepSlug,
      fireboltConfigName,
      partnerSlug,
      capturedData,
      fields,
    } = getDataProps({ partnerData, formData });

    const formContinuedPayload = {
      correlationId: getCorrelationId(fireboltConfigName),
      ...generateFormEventsDefaultPayload("formBacked", partnerSlug, {
        stepSlug,
        position,
      }),
      field: [...formatFireboltFormFieldValues(fields, stepSlug, capturedData)],
    };

    this.redline.track(event, formContinuedPayload);
  }

  formSubmitted({ partnerData, formData }: FormEventProps) {
    const event = "userTracking.FormSubmitted.v1";
    const {
      position,
      stepSlug,
      fireboltConfigName,
      partnerSlug,
      capturedData,
      fields,
    } = getDataProps({ partnerData, formData });

    const formContinuedPayload = {
      correlationId: getCorrelationId(fireboltConfigName),
      ...generateFormEventsDefaultPayload("formSubmitted", partnerSlug, {
        stepSlug,
        position,
      }),
      field: [...formatFireboltFormFieldValues(fields, stepSlug, capturedData)],
    };

    this.redline.track(event, formContinuedPayload);
  }

  formError({ partnerData, formData, errorMessage }: FormEventProps) {
    const event = "userTracking.FormErrored.v1";
    const unhandledErrorMsg = ["application_error"];

    const { position, stepSlug, partnerSlug } = getDataProps({
      partnerData,
      formData,
    });

    const errorType = unhandledErrorMsg.includes(errorMessage)
      ? "unhandled"
      : "predicted";

    const formContinuedPayload = {
      errorMessage: errorMessage,
      errorDetails: errorMessage,
      errorType: errorType,
      ...generateFormEventsDefaultPayload("formErrored", partnerSlug, {
        stepSlug,
        position,
      }),
    };

    this.redline.track(event, formContinuedPayload);
  }

  redirectRequested({
    redirectRequestedId,
    originId,
    redirectRequestedUrl,
  }: RedirectRequestEventProps) {
    const event = "experience.RedirectRequested.v1";

    const formContinuedPayload = {
      redirectRequestedId,
      originId,
      redirectRequestedUrl,
    };

    this.redline.track(event, formContinuedPayload);
  }

  decisionRequested({ partnerData }: DecisionEventProps) {
    const event = "sortingHat.DecisionRequested.v1";
    const { fireboltConfigName, partnerSlug } = getDataProps({ partnerData });

    const payload = {
      correlationId: getCorrelationId(fireboltConfigName),
      cardSlug: partnerSlug,
      partnerSlug: partnerSlug,
      sortingHatVersion: "v3",
    };

    this.redline.track(event, payload);
  }

  decisionAccepted({
    partnerData,
    formData,
    acceptedOfferSlug,
  }: FormEventProps) {
    const event = "sortingHat.DecisionAccepted.v1";
    const { position, stepSlug, fireboltConfigName, partnerSlug } =
      getDataProps({ partnerData, formData });

    const acceptedOffer = {
      accepted: {
        cardSlug: acceptedOfferSlug || partnerSlug,
        partner: partnerSlug,
      },
      sortingHatVersion: "v3",
      workflowId: "ac-tree",
    };

    const payload = {
      correlationId: getCorrelationId(fireboltConfigName),
      ...acceptedOffer,
      ...generateFormEventsDefaultPayload("decisionAccepted", partnerSlug, {
        stepSlug,
        position,
      }),
    };

    this.redline.track(event, payload);
  }

  decisionDenied({ partnerData }: DecisionEventProps) {
    const event = "sortingHat.DecisionDenied.v1";
    const { fireboltConfigName, partnerSlug } = getDataProps({ partnerData });

    const acceptedOffer = {
      accepted: {
        cardSlug: partnerSlug,
        partner: partnerSlug,
      },
      sortingHatVersion: "v3",
      workflowId: "ac-tree",
    };

    const payload = {
      correlationId: getCorrelationId(fireboltConfigName),
      cardSlug: partnerSlug,
      ...acceptedOffer,
    };

    this.redline.track(event, payload);
  }

  modalViewed({ partnerData }: DecisionEventProps) {
    const event = "sortingHat.ModalViewed.v1";
    const { fireboltConfigName, partnerSlug } = getDataProps({ partnerData });

    const payload = {
      correlationId: getCorrelationId(fireboltConfigName),
      currentCardPartner: partnerSlug,
      currentCardSlug: partnerSlug,
      sortingHatVersion: "v3",
      workflowId: "ac-tree",
    };

    this.redline.track(event, payload);
  }

  onRouteChange() {
    if (!this.alreadyRanFirstPageload) {
      this.alreadyRanFirstPageload = true;
    } else {
      this.redline.dispatchSPAPathChange();
    }
  }

  createId() {
    return createID();
  }

  sendEvent(name, props) {
    if (eventNames[name]) {
      this.redline.track(eventNames[name], props);
    }
  }

  // TRACK EVENTS
  trackError(name, err) {
    const payload = this.formatterRedlineService.formatDefaultFormPayload();
    const errorPayload = {
      errorDetails: err?.error?.error_slug || err?.name,
      errorMessage: err?.error.fields?.[0]?.message || "Unknown error",
      errorType: err?.error?.message || err?.message,
    };

    this.sendEvent(name, {
      ...payload,
      ...errorPayload,
    });
  }

  // FORM

  trackFormSubmittedView(stepsView, stepForm) {
    const currentStepView = stepsView.querySelectorAll(".slide");
    const payload = this.formatterRedlineService.formatFormPayload(
      currentStepView,
      stepForm
    );
    this.sendEvent("formSubmitted", payload);
  }

  trackFormContinuedView(stepsView, stepForm) {
    this.trackActiveSlide("formContinued", stepsView, stepForm);
  }

  trackFormStartedView(stepsView, stepForm) {
    this.trackActiveSlide("formStarted", stepsView, stepForm);
  }

  trackFieldSelectedView(field, stepsView, stepForm) {
    this.trackActiveSlide("fieldSelected", stepsView, stepForm, field);
  }

  trackActiveSlide(eventName, stepsView, stepForm, field?: any) {
    const currentStepView = stepsView.querySelector(".slide.active");
    const payload = this.formatterRedlineService.formatFormPayload(
      [currentStepView],
      stepForm,
      field
    );
    this.sendEvent(eventName, payload);
  }

  // SIGN IN
  trackCheckRegisterStatus(isRegistered: boolean, document: string) {
    this.sendEvent("checkRegisterStatus", {
      isRegistered: isRegistered.toString(),
      correlationId: this.rlUserAuthenticationService.correlationId(),
      customerId: document,
    });
  }

  trackSignInStarted() {
    this.sendEvent("signInStarted", {
      correlationId: this.rlUserAuthenticationService.correlationId(),
    });
  }

  trackSignInCompleted() {
    this.sendEvent("signInCompleted", {
      correlationId: this.rlUserAuthenticationService.correlationId(),
    });
  }

  trackSignInErrored(err) {
    this.sendEvent("signInErrored", {
      correlationId: this.rlUserAuthenticationService.correlationId(),
    });
  }

  // SIGN UP
  trackSignUpStarted() {
    this.sendEvent("signUpStarted", {
      correlationId: this.rlUserAuthenticationService.correlationId(),
      formType: "sign-up",
    });
  }

  trackSignUpCompleted() {
    this.sendEvent("signUpCompleted", {
      correlationId: this.rlUserAuthenticationService.correlationId(),
      formType: "sign-up",
    });
  }

  trackSignUpErrored(err) {
    const errorDetails = err?.error?.error_slug || err?.name;
    const errorMessage = err?.error.fields?.[0]?.message || "Unknown error";
    const errorType = err?.error?.message || err?.message;

    this.trackError("signUpErrored", {
      correlationId: this.rlUserAuthenticationService.correlationId(),
      errorDetails,
      errorMessage,
      errorType,
    });
  }

  /**
   * @description Rastreia a visualização de anúncios no sistema usando o Adsense.
   * Este método envia informações relevantes para análise de desempenho e estatísticas.
   *
   * @param {string} props.campaignId - Identificador da campanha do anúncio.
   * @param {string} props.size - Tamanho do anúncio.
   * @param {string} props.location - Localização onde o anúncio foi visualizado.
   * @param {string} props.creativeId - Identificador criativo do anúncio.
   * @param {string} props.lineItemId - Identificador do item de linha do anúncio.
   * @param {string} props.advertiserId - Identificador do anunciante.
   *
   * @example
   * this.trackAdsense({
   *   campaignId: "123",
   *   size: "300x250",
   *   location: "sidebar",
   *   creativeId: "456",
   *   lineItemId: "789",
   *   advertiserId: "987",
   * });
   */
  trackAdsense(props: Record<string, string | number>) {
    this.redline.track("userTracking.AdViewed.v1", {
      adContext: {
        campaignId: props?.campaignId?.toString(),
        size: props?.size,
        location: props?.location,
        creativeId: props?.creativeId?.toString(),
        lineItemId: props?.lineItemId?.toString(),
        advertiserId: props?.advertiserId?.toString(),
        isDirectSold: true,
      },
    });
  }

  // CREDIT
  modalViewedCredit(location: string) {
    const event = "sortingHat.ModalViewed.v1";

    const payload = {
      location,
    };

    this.redline.track(event, payload);
  }

  productViewedCredit(productViewed: ProductCreditEventProps) {
    const event = "ecommerce.ProductViewed.v1";

    this.redline.track(event, { product: productViewed });
  }

  productClickedCredit(productClicked: ProductCreditEventProps) {
    const event = "ecommerce.ProductClicked.v1";

    this.redline.track(event, { product: productClicked });
  }

  decisionRequestedCredit() {
    const event = "sortingHat.DecisionRequested.v1";

    const payload = { workflowId: "ac-tree", partner: "ac" };

    this.redline.track(event, payload);
  }

  redirectRequestedCredit(payload: RedirectRequestedCreditEventProps) {
    const event = "experience.RedirectRequested.v1";

    this.redline.track(event, payload);
  }

  decisionEnrichedCredit() {
    const event = "sortingHat.DecisionEnriched.v1";

    const payload = { partner: "ac", cardSlug: "painel" };

    this.redline.track(event, payload);
  }

  productListViewedCredit(
    productListViewed: ProductListViewedCreditEventProps
  ) {
    const event = "ecommerce.ProductListViewed.v1";

    this.redline.track(event, productListViewed);
  }

  productListFilteredCredit(
    productListFiltered: ProductListFilteredCreditEventProps
  ) {
    const event = "ecommerce.ProductListFiltered.v1";

    this.redline.track(event, productListFiltered);
  }

  decisionOutcomeReceivedCredit(
    decisionOutcomePayload: DecisionOutcomeReceivedCreditEventProps[]
  ) {
    const event = "sortingHat.DecisionOutcomeReceived.v1";

    const payload = {
      partner: "ac",
      workflowId: "ac-tree",
      decisionProducts: Object.values(decisionOutcomePayload),
    };

    this.redline.track(event, payload);
  }

  elementClickedCredit(elementClicked: ElementClickedEventProps) {
    const event = "userTracking.ElementClicked.v1";

    this.redline.track(event, elementClicked);
  }

  experimentStarted(experimentStarted: ExperimentStartedProps) {
    const event = "userTracking.ExperimentStarted.v1";

    this.redline.track(event, experimentStarted);
  }

  trackDecisionProvided(payload: DecisionProvidedProps) {
    const event = "abTesting.DecisionProvided.v1";

    this.redline.track(event, payload);
  }
}
