export class FeedbackContext {
    id: string;
    description: string;
    title: string;
    title_thank: string;
    title_call: string;
    title_btn: string;
    event_name: string;
    event_property_good: string;
    event_property_bad: string;
    event_property_call: string;
    event_property_closed: string;

    constructor(
        id: string,
        description: string,
        title: string,
        title_thank: string,
        title_call: string,
        title_btn: string,
        event_name: string,
        event_property_good: string,
        event_property_bad: string,
        event_property_call: string,
        event_property_closed: string,
    ) {
        this.title = title;
        this.id = id;
        this.description = description;
        this.title = title;
        this.title_thank = title_thank;
        this.title_call = title_call;
        this.title_btn = title_btn;
        this.event_name = event_name;
        this.event_property_good = event_property_good;
        this.event_property_bad = event_property_bad;
        this.event_property_call = event_property_call;
        this.event_property_closed = event_property_closed;
    }
}