import { Injectable } from "@angular/core";
import { stepId, steps } from "./constants";

import { RlUserAuthenticationService } from "./userAuthentication/rl-user-authentication.service";

@Injectable({
  providedIn: "root",
})
export class FormatterRedlineService {
  constructor(
    private rlUserAuthenticationService: RlUserAuthenticationService
  ) {}

  // FORMAT DATA
  formatDefaultFormPayload() {
    const isLogin = window.location.pathname.includes("login");

    return {
      correlationId: this.rlUserAuthenticationService.correlationId(),
      formContext: {
        formBrand: "acordo-certo",
        formId: isLogin ? "acordo-certo-sign-in" : "acordo-certo-sign-up",
        formName: isLogin ? "sign-in" : "sign-up",
        formType: isLogin ? "sign-in" : "sign-up",
        formVersion: "v1",
      },
    };
  }

  formatFieldPayload(currentStepView, fieldRaw?: any) {
    if (fieldRaw) {
      return [
        {
          autofilled: false,
          fieldName: fieldRaw.getAttribute("name"),
          fieldLabel: fieldRaw.closest(".form-group").querySelector("label")
            .innerText,
          fieldValue: "true",
        },
      ];
    }

    const stepFields = currentStepView.querySelectorAll(".form-group");

    return [...stepFields].map((stepField) => {
      const stepFieldInput = stepField.querySelector("input");
      const fieldName = stepFieldInput.getAttribute("name");
      const fieldValue =
        stepFieldInput.value == "on" || fieldName === "password"
          ? "true"
          : stepFieldInput.value;

      if (fieldName == "validaId") {
        return {
          autofilled: false,
          fieldName: "kba",
          fieldLabel: stepField.querySelector("h1").innerText,
          fieldValue: "true",
        };
      }

      return {
        autofilled: false,
        fieldName,
        fieldLabel: stepField.querySelector("label").innerText,
        fieldValue,
      };
    });
  }

  formatFormPayload(currentStepView, stepForm, fieldRaw?: any) {
    const stepIndex = stepForm - 1;
    const step = steps[stepIndex];
    const field =
      [...currentStepView].length === 1
        ? this.formatFieldPayload(currentStepView[0], fieldRaw)
        : [...currentStepView].reduce((allSteps, view) => {
            return [...allSteps, ...this.formatFieldPayload(view, fieldRaw)];
          }, []);

    const defaultPayload = this.formatDefaultFormPayload();
    return {
      ...defaultPayload,
      field,
      stepContext: {
        stepId: stepId[step],
        stepName: step,
        stepNumber: stepIndex,
      },
      autofillUsed: true,
    };
  }
}
