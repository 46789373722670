import { Injectable } from "@angular/core";
import { AppConfig } from "@app/configs";
import { EnvContext } from "@app/models";
import { environment } from "@env/environment";
import { CookieHelperService } from "../storage/cookie-helper.service";
import { CurrentPartner } from "@app/models/current-partner/current-partner";

@Injectable({
  providedIn: "root",
})
export class EnvContextResolverService {
  constructor(private cookieHelperService: CookieHelperService) {}

  current(path?: string): EnvContext {
    let currentEnvContext: EnvContext;
    if (path) {
      currentEnvContext =
        this.getEnvContextByParam(path) || this.getDefaultEnv();
    } else {
      currentEnvContext =
        this.getEnvContextByParam(path) ||
        this.getEnvContextBySubdomain() ||
        this.getEnvContextByCookie() ||
        this.getDefaultEnv();
    }
    return currentEnvContext;
  }

  /**
   *  Verifies the association of the current partner with predefined partners.
   * Returns an object indicating if the current partner matches each predefined partner.
   * @param {string} currentPartner - The name of the current partner.
   * @returns {CurrentPartner} An object with boolean properties indicating the association
   *                          of the current partner with each predefined partner in the list.
   * @example
   *  Example usage:
   * const currentPartner = getCurrentPartner("itau");
   *  Output currentPartner:  { isItau: true, isBancodobrasil: false, isBradesco: false, isRiachuelo: false, isNubank: false }
   */
  getCurrentPartner(currentPartner: string): CurrentPartner {
    const partnerList = [
      "itau",
      "bancodobrasil",
      "bradesco",
      "riachuelo",
      "nubank",
      "ativossa",
      "neon",
    ];

    return partnerList.reduce((result, partner) => {
      result[`is${partner.charAt(0).toUpperCase() + partner.slice(1)}`] =
        currentPartner === partner;
      return result;
    }, {} as CurrentPartner);
  }

  first(): EnvContext {
    let envContext: EnvContext;
    if (this.cookieHelperService.check(AppConfig.firstEnvContext)) {
      envContext = this.getEnvContextByKey(
        this.cookieHelperService.get(AppConfig.firstEnvContext)
      );
      if (envContext) {
        return envContext;
      }
    }
    envContext = this.current();
    this.cookieHelperService.add(
      AppConfig.firstEnvContext,
      envContext.partnerId
    );
    return envContext;
  }

  getDefaultEnv(): EnvContext {
    return this.getEnvContextByKey("marketplace");
  }

  getEnvContextByKey(key: string): EnvContext {
    if (environment.envs) {
      for (const actualEnvContext of environment.envs) {
        for (const domain of actualEnvContext.domains) {
          if (domain === key) {
            return actualEnvContext;
          }
        }
      }
    }
    return null;
  }

  getEnvContextByPartnerId(partnerId: string): EnvContext {
    let envContext: EnvContext;
    for (const actualEnvContext of environment.envs) {
      if (actualEnvContext.partnerId === partnerId) {
        envContext = actualEnvContext;
        break;
      }
    }
    return envContext;
  }

  getEnvContextById(partnerId: string): EnvContext {
    let envContext: EnvContext;
    for (const actualEnvContext of environment.envs) {
      if (actualEnvContext.id === partnerId) {
        envContext = actualEnvContext;
        break;
      }
    }
    return envContext;
  }

  checkPartnerWithoutEnvData({
    path,
    envContext,
    partnerIdentifier,
  }: {
    path?: string;
    envContext: EnvContext;
    partnerIdentifier: string;
  }) {
    if (!envContext) {
      console.error(
        `${path} [ checkPartnerWithoutEnvData ]: Check the environment variables for the partner data ${partnerIdentifier}`
      );
    }
  }

  private getEnvContextBySubdomain(): EnvContext {
    const currentDomain = this.getCurrentSubdomain();
    return this.getEnvContextByKey(currentDomain);
  }

  private getEnvContextByParam(path?: string): EnvContext {
    return (
      this.getEnvContextByKey(this.getPartnerNameByPath(path)) ||
      this.getEnvContextByKey(this.getUrlParamByName("partner")) ||
      this.getEnvContextByKey(this.getUrlParamByName("credor"))
    );
  }

  private getEnvContextByCookie() {
    const partner = this.cookieHelperService.get(AppConfig.partner);
    return this.getEnvContextByKey(partner);
  }

  private getCurrentSubdomain(): string {
    return (window as any).location.hostname.split(".")[0];
  }

  private getPartnerNameByPath(path?: string): string {
    const pathname = path || (window as any).location.pathname;
    const firstParam = this.extractPathFirstPart(pathname);
    return this.removePathQueryString(firstParam);
  }

  private extractPathFirstPart(path: string) {
    let firstPathName: string = null;
    const pathnames = path.split("/");
    if (pathnames && pathnames.length > 1) {
      firstPathName = pathnames[1].toLowerCase();
    }
    return firstPathName;
  }

  private removePathQueryString(path: string) {
    return path.split("?")[0];
  }

  private getUrlParamByName(name: string, url?: string): string {
    if (!url) {
      url = (window as any).location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return "";
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
}
