import { FormFormatterEventProps } from "@app/models/redline/redline.model";

export function formatFireboltFormFieldValues(fields, stepSlug, capturedData) {
  const labelDictionary = {
    address: "Endereço",
    phone_number: "Telefone",
    sms_token: "Token",
  };

  return fields.reduce((allValues, field) => {
    const { label } = field["ui:props"];
    const { slug } = field;

    const fieldValue = capturedData[slug];

    return [
      ...allValues,
      {
        autofilled: false,
        fieldId: `${stepSlug}-${slug}`,
        fieldLabel: label || labelDictionary[slug],
        fieldName: slug,
        fieldType: field ? field["ui:widget"] : "",
        fieldValue: fieldValue?.toString(),
        fieldSource: "user-input",
      },
    ];
  }, []);
}

export function generateFormEventsDefaultPayload(
  eventName: string,
  partnerSlug: string,
  formData?: { stepSlug: string; position?: number; track?: string }
) {
  let captureContext = {};
  let stepContext = {};
  let formContext = {};

  const eventsWithCommonPayload = [
    "formStarted",
    "formContinued",
    "formSubmitted",
  ];

  const isCaptureAvailable = [...eventsWithCommonPayload];
  const isStepAvailable = [
    ...eventsWithCommonPayload,
    "formBacked",
    "formErrored",
    "decisionAccepted",
  ];
  const isFormAvailable = [
    ...eventsWithCommonPayload,
    "formBacked",
    "formErrored",
    "decisionAccepted",
  ];

  if (isCaptureAvailable.includes(eventName)) {
    captureContext = {
      captureContext: {
        capturePlacement: "Full Screen",
        captureType: "Custom Form",
        identityRequested: true,
        valueProp: `form-${partnerSlug}`,
      },
    };
  }

  if (isStepAvailable.includes(eventName)) {
    const { position, stepSlug } = formData;

    stepContext = {
      stepContext: {
        stepId: `ac-partner-${partnerSlug}-${stepSlug}`,
        stepName: stepSlug,
        stepNumber: position,
        stepType: "PRODUCT",
      },
    };
  }

  if (isFormAvailable.includes(eventName)) {
    const { track } = formData;

    formContext = {
      formContext: {
        formBrand: partnerSlug,
        formId: `ac-partner-${partnerSlug}`,
        formName: `${partnerSlug}-ac`,
        formType: track || "default",
        formVersion: "v1",
      },
    };
  }

  return {
    ...captureContext,
    ...stepContext,
    ...formContext,
  };
}

export function getDataProps({
  partnerData,
  formData,
}: FormFormatterEventProps) {
  const { fireboltConfigName, slug: partnerSlug } = partnerData;
  const defaultValue = {
    data: { fields: [], slug: "" },
    position: 0,
  };

  const {
    capturedData,
    step: { data: { fields, slug: stepSlug }, position } = defaultValue,
  } = formData ?? {};

  return {
    position,
    stepSlug,
    fireboltConfigName,
    partnerSlug,
    capturedData,
    fields,
  };
}
