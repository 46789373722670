import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStorageHelperService } from "@app/core/services/storage/local-storage-helper.service";
import { AppConfig } from "@app/configs";
import {
  Billet,
  CloseAgreementContext,
  EnvContext,
  HasDebt,
  Partner,
  Proposal,
  RecoverPasswordContext,
  Term,
  User,
  HasTerm,
  PaymentOption,
  EasyRegisterResponse,
  EasyRegisterRequest,
  SitePolicyUserAccept,
  SortingHatTrackRequestContext,
  SortingHatDecideRequestContext,
  CustomerFindResponse,
} from "@app/models";
import { Agreement } from "@app/models/agreement/agreement.model";
import { DebtTokenSend } from "@app/models/debt/debt-token-send.model";
import { DebtTokenValidate } from "@app/models/debt/debt-token-validate";
import { PaymentOptionRequest } from "@app/models/debt/payment-option-request.model";
import { SimulateContext } from "@app/models/debt/simulate-context.model";
import { OfflineProposal } from "@app/models/offline/offline-proposal.model";
import { OfflineSendProposalContext } from "@app/models/offline/offline-send-proposal-context.model";
import { OfflineSimulate } from "@app/models/offline/offline-simulate.model";
import { Unsubscribe } from "@app/models/unsubscribe/unsubscribe.model";
import { environment } from "@env/environment";
import { Observable, throwError } from "rxjs";
import { HideDebt } from "@app/models/debt/hide-debt.model";
import { ContactBlock } from "@app/models/unsubscribe/contact-block.model";
import { DebtDocument } from "@app/models/debt/debt-document.model";
import { CurrentUser } from "@app/models/auth/currentUser.model";
import { SendTokenUpdateUserRequest } from "@app/models/my-account/send-token-update-user-request";
import { CustomerUpdateRequest } from "@app/models/my-account/customer-update-request.model";
import { SubscriptionRequest } from "@app/models/notifications/subscription-request.model";
import { SubscriptionResponse } from "@app/models/notifications/subscription-response.model";
import { RegisterPreSaveRequest } from "@app/models/auth/register-pre-save-request.model";
import {
  KbaRegisterResponse,
  KbaRegisterResponseNewAPI,
} from "@app/models/auth/kba-register-response.model";
import { KbaRegisterRequest } from "@app/models/auth/kba-register-request.model";
import { CancelAgreementRequest } from "@app/models/agreement/cancel-agreement";
import { UpdateAgreementRequest } from "@app/models/agreement/update-agreement-request.model";

import { MyDebtsResponse } from "@app/models/my-debts/my-debts-response.model";
import { MyDebtsRequest } from "@app/models/my-debts/my-debts-request.model";
import { AdminRegisterCustomerResponse } from "@app/models/admin/admin-register-customer-response.model";
import { AdminRegisterCustomerRequest } from "@app/models/admin/admin-register-customer-request.model";
import { PersonDataResponse } from "@app/models/auth/person-data.model";
import { CookieHelperService } from "../services/storage/cookie-helper.service";
import { Pix } from "@app/models/agreement/pix";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private localStorageHelperService: LocalStorageHelperService,
		private cookieHelperService: CookieHelperService,
  ) { }

  shouldUseMarketplaceNewAPI(): boolean {
    return environment.shouldUseMarketplaceNewAPI;
  }


  private getEnvironmentUrl(): string {
    let apiUrl = environment.apiUrl;
    return apiUrl;
  }

  private getFinancialdataUrl(): string {
    let apiUrl = environment.financialDataApi;
    return apiUrl;
  }

  private getEnvironmentUrlAPIV2(): string {
    let apiUrl = environment.apiUrlV2;
    return apiUrl;
  }

  private getSortingDebtUrlAPI(): string {
    let apiUrl = environment.sortingDebtApiUrl;
    return apiUrl;
  }

  public getGeneratePixUrlAPI(): string {
    let apiUrl = environment.paymentApi;
    return apiUrl;
  }

  private getRemindersUrl(): string {
    return environment.apiReminders;
  }

  implicitLogin(userToken: string): Observable<HttpResponse<any>> {
    let request = {
      "token": userToken,
    };
    return this.http.post<User>(
      `${this.getEnvironmentUrl()}/api/v2/consumidorpositivo/token`,
      request,
      { observe: "response" }
    );
  }

  findEasyRegister(token: string): Observable<EasyRegisterResponse> {
    let request = { token };
    return this.http.post<EasyRegisterResponse>(
      `${this.getEnvironmentUrl()}/api/v2/consumidorpositivo/find`,
      request
    );
  }

  easyRegister(request: EasyRegisterRequest): Observable<any> {
    return this.http.post<any>(
      `${this.getEnvironmentUrl()}/api/v2/consumidorpositivo/register`,
      request
    );
  }

  logout(): Observable<HttpResponse<any>> {
    return this.http.post<any>(
      `${this.getEnvironmentUrl()}/api/v1/auth/logout`,
      { observe: "response" }
    );
  }

  personData(document: string): Observable<HttpResponse<PersonDataResponse>> {
    const request = {
      documento: document,
    };
    return this.http.post<any>(
      `${this.getEnvironmentUrlAPIV2()}/admin/auth/person-data`,
      request,
      { observe: "response" }
    );
  }

  currentUser(): Observable<HttpResponse<CurrentUser>> {
    return this.http.get<CurrentUser>(
      `${this.getEnvironmentUrlAPIV2()}/app/customer`,
      { observe: "response" }
    );
  }

  recoverAdminToken(documento: string): Observable<HttpResponse<any>> {
    let request = { "customerId": documento };
    return this.http.post<any>(
      `${this.getEnvironmentUrl()}/api/v2/admin/token`,
      request,
      {
        observe: "response",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  validaIdQuestion(request): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v2/auth/generatequestion`,
      request,
      { observe: "response" }
    );
  }

  validaIdAnswer(request): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v2/auth/checkanswer`,
      request,
      { observe: "response" }
    );
  }

  registerUser(request: User, version: string): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v1/devedor/salvar?version=${version}`,
      request,
      { observe: "response" }
    );
  }

  sitePolicyUserAccept(
    request: SitePolicyUserAccept
  ): Observable<HttpResponse<any>> {
    return this.http.put(
      `${this.getEnvironmentUrl()}/api/v2/site-policy/accept`,
      request,
      { observe: "response" }
    );
  }

  getCurrentSitePolicy(): Observable<HttpResponse<any>> {
    return this.http.get(
      `${this.getEnvironmentUrl()}/api/v2/site-policy/current`,
      { observe: "response" }
    );
  }

  getLastSitePolicyAccepted(cpf): Observable<HttpResponse<any>> {
    return this.http.get(
      `${this.getEnvironmentUrl()}/api/v2/site-policy/last-accepted/${cpf}`,
      { observe: "response" }
    );
  }

  resetSitePolicyUserAccepted(
    request: SitePolicyUserAccept
  ): Observable<HttpResponse<any>> {
    return this.http.put(
      `${this.getEnvironmentUrl()}/api/v2/site-policy/reset-accepted`,
      request,
      { observe: "response" }
    );
  }

  disableSitePolicyUserAccepted(
    request: SitePolicyUserAccept
  ): Observable<HttpResponse<any>> {
    return this.http.put(
      `${this.getEnvironmentUrl()}/api/v2/site-policy/disable-accepted`,
      request,
      { observe: "response" }
    );
  }

  registerCustomer(request: User): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v2/customer/save`,
      request,
      { observe: "response" }
    );
  }

  editUser(request: User, token?: string): Observable<HttpResponse<any>> {
    let headers = !!token
      ? {
        "X-Auth-Token": token,
      }
      : {};

    return this.http.put(
      `${this.getEnvironmentUrl()}/api/v1/devedor/editar`,
      request,
      { headers, observe: "response" }
    );
  }

  unsubscribeUser(request: Unsubscribe): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v2/unsubscription`,
      request,
      { observe: "response" }
    );
  }

  contactBlock(request: ContactBlock): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/contact_block_list`,
      request,
      { observe: "response" }
    );
  }

  //TODO matar assim que o back migrar 100% e estiver seguro - 14/06/2023
  searchUser(documento: string): Observable<HttpResponse<User>> {
    let request = {};

    if (this.localStorageHelperService.check(AppConfig.referralId)) {
      request = {
        userDocument: documento,
        referralId: this.localStorageHelperService.get(AppConfig.referralId),
      };
    } else {
      request = {
        userDocument: documento,
      };
    }

    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v2/customer/find`,
      request,
      { observe: "response" }
    );
  }

  registerPreSave(
    request: RegisterPreSaveRequest
  ): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v2/customer/pre-save`,
      request,
      { observe: "response" }
    );
  }

  searchUserV2(
    documento: string
  ): Observable<HttpResponse<CustomerFindResponse<User>>> {
    let request = {};

    if (this.localStorageHelperService.check(AppConfig.referralId)) {
      request = {
        userDocument: documento,
        referralId: this.localStorageHelperService.get(AppConfig.referralId),
      };
    } else {
      request = {
        userDocument: documento,
      };
    }

    return this.http.post<CustomerFindResponse<User>>(
      `${this.getEnvironmentUrlAPIV2()}/public/customer/find`,
      request,
      { observe: "response" }
    );
  }

  registerPassword(request: any): Observable<HttpResponse<User>> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v1/auth/recuperar-senha/alterar-senha`,
      request,
      { observe: "response" }
    );
  }

  changePassword(request: any): Observable<HttpResponse<User>> {
    if (this.shouldUseMarketplaceNewAPI()) {
      return this.http.put(
        `${this.getEnvironmentUrlAPIV2()}/public/auth/reset-password`,
        request,
        { observe: "response" }
      );
    }
    return this.http.put(
      `${this.getEnvironmentUrl()}/api/v2/password`,
      request,
      { observe: "response" }
    );
  }

  recoverPassword(documento: string): Observable<HttpResponse<User>> {
    return this.http.post<User>(
      `${this.getEnvironmentUrl()}/api/v1/auth/recuperar-senha/enviar-email`,
      { documento },
      { observe: "response" }
    );
  }

  validateRecoverPasswordToken(token: string): Observable<HttpResponse<any>> {
    return this.http.get(
      `${this.getEnvironmentUrl()}/api/v1/auth/recuperar-senha/validar-token/${token}`,
      { observe: "response" }
    );
  }

  recoverPasswordConfirmation(request: RecoverPasswordContext) {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v1/auth/recuperar-senha/alterar-senha`,
      request,
      { observe: "response" }
    );
  }

  generatePasswordCode(request: any): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v2/password/generatecode`,
      request,
      { observe: "response" }
    );
  }

  checkPasswordCode(request: any): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v2/password/checkcode`,
      request,
      { observe: "response" }
    );
  }

  checkPasswordCodeAPIV2(request: any): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.getEnvironmentUrlAPIV2()}/public/auth/reset-password/code`,
      request,
      { observe: "response" }
    );
  }

  checkPasswordPhone(request: any): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v2/contact/checkphone`,
      request,
      { observe: "response" }
    );
  }

  checkPasswordEmail(request: any): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v2/contact/checkemail`,
      request,
      { observe: "response" }
    );
  }

  getNotifications(userId: number): Observable<SubscriptionResponse[]> {
    return this.http.get<SubscriptionResponse[]>(
      `${this.getEnvironmentUrl()}/api/v2/subscription/customerid/${userId}`
    );
  }

  updateNotifications(request: SubscriptionRequest[]) {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v2/subscription/`,
      request,
      { observe: "response" }
    );
  }

  verificationToken(request: SendTokenUpdateUserRequest) {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v2/customer/verification-token`,
      request,
      { observe: "response" }
    );
  }

  validateTokenUpdateUser(kind: string, token: string) {
    return this.http.get(
      `${this.getEnvironmentUrl()}/api/v2/customer/verification-token/${kind}/${token}`,
      { observe: "response" }
    );
  }

  customerUpdate(request: CustomerUpdateRequest) {
    if (this.shouldUseMarketplaceNewAPI()) {
      return this.http.put(
        `${this.getEnvironmentUrlAPIV2()}/app/customer/update`,
        request,
        { observe: "response" }
      );
    }
    return this.http.put(
      `${this.getEnvironmentUrl()}/api/v2/customer/update`,
      request,
      { observe: "response" }
    );
  }

  hasDebt(
    envContext: EnvContext,
    partner: Partner,
    user: User
  ): Observable<HttpResponse<HasDebt>> {
    if (
      !this.isValidEnvContext(envContext) ||
      !partner ||
      !user ||
      !user?.documento
    ) {
      return throwError("Request is not valid.");
    }

    let request = {};

    if (this.localStorageHelperService.check(AppConfig.referralId)) {
      request = {
        documento: user?.documento,
        referralId: this.localStorageHelperService.get(AppConfig.referralId),
        parameters: {
          credorId: partner.id,
        },
      };
    } else {
      request = {
        documento: user?.documento,
        parameters: {
          credorId: partner.id,
        },
      };
    }

    return this.http.post<HasDebt>(
      `${envContext.apiUrl}/api/divida/possui-divida`,
      request,
      { observe: "response" }
    );
  }

  hasDebtByPartner(partner: Partner): Observable<HttpResponse<HasDebt>> {
    return this.http.get<HasDebt>(
      `${this.getEnvironmentUrl()}/api/v1/divida/possui-divida/${partner.id}`,
      { observe: "response" }
    );
  }

  hasDebtInvalidateCache(
    userDocument: string,
    partnerID: string
  ): Observable<HttpResponse<any>> {
    let request = {
      documento: userDocument,
      provider_identifier: partnerID,
    };

		const token = this.cookieHelperService.get(
      AppConfig.auth.tokenCookie,
      false
    );
		const headers = {
			'X-Auth-Token': token
		}

    return this.http.post<any>(
      `${this.getSortingDebtUrlAPI()}/public/debt/invalidate`,
      request,
      { headers, observe: "response" }
    );
  }

  simulate(
    envContext: EnvContext,
    user: User
  ): Observable<HttpResponse<SimulateContext>> {
    if (!this.isValidEnvContext(envContext) || !user || !user?.documento) {
      return throwError("Request is not valid.");
    }

    let request = {};

    if (this.localStorageHelperService.check(AppConfig.referralId)) {
      request = {
        documento: user?.documento,
        referralId: this.localStorageHelperService.get(AppConfig.referralId),
        parameters: {
          partnerSystemIdentifier: envContext.partnerId,
        },
      };
    } else {
      request = {
        documento: user?.documento,
        parameters: {
          partnerSystemIdentifier: envContext.partnerId,
        },
      };
    }

    return this.http.post<SimulateContext>(
      `${envContext.apiUrl}/api/divida/buscar`,
      request,
      { observe: "response" }
    );
  }

  recoverPartnerData(envContext: EnvContext): Observable<HttpResponse<any>> {
    if (!this.isValidEnvContext(envContext)) {
      return throwError("Request is not valid.");
    }

    return this.http.get(
      `${this.getEnvironmentUrl()}/api/v1/credor/detalhe/${envContext.parentPartnerId
      }`,
      { observe: "response" }
    );
  }

  hideDebt(request: HideDebt): Observable<HttpResponse<any>> {
    return this.http.post<any>(
      `${this.getEnvironmentUrl()}/api/v2/debt/hide`,
      request,
      { observe: "response" }
    );
  }

  closeAgreement(
    envContext: EnvContext,
    request: CloseAgreementContext
  ): Observable<HttpResponse<Agreement>> {
    if (!this.isValidEnvContext(envContext)) {
      return throwError("Request is not valid.");
    }
    const closeAgreementRequest = {
      ...request,
      parameters: {
        ...(request?.parameters || {}),
        partnerSystemIdentifier: envContext.partnerId,
      },
    };
    return this.http.post<Agreement>(
      `${envContext.apiUrl}/api/acordo/fechar`,
      closeAgreementRequest,
      { observe: "response" }
    );
  }

  myAgreements(token?: string): Observable<HttpResponse<Agreement[]>> {
    let headers = {};
    if (token) {
      headers[AppConfig.auth.tokenHeader] = token;
    }
    return this.http.post<Agreement[]>(
      `${this.getEnvironmentUrl()}/api/v1/acordo/buscar`,
      null,
      { observe: "response", headers: headers }
    );
  }

  closedAgreements(token?: string): Observable<HttpResponse<Agreement[]>> {
    let headers = {};
    if (token) {
      headers[AppConfig.auth.tokenHeader] = token;
    }
    return this.http.post<Agreement[]>(
      `${this.getEnvironmentUrl()}/api/v2/agreement`,
      null,
      { observe: "response", headers: headers }
    );
  }

  generatePix(request: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(
      `${this.getGeneratePixUrlAPI()}/payment/v1/pix/qrcode`,
      request,
      { observe: "response" }
    );
  }

  getPaymentStatus(id): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${environment.paymentApi}/payment/v1/pix/status/${id}?status`,
      { observe: "response" }
    );
  }

  validateHitCaptcha(
    captcha: string,
    url: string
  ): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${url}/captcha/hit/validar`, captcha, {
      observe: "response",
    });
  }

  cancelMyAccount(): Observable<HttpResponse<any>> {
    if (this.shouldUseMarketplaceNewAPI()) {
      return this.http.delete(
        `${this.getEnvironmentUrlAPIV2()}/app/customer/deactive`,
        { observe: "response" }
      );
    } else {
      return this.http.delete(
        `${this.getEnvironmentUrl()}/api/v1/devedor/desativar`,
        { observe: "response" }
      );
    }
  }

  partnersActive(): Observable<HttpResponse<Partner[]>> {
    return this.http.get<Partner[]>(
      `${this.getEnvironmentUrl()}/api/v1/credor/ativos`,
      { observe: "response" }
    );
  }

  partnersWithDebts(request: MyDebtsRequest): Observable<HttpResponse<MyDebtsResponse>> {
		const token = this.cookieHelperService.get(
      AppConfig.auth.tokenCookie,
      false
    );
		const headers = {
			'X-Auth-Token': token
		}
    return this.http.post<MyDebtsResponse>(
      `${this.getSortingDebtUrlAPI()}/app/debt/track`,
      request,
      { headers, observe: "response" }
    );
  }

  adminTrackPartnerDebts(request: MyDebtsRequest): Observable<HttpResponse<MyDebtsResponse>> {
		const token = this.cookieHelperService.get(
      AppConfig.auth.tokenCookie,
      false
    );
		const headers = {
			'X-Auth-Token': token
		}
    return this.http.post<MyDebtsResponse>(
      `${this.getSortingDebtUrlAPI()}/app/debt/admin/track`,
      request,
      { headers, observe: "response" }
    );
  }

  partnersInactive(): Observable<HttpResponse<Partner[]>> {
    return this.http.get<Partner[]>(
      `${this.getEnvironmentUrl()}/api/v1/credor/inativos`,
      { observe: "response" }
    );
  }

  sendProposal(request: Proposal): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v1/proposta/salvar`,
      request,
      { observe: "response" }
    );
  }

  getOptionsByFieldId(fieldId: number): Observable<HttpResponse<any>> {
    return this.http.get(
      `${this.getEnvironmentUrl()}/api/v2/contactus/fields/${fieldId}/options`,
      { observe: "response" }
    );
  }

  contactUs(request: FormData): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v2/contactus`,
      request,
      { observe: "response" }
    );
  }

  private isValidEnvContext(envContext: EnvContext): boolean {
    return envContext?.apiUrl ? true : false;
  }

  cancelAgreement(
    request: CancelAgreementRequest
  ): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v2/agreement/cancel`,
      request,
      { observe: "response" }
    );
  }

  offlineSimulate(
    envContext: EnvContext
  ): Observable<HttpResponse<OfflineSimulate>> {
    return this.http.get<OfflineSimulate>(
      `${this.getEnvironmentUrl()}/api/offline/simular/${envContext.partnerId}`,
      { observe: "response" }
    );
  }

  offlineSearchProposal(
    envContext: EnvContext,
    proposalId: number
  ): Observable<HttpResponse<OfflineProposal>> {
    return this.http.get<OfflineProposal>(
      // eslint-disable-next-line prettier/prettier
      `${this.getEnvironmentUrl()}/api/offline/propostas/${envContext.partnerId
      }/${proposalId}`,
      { observe: "response" }
    );
  }

  offlineSendProposal(
    sendProposalContext: OfflineSendProposalContext
  ): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/offline/aceitar-proposta`,
      sendProposalContext,
      { observe: "response" }
    );
  }

  hasTerm(
    request: HasTerm,
    envContext: EnvContext
  ): Observable<HttpResponse<Term>> {
    return this.http.post<Term>(
      `${envContext.apiUrl}/api/divida/termo`,
      request,
      { observe: "response" }
    );
  }

  paymentOptions(
    request: PaymentOptionRequest,
    envContext: EnvContext
  ): Observable<HttpResponse<PaymentOption[]>> {
    return this.http.post<PaymentOption[]>(
      `${envContext.apiUrl}/api/v2/simulate/payment-option`,
      request,
      { observe: "response" }
    );
  }

  paymentOption(
    request: PaymentOptionRequest,
    envContext: EnvContext
  ): Observable<HttpResponse<PaymentOption>> {
    return this.http.post<PaymentOption>(
      `${envContext.apiUrl}/api/v2/simulate/payment-option`,
      request,
      { observe: "response" }
    );
  }

  sendDebtToken(request: DebtTokenSend): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v1/debt/token/send`,
      request,
      { observe: "response" }
    );
  }

  validateDebtToken(request: DebtTokenValidate): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v1/debt/token/validate`,
      request,
      { observe: "response" }
    );
  }

  generateBillet(
    installmentCode,
    requestParameters,
    envContext: EnvContext
  ): Observable<HttpResponse<Billet>> {
    let apiUrl = this.getEnvironmentUrl();
    if (this.isValidEnvContext(envContext)) {
      apiUrl = envContext.apiUrl;
    }
    const generateBilletRequest = {
      installmentCode,
      parameters: {
        requestParameters,
        partnerSystemIdentifier: envContext.partnerId,
      },
    };
    return this.http.post<Billet>(
      `${apiUrl}/api/v2/billet`,
      generateBilletRequest,
      { observe: "response" }
    );
  }

  downloadBillet(request: any): Observable<HttpResponse<any>> {
    return this.http.get(
      `${this.getEnvironmentUrl()}/api/v2/billet/${request.installmentCode}`,
      { observe: "response", responseType: "blob" }
    );
  }

  getDebtDocument(
    documento: string,
    code: string,
    envContext: EnvContext
  ): Observable<HttpResponse<DebtDocument>> {
    if (!this.isValidEnvContext(envContext)) {
      return throwError("Request is not valid.");
    }

    return this.http.post<DebtDocument>(
      `${envContext.apiUrl}/api/v2/debt/document`,
      { documento, code },
      { observe: "response" }
    );
  }

  kbaScore(request: any): Observable<HttpResponse<any>> {
    return this.http.post(`${this.getEnvironmentUrl()}/api/v2/kba`, request, {
      observe: "response",
    });
  }

  kbaScoreAnswer(request: any): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v2/kba/answer`,
      request,
      { observe: "response" }
    );
  }

  kbaValidate(): Observable<HttpResponse<any>> {
    return this.http.get(`${this.getEnvironmentUrl()}/api/v2/kba/validate`, {
      observe: "response",
    });
  }

  kbaValidateAPIV2(): Observable<HttpResponse<any>> {
    return this.http.get(
      `${this.getEnvironmentUrlAPIV2()}/app/kba/score/validate`,
      {
        observe: "response",
      }
    );
  }

  kbaRegister(request: KbaRegisterRequest): Observable<KbaRegisterResponse> {
    return this.http.post<KbaRegisterResponse>(
      `${this.getEnvironmentUrlAPIV2()}/public/kba/register`,
      request
    );
  }

  kbaRegisterNewAPI(
    request: KbaRegisterRequest
  ): Observable<KbaRegisterResponseNewAPI> {
    return this.http.post<KbaRegisterResponseNewAPI>(
      `${this.getEnvironmentUrlAPIV2()}/public/kba/register`,
      request
    );
  }

  onScore(): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(
      `${this.getFinancialdataUrl()}/api/v1/score`,
      {}
    );
  }

  scoreTale(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(
      `${this.getFinancialdataUrl()}/api/v1/score/history`,
      {
        observe: "response",
      }
    );
  }

  scoreConsultHistory(): Observable<HttpResponse<any>> {
    return this.http.get(`${this.getFinancialdataUrl()}/api/v1/monitoring`, {
      observe: "response",
    });
  }

  getTestInfos(): Observable<HttpResponse<any>> {
    return this.http.get(
      `https://cdn.growthbook.io/config/${environment.growthbookKey}`,
      { observe: "response" }
    );
  }

  changePasswordAuthenticated(password: string): Observable<User> {
    if (this.shouldUseMarketplaceNewAPI()) {
      return this.http.put<User>(
        `${this.getEnvironmentUrlAPIV2()}/app/customer/password`,
        { password }
      );
    }
    return this.http.put<User>(
      `${this.getEnvironmentUrl()}/api/v2/password/authenticated`,
      { password }
    );
  }

  getReminders(size: number): Observable<any> {
    return this.http.get(
      `${this.getRemindersUrl()}/api/v1/reminders?size=${size}`
    );
  }

  reminderMarkAsRead(id: Array<any>): Observable<any> {
    return this.http.put(`${this.getRemindersUrl()}/api/v1/reminders`, {
      "ids": id,
      "read": true,
    });
  }

  remindersNoRead(): Observable<any> {
    return this.http.get(`${this.getRemindersUrl()}/api/v1/reminders/check`);
  }

  preApprovedInsert(requestLink: string): Observable<any> {
    return this.http.post(
      `${this.getEnvironmentUrl()}/api/v2/${requestLink}`,
      {}
    );
  }

  creditCardCheckIssuer(
    creditCardNumber: string,
    urlPartner: string,
    envContext: EnvContext
  ): Observable<any> {
    const request = {
      "number": creditCardNumber,
      parameters: {
        partnerSystemIdentifier: envContext.partnerId,
      },
    };

    return this.http.post(
      `${urlPartner}/api/v2/payment/credit-card/check-issuer`,
      request
    );
  }

  getTermsAVista(envContext: EnvContext): Observable<any> {
    return this.http.get(`${envContext.apiUrl}/api/terms/a-vista`, {
      observe: "response",
      responseType: "blob",
    });
  }

  getTermsParcelado(envContext: EnvContext): Observable<any> {
    return this.http.get(`${envContext.apiUrl}/api/terms/parcelado`, {
      observe: "response",
      responseType: "blob",
    });
  }

  sortingHatTrack(request: SortingHatTrackRequestContext): Observable<any> {
    let payload = {
      "data": btoa(JSON.stringify(request)),
    };
    return this.http.post(`${environment.sortingHatUrl}/track`, payload);
  }

  sortingHatDecide(request: SortingHatDecideRequestContext): Observable<any> {
    let payload = {
      "data": btoa(JSON.stringify(request)),
    };
    return this.http.post(`${environment.sortingHatUrl}/decide`, payload);
  }

  isAgreementUpdatable(
    request: UpdateAgreementRequest,
    envContext: EnvContext
  ): Observable<boolean> {
    return this.http.post<boolean>(
      `${envContext.apiUrl}/api/acordo/atualizar/habilitado`,
      request
    );
  }

  updateAgreement(
    request: UpdateAgreementRequest,
    envContext: EnvContext
  ): Observable<Agreement> {
    return this.http.post<Agreement>(
      `${envContext.apiUrl}/api/acordo/atualizar`,
      request
    );
  }


  generatePixExternal(
    installmentCode,
    envContext: EnvContext
  ): Observable<HttpResponse<Pix>> {
    let apiUrl = this.getEnvironmentUrl();
    if (this.isValidEnvContext(envContext)) {
      apiUrl = envContext.apiUrl;
    }

    return this.http.post<Pix>(
      `${apiUrl}/pix`,
      { installmentCode },
      { observe: "response" }
    );
  }

  generatePixInternal(request: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(
      `${this.getGeneratePixUrlAPI()}/payment/v1/pix/qrcode`,
      request,
      { observe: "response" }
    );
  }

  downloadAtivosAgreementLetter(
    request: any,
    envContext: EnvContext
  ): Observable<HttpResponse<any>> {
    return this.http.get(
      `${envContext.apiUrl}/agreement-letter/${request.installmentCode}/download`,
      { observe: "response", responseType: "blob" }
    );
  }


  getPaymentTermsCash(envContext: EnvContext): Observable<any> {
    return this.http.get(`${envContext.apiUrl}/api/terms/a-vista`, {
      observe: "response",
      responseType: "blob",
    });
  }

  getPaymentTermsInstallments(envContext: EnvContext): Observable<any> {
    return this.http.get(`${envContext.apiUrl}/api/terms/parcelado`, {
      observe: "response",
      responseType: "blob",
    });
  }

  getPaymentTermsLending(envContext: EnvContext): Observable<any> {
    return this.http.get(`${envContext.apiUrl}/api/terms/emprestimo`, {
      observe: "response",
      responseType: "blob",
    });
  }

  public adminRegisterCustomer(
    request: AdminRegisterCustomerRequest
  ): Observable<AdminRegisterCustomerResponse> {
    return this.http.post<AdminRegisterCustomerResponse>(
      `${this.getEnvironmentUrlAPIV2()}/admin/auth/register-user`,
      request
    );
  }

}
