export const GtagConfig = {
    register: 'eventRegister',
    login: 'eventLogin',
    hasDebt: 'eventHasDebt',
    debtFound: 'eventDebtFound',
    debtNotFound: 'eventDebtNotFound',
    debtViewed: 'eventViewed',
    ready: 'ready',
    partner: 'partner',
    agreement: 'agreement',
    agreementPrice: 'agreementPrice',
    originalLocationCookie: 'ORIGINAL_LOCATION',
    registerSuccess: 'Register Success',
    loadAds: 'loadAdsense',
    loadAdManager: 'loadAdManager'
}
