import { Router } from "@angular/router";
import { ApiService } from "./../../http/api.service";
import { map, finalize } from "rxjs/operators";
import { Observable } from "rxjs";
import { Partner } from "./../../../models/partner/partner.model";
import { LocalStorageHelperService } from "./../storage/local-storage-helper.service";
import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { SeoConfig } from "@app/core/services/seo/seo.config";
import { EnvContext } from "@app/models";
import { environment } from "@env/environment";
import HtmlUtils from "@app/core/services/utils/html.utils";
import { SeoContext } from "./seo-context.model";
import { AppConfig } from "@app/configs";

@Injectable({
  providedIn: "root",
})
export class SeoService {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private localStorageHelperService: LocalStorageHelperService,
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private document: any
  ) {}

  configure(envContext: EnvContext): void {
    this.setTitle(envContext);
    this.setDescription(envContext);
    this.setCanonical(envContext);
  }

  findContext(envContext: EnvContext): SeoContext {
    return SeoConfig[envContext.id];
  }

  setTitle(envContext: EnvContext): void {
    const seoConfig = SeoConfig[envContext.id];
    const title =
      seoConfig && seoConfig.title
        ? seoConfig.title
        : SeoConfig.marketplace.title;
    this.title.setTitle(title);
  }

  setDescription(envContext: EnvContext): void {
    const seoConfig = SeoConfig[envContext.id];
    const description =
      seoConfig && seoConfig.description
        ? seoConfig.description
        : SeoConfig.marketplace.description;
    this.meta.updateTag({ name: "description", content: description });
  }

  setCanonical(envContext: EnvContext): void {
    HtmlUtils.addCanonical(this.buildCanonicalHref(envContext));
  }

  private buildCanonicalHref(envContext: EnvContext): string {
    const domainArray = environment.domain.split("/");
    const protocol = domainArray[0];
    const host = this.normalizeHost(domainArray[2]);
    const subdomain = envContext.isPartner ? envContext.domains[0] : "";
    const canonicalHref = `${protocol}//www.${host}/${subdomain}`;
    return canonicalHref;
  }

  private normalizeHost(host: string): string {
    return host.replace("www.", "");
  }

  redirectDomain() {
    if (environment.production) {
      const url = window.location.href;

      AppConfig.partnersDomainRedirect.forEach((partner) => {
        if (url.indexOf(`/${partner}.`) != -1) {
          window.location.href = environment.domain;
        }
      });

      this.redirectFolderWebflow(url);
    }
  }

  redirectFolderWebflow(url: string) {
    AppConfig.folderRedirectWebflow.forEach((folder) => {
      if (url.indexOf(`/${folder}`) != -1) {
        window.location.href = `https://renegociacao.acordocerto.com.br/${folder}`;
      }
    });
  }

  recoverCurrentPartner(envContext: EnvContext) {
    const listPartners = JSON.parse(
      this.localStorageHelperService.get(AppConfig.listPartners)
    );
    if (listPartners) {
      this.addPartnerLocalStorage(envContext, listPartners);
    } else {
      const partnersSubscription = this.getPartners()
        .pipe(
          finalize(() => {
            partnersSubscription.unsubscribe();
          })
        )
        .subscribe(
          (partners) => {
            this.localStorageHelperService.add(
              AppConfig.listPartners,
              JSON.stringify(partners)
            );
            this.addPartnerLocalStorage(envContext, partners);
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  addPartnerLocalStorage(envContext: EnvContext, partners: Partner[]) {
    const idContext = envContext.parentPartnerId || envContext.partnerId;
    partners.forEach((partner) => {
      if (idContext === partner.identificador) {
        this.localStorageHelperService.add(
          AppConfig.currentPartnerSystem,
          JSON.stringify(partner)
        );
      }
    });
  }

  checkPartner(envContext: EnvContext) {
    if (!this.checkCurrentPartner(envContext)) {
      this.recoverCurrentPartner(envContext);
    }
  }

  checkCurrentPartner(envContext: EnvContext): boolean {
    const idContext = envContext.parentPartnerId || envContext.partnerId;
    const partner = JSON.parse(
      this.localStorageHelperService.get(AppConfig.currentPartnerSystem)
    );

    if (!partner || partner.identificador !== idContext) {
      this.localStorageHelperService.remove(AppConfig.currentPartnerSystem);
      return false;
    } else {
      return true;
    }
  }

  private getPartners(): Observable<Partner[]> {
    return this.apiService.partnersActive().pipe(
      map((partnerResponse) => {
        return partnerResponse.body;
      })
    );
  }

  redirectToHomeWebflow() {
    if (environment.production) {
      window.location.href = "https://www.acordocerto.com.br/";
    } else {
      window.location.href = "/";
    }
  }
}
