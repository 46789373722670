import { Injectable } from "@angular/core";
import EncoderUtils from "@app/core/services/utils/encoder.utils";

@Injectable({
  providedIn: "root",
})
export class LocalStorageHelperService {
  localStorage: Storage;

  constructor() {
    this.localStorage = window.localStorage;
  }

  check(key: string): boolean {
    return !!this.localStorage.getItem(key);
  }

  get(key: string, decrypt?: boolean): string {
    const valueEncoded = this.localStorage.getItem(key);
    return this.shouldEncryptOrDecrypt(valueEncoded, decrypt)
      ? EncoderUtils.decode(valueEncoded)
      : valueEncoded;
  }

  add(key: string, value: string, encrypt?: boolean): void {
    const valueEncoded = this.shouldEncryptOrDecrypt(value, encrypt)
      ? EncoderUtils.encode(value)
      : value;
    this.localStorage.setItem(key, valueEncoded);
  }

  remove(key: string): void {
    this.localStorage.removeItem(key);
  }

  clear(): void {
    this.localStorage.clear();
  }

  /**
   * @param keys string[] - list of keys to be removed from local storage.
   * @description The "removeByKeys" method iterates over a list of keys and checks if there is an item in the local storage with each key. If an item exists, it is removed.
   */
  removeByKeys(keys: string[]): void {
    keys?.forEach((key) => {
      if (this.check(key)) {
        this.remove(key);
      }
    });
  }

  private shouldEncryptOrDecrypt(value?: string, encrypt?: boolean) {
    return value && (encrypt === undefined || encrypt);
  }
}
