import { Component, OnInit } from '@angular/core';
import { EventConstants, EventHandlerService } from '@app/core/services';

@Component({
  selector: 'app-minor-error',
  templateUrl: './minor-error.component.html',
  styleUrls: ['./minor-error.component.scss']
})
export class MinorErrorComponent implements OnInit {

  constructor(private eventHandlerService: EventHandlerService) { }

  ngOnInit(): void {
    this.eventHandlerService.sendEvent(EventConstants.events.pageViewUrl, {
      fluxo: 'cadastro',
      nome: 'usuario_menor_de_18'
    });
    this.sendEventUserMinor()
    this.sendEventErroCadastro()
  }

  private sendEventUserMinor(): void {
    const properties = {
      category: 'cadastro',
      step: 4,
      step_name: 'menor 18 anos'
    }

    this.eventHandlerService.sendEvent(EventConstants.events.VirtualPageview, properties)
  }

  private sendEventErroCadastro(): void {
    const properties = {
      category: 'cadastro',
      error_type: 'autenticacao cpf',
      step: 4,
      step_name: 'menor 18 anos'
    }

    this.eventHandlerService.sendEvent(EventConstants.events.ErroCadastro, properties)
  }

  handleOkClick(): void {
    this.sendEventOkClick()
  }

  private sendEventOkClick(): void {
    const properties = {
      category: 'cadastro',
      button: 'cta ok',
      step: 4,
      step_name: 'menor 18 anos'
    }

    this.eventHandlerService.sendEvent(EventConstants.events.ClickBotao, properties)
  }

}
