import jwt_decode from "jwt-decode";

export function getCorrelationId(fireboltConfigName: string) {
  const fireboltStore =
    JSON.parse(localStorage.getItem("firebolt-store")) || undefined;

  let partnerAuth = "";
  let correlationId;
  if (fireboltStore) {
    partnerAuth = fireboltStore.sessionKeys[fireboltConfigName];

    const decodedPartnerAuth: { iat: number; unique_id: string } =
      jwt_decode(partnerAuth);
    const { unique_id } = decodedPartnerAuth;

    correlationId = unique_id;
  }

  return correlationId;
}
