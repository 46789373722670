import { Injectable } from "@angular/core";
import { AppConfig } from "@app/configs";
import { ApiService } from "@app/core/http";
import { CookieHelperService } from "@app/core/services/storage/cookie-helper.service";
import { NegativeDebtGroup } from "@app/scopes/financial-services/models/negative-debts.model";
import { NegativeDebtsService } from "@app/scopes/financial-services/negative-debts.service";
import { Observable, of } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ScoreService {
  private cacheDebtsNegative$: Observable<NegativeDebtGroup[]>;
  private cachedScoreResponse: Observable<any>;
  private loadedScore = false;

  constructor(
    private apiService: ApiService,
    private cookieHelperService: CookieHelperService,
    private negativeDebtsService: NegativeDebtsService
  ) { }

  kbaScore() {
    const request: any = {
      "uuid":
        this.cookieHelperService.get(AppConfig.kbaScoreUuid, false) || null,
    };

    return this.apiService.kbaScore(request).pipe(
      map((r) => {
        const body = r.body;
        this.setUuidScore(body.uuid);
        return body;
      })
    );
  }

  kbaScoreAnswer(answer: string) {
    const request: any = {
      "uuid": this.cookieHelperService.get(AppConfig.kbaScoreUuid, false),
      "answer": answer,
    };

    return this.apiService.kbaScoreAnswer(request).pipe(
      map((r) => {
        return r.body;
      })
    );
  }

  kbaValidate() {
    if (this.apiService.shouldUseMarketplaceNewAPI()) {
      return this.apiService.kbaValidateAPIV2().pipe(
        map((r) => {
          return r.body.data;
        })
      );
    } else {
      return this.apiService.kbaValidate().pipe(
        map((r) => {
          return r.body;
        })
      );
    }
  }

  fetchScoreAndSave() {
    return this.apiService.onScore().pipe(
      map((r) => {
        this.cachedScoreResponse = of(r);
        this.loadedScore = true;
        return r;
      })
    );
  }

  onScore() {
    if (this.loadedScore) {
      return this.cachedScoreResponse;
    } else {
      return this.fetchScoreAndSave();
    }
  }

  getNegativeDebts() {
    if (this.cacheDebtsNegative$) {
      return this.cacheDebtsNegative$;
    }

    this.cacheDebtsNegative$ = this.negativeDebtsService.getGroupedNegativeDebts()
      .pipe(shareReplay(1));

    return this.cacheDebtsNegative$;
  }

  scoreTale() {
    return this.apiService.scoreTale().pipe(
      map((r) => {
        return r.body;
      })
    );
  }

  scoreConsultHistory() {
    return this.apiService.scoreConsultHistory().pipe(
      map((r) => {
        return r.body;
      })
    );
  }

  private setUuidScore(uuid: string): void {
    if (!this.cookieHelperService.check(AppConfig.kbaScoreUuid)) {
      this.cookieHelperService.add(AppConfig.kbaScoreUuid, uuid, false, 0.04);
    }
  }
}
