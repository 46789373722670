import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AcNotifyService {
	errorOccurred: EventEmitter<string> = new EventEmitter<string>();

  notifyError(message: string) {
		this.errorOccurred.emit(message);
	}
}
