import { Injectable } from "@angular/core";
import { AppConfig } from "@app/configs";

import { CookieHelperService } from "../../storage/cookie-helper.service";
import DateUtils from "../../utils/DateUtils";
import { rLTracksAuthDataProps } from "./types/types-userAuthentication-props";
import { getSessionID } from "redline-client-sdk";

@Injectable({
  providedIn: "root",
})
export class RlUserAuthenticationService {
  constructor(private cookieHelperService: CookieHelperService) {}

  setRlSlugAuthenticatedCookie() {
    const href = window?.location?.href;
    const referrer = href?.includes("login") ? "sign-in" : "sign-up";

    const hasTokenAuth = this.cookieHelperService.check(
      AppConfig.auth.tokenCookie
    );
    const authenticationType = hasTokenAuth ? "cached-sign-in" : referrer;

    this.addCookieRLTracksAuth(authenticationType);
  }

  addCookieRLTracksAuth(authenticationType: string) {
    const sessionId = getSessionID();

    const rLTracksAuthData: rLTracksAuthDataProps = {
      authenticationType,
      correlationId: sessionId,
    };

    this.cookieHelperService.add(
      AppConfig.rlTracksAuthCookie,
      JSON.stringify(rLTracksAuthData),
      true,
      DateUtils.fromNow(120, "days")
    );
  }

  correlationId() {
    const hasRlTracksAuthCookie = this.cookieHelperService.check(
      AppConfig.rlTracksAuthCookie
    );

    if (hasRlTracksAuthCookie) {
      const rLTracksAuthData: rLTracksAuthDataProps = JSON.parse(
        this.cookieHelperService.get(AppConfig.rlTracksAuthCookie, true)
      );

      return rLTracksAuthData.correlationId;
    }
    return "";
  }
}
