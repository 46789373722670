import { Injectable } from "@angular/core";
import { CookieHelperService } from "../storage/cookie-helper.service";

@Injectable({
  providedIn: "root",
})
export class MoodService {
  constructor(private cookieHelperService: CookieHelperService) {}

  getMood(): any {
    const mood = (window as any).mood;
    if (typeof mood === "undefined") {
      console.log("[mood] is not defined.");
    }
    return mood;
  }

  private getObjectFromCookie(cookieName: string): any {
    let result: any;
    if (this.cookieHelperService.check(cookieName)) {
      result = JSON.parse(this.cookieHelperService.get(cookieName, false));
    }
    return result;
  }

  getUtm(): any {
    let utm: any;
    const mood = this.getMood();
    if (mood) {
      utm = mood.getUtm();
    } else {
      utm = this.getObjectFromCookie("mood_utm");
    }
    return utm;
  }

  getGclid(): any {
    let gclid: any;
    const mood = this.getMood();
    if (mood) {
      gclid = mood.getGclid();
    } else {
      gclid = this.getObjectFromCookie("mood_gclid");
    }
    return gclid;
  }
}
