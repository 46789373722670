<div *ngIf="showError" class="ac-notify {{ status }}">
  {{ message }}
  <svg
    (click)="closeMessage()"
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 223.364 223.364">
    <defs>
      <style>
        .a {
          fill: none;
          stroke: #fff;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 16px;
        }
      </style>
    </defs>
    <g transform="translate(-1095.576 -378.394)">
      <circle
        class="a"
        cx="103.682"
        cy="103.682"
        r="103.682"
        transform="translate(1103.576 386.394)" />
      <line
        class="a"
        x2="86.402"
        y2="86.402"
        transform="translate(1164.057 446.875)" />
      <line
        class="a"
        x1="86.402"
        y2="86.402"
        transform="translate(1164.057 446.875)" />
    </g>
  </svg>
</div>
