import { Injectable, OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AuthenticationService } from "@app/core/authentication/authentication.service";
import { EnvContextResolverService } from "@app/core/services/env-context-resolver/env-context-resolver.service";
import { EnvContext, User } from "@app/models";
import { Subscription } from "rxjs";
import { IndicativeSenderService } from "./indicative-event-sender.service";
import { DataLayerEventSenderService } from "./data-layer-event-sender.service";
import { CookieHelperService } from "../storage/cookie-helper.service";
import { LocalStorageHelperService } from "@app/core/services/storage/local-storage-helper.service";
import { AppConfig } from "@app/configs";
import { FacebookPixelService } from "../facebook-pixel/facebook-pixel.service";

@Injectable({
  providedIn: "root",
})
export class EventHandlerService implements OnDestroy {
  envContext: EnvContext;
  user: User;
  userSubscription: Subscription;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private envContextResolverService: EnvContextResolverService,
    private indicativeSender: IndicativeSenderService,
    private dataLayerEventSenderService: DataLayerEventSenderService,
    private cookieHelperService: CookieHelperService,
    private localStorageHelperService: LocalStorageHelperService,
    private facebookPixelService: FacebookPixelService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.envContext = this.envContextResolverService.current();
      }
    });
    this.userSubscription = this.authenticationService.user$.subscribe(
      (user) => (this.user = user)
    );
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  sendEvent(eventName: string, params?: any, origin?: string): void {
    this.sendEventToAllProviders(eventName, params, origin);
  }

  sendEventToAllProviders(eventName: string, params: any, origin?: string) {
    params = this.normalizeParams(params, origin);
    this.indicativeSender.sendEvent(eventName, params, this.user);
    this.dataLayerEventSenderService.sendEvent(eventName, params, this.user);
  }

  private normalizeParams(params: any, origin: string): any {
    // origin = origin || this.envContext ? (this.envContext.parentPartnerId || this.envContext.partnerId) : null;
    origin = origin || this.envContext ? this.envContext.partnerId : null;
    params = params || {};
    const predefinedAuthValues = {
      customerIdHash: params?.customerIdHash,
      customerAdminIdHash: params?.customerAdminIdHash,
      adminMode: params?.adminMode,
    };

    if (this.user) {
      if (
        this.localStorageHelperService.check(AppConfig.adminMode) &&
        this.localStorageHelperService.check(AppConfig.userHashId)
      ) {
        params.customerIdHash = this.user.customerIdHash;
        params.customerAdminIdHash = this.localStorageHelperService.get(
          AppConfig.userHashId
        );
      } else {
        params.customerIdHash = this.user.customerIdHash;
      }
    } else if (this.localStorageHelperService.check(AppConfig.userHashId)) {
      params.customerIdHash = this.localStorageHelperService.get(
        AppConfig.userHashId
      );
    }

    params.credor = params.credor || origin;
    params.origem = params.origem || origin;

    params["adminMode"] = "false";
    if (this.localStorageHelperService.check(AppConfig.adminMode)) {
      params["adminMode"] = "true";
    }

    this.addInitialReferrer(params);
    this.addUtm(params);
    this.addGclid(params);
    this.addFacebookCookies(params);
    this.addCredor(params);

    Object.entries(predefinedAuthValues).forEach(([key, value]) => {
      if (!!value) {
        params[key] = value;
      }
    });

    return params;
  }

  private addInitialReferrer(params: any) {
    let initialReferrer = "not_set";
    if (this.cookieHelperService.check("mood_session")) {
      const moodSession = JSON.parse(
        this.cookieHelperService.get("mood_session", false)
      );
      if (moodSession) {
        initialReferrer = moodSession.initialReferrer || "direct";
      }
    }
    params["ac.initialReferrer"] = initialReferrer;
  }

  private addUtm(params: any) {
    if (this.cookieHelperService.check("mood_utm")) {
      const utm = JSON.parse(this.cookieHelperService.get("mood_utm", false));
      if (utm) {
        params.utm_source = utm.source;
        params.utm_medium = utm.medium;
        params.utm_campaign = utm.campaign;
        params.utm_term = utm.term;
        params.utm_content = utm.content;
      }
    }
  }

  private addGclid(params: any) {
    if (this.cookieHelperService.check("mood_gclid")) {
      const gclid = this.cookieHelperService.get("mood_gclid", false);
      if (gclid) {
        params.gclid = gclid;
      }
    }
  }

  private addFacebookCookies(params) {
    const availableCookies = this.facebookPixelService.getAvailableCookies();

    if (availableCookies.fbc) {
      params["fbc"] = availableCookies.fbc;
    }

    if (availableCookies.fbp) {
      params["fbp"] = availableCookies.fbp;
    }
  }

  private addCredor(params: any) {
    if (this.cookieHelperService.check(AppConfig.credor)) {
      if (!params["credor"]) {
        params["credor"] = decodeURIComponent(
          this.cookieHelperService.get(AppConfig.credor, false)
        );
      }
      params["origin"] = decodeURIComponent(
        this.cookieHelperService.get(AppConfig.credor, false)
      );
    }

    if (this.envContext) {
      params["credor_divida"] = this.envContext["partnerId"];
    }
  }

  public sendEventHj(eventName: string): void {
    const hj = (window as any).hj;

    if (typeof hj === "undefined") {
      console.log("[hj] not defined.");
    } else {
      hj("event", eventName);
    }
  }
}
