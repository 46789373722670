import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import jwt_decode from "jwt-decode";
import { Injectable } from "@angular/core";
import { AppConfig } from "@app/configs/app.config";
import { ApiService } from "@app/core/http/api.service";
import { CookieHelperService } from "@app/core/services/storage/cookie-helper.service";
import { User, SitePolicyUserAccept } from "@app/models";
import { CurrentUser } from "@app/models/auth/currentUser.model";
import { BehaviorSubject, Observable } from "rxjs";
import { flatMap, map } from "rxjs/operators";
import { MoodService } from "../services/mood/mood.service";
import { LocalStorageHelperService } from "../services/storage/local-storage-helper.service";
import { RecordHandlerService } from "@app/core/services/record-handler/record-handler.service";
import { RegisterPreSaveRequest } from "@app/models/auth/register-pre-save-request.model";
import {
  KbaRegisterResponse,
  KbaRegisterResponseNewAPI,
} from "@app/models/auth/kba-register-response.model";
import { KbaRegisterRequest } from "@app/models/auth/kba-register-request.model";
import { FacebookPixelService } from "../services/facebook-pixel/facebook-pixel.service";
import DateUtils from "@app/core/services/utils/DateUtils";
import {
  SigninPayload,
  SignupPayload,
} from "@app/models/auth/social-user.model";
import { environment } from "@env/environment";

interface Token {
  authorized: boolean;
  exp: number;
  role: string;
  sub: string;
  tokenType: string;
  uuid: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  user$: Observable<User>;
  public subject: BehaviorSubject<User>;

  constructor(
    private cookieHelperService: CookieHelperService,
    private apiService: ApiService,
    private localStorageHelperService: LocalStorageHelperService,
    private recordHandlerService: RecordHandlerService,
    private facebookPixelService: FacebookPixelService,
    private http: HttpClient,
    private moodService: MoodService
  ) {
    this.subject = new BehaviorSubject<User>(this.getSubject());
    this.user$ = this.subject.asObservable();
  }

  isValidToken(): boolean {
    const token = this.cookieHelperService.get(
      AppConfig.auth.tokenCookie,
      false
    );

    function isExpiredToken(timestamp: number) {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      return timestamp < currentTimestamp;
    }

    try {
      const tokenValid: Token = jwt_decode(token);
      if (tokenValid) {
        const { exp } = tokenValid;
        return !isExpiredToken(exp);
      }

      return true;
    } catch (error) {
      return false;
    }
  }

  isAuthenticated(): boolean {
    const isValidToken = this.isValidToken();
    return isValidToken && this.hasSubjectCookie();
  }

  searchUserV2(documento: string): Observable<User> {
    return this.apiService.searchUserV2(documento).pipe(
      map((searchUserResponse) => {
        this.localStorageHelperService.add(
          AppConfig.userHashId,
          searchUserResponse.body.data["customerIdHash"]
        );
        return searchUserResponse.body.data;
      })
    );
  }

  getAdminToken(documento: string): Observable<any> {
    return this.apiService.recoverAdminToken(documento).pipe(
      map((recoverResponse) => {
        let token = this.getTokenFromResponse(recoverResponse);
        this.addTokenCookie(token);
        this.localStorageHelperService.add(AppConfig.adminMode, token);
      })
    );
  }

  login(payload: SigninPayload): Observable<HttpResponse<any>> {
    return this.http
      .post<any>(`${environment.apiUrlV2}/public/auth/signin`, payload, {
        observe: "response",
      })
      .pipe(
        map((signInResponse) => {
          const token = signInResponse.body.data.auth.access_token;
					console.log("!!!! TOKEN !!!!!", token)
          this.addTokenCookie(token);
          const user = this.recoveryUser(signInResponse.body.data.user);
          this.addSubjectCookie(user);
          this.subject.next(user);

          return signInResponse;
        })
      );
  }

  validateCurrentPassword(payload: SigninPayload): Observable<boolean> {
    return this.http
      .post<any>(
        `${environment.apiUrlV2}/app/customer/validate-password`,
        payload,
        {
          observe: "response",
        }
      )
      .pipe(map((verifyRes) => verifyRes.body?.data?.isValid));
  }

  associateGoogle({ googleToken, signInToken }): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + signInToken)
      .set("X-Auth-Token", signInToken);

    return this.http.post<User>(
      `${environment.apiUrlV2}/app/customer/associate-google`,
      { googleToken },
      { headers: headers, observe: "response" }
    );
  }

  implicitLogin(token: string): Observable<any> {
    return this.apiService.implicitLogin(token).pipe(
      flatMap((recoverResponse) => {
        this.addTokenCookie(this.getTokenFromResponse(recoverResponse));
        return this.apiService.currentUser();
      }),
      map((currentUserResponse) => {
        const user = this.recoveryUser(currentUserResponse.body);

        const w = window as any;
        let userParam = {
          email: user.email,
        };
        w.dataLayer.push(userParam);

        this.addSubjectCookie(user);
        this.subject.next(user);

        this.recordHandlerService.startRecord(user.customerIdHash);

        return user;
      })
    );
  }

  validaIdQuestion(request): Observable<any> {
    return this.apiService.validaIdQuestion(request).pipe(
      map((validaIdResponse) => {
        const validaQuestion = validaIdResponse.body;
        return validaQuestion;
      })
    );
  }

  validaIdAnswer(request): Observable<any> {
    return this.apiService.validaIdAnswer(request).pipe(
      map((validaIdResponse) => {
        const validaAnswer = validaIdResponse.body;
        return validaAnswer;
      })
    );
  }

  checkPasswordEmail(request): Observable<any> {
    return this.apiService
      .checkPasswordEmail(request)
      .pipe(map((checkPasswordReturn) => checkPasswordReturn.body));
  }

  checkPasswordPhone(request): Observable<any> {
    return this.apiService
      .checkPasswordPhone(request)
      .pipe(map((checkPasswordReturn) => checkPasswordReturn.body));
  }

  generatePasswordCode(request: any): Observable<any> {
    return this.apiService
      .generatePasswordCode(request)
      .pipe(map((checkTokenReturn) => checkTokenReturn.body));
  }

  checkPasswordCode(request: any): Observable<any> {
    return this.apiService
      .checkPasswordCode(request)
      .pipe(map((checkTokenReturn) => checkTokenReturn.body));
  }

  checkPasswordCodeAPIV2(request: any): Observable<any> {
    return this.apiService
      .checkPasswordCodeAPIV2(request)
      .pipe(map((checkTokenReturn) => checkTokenReturn.body));
  }

  changePassword(request: any): Observable<any> {
    return this.apiService
      .changePassword(request)
      .pipe(map((passwordReturn) => passwordReturn.body));
  }

  registerPreSave(
    request: RegisterPreSaveRequest
  ): Observable<HttpResponse<any>> {
    return this.apiService.registerPreSave(request);
  }

  changePasswordAuthenticated(newPassword: string): Observable<User> {
    return this.apiService.changePasswordAuthenticated(newPassword);
  }

  hasDebtInvalidateCache(
    userDocument: string,
    partnerId: string
  ): Observable<any> {
    return this.apiService.hasDebtInvalidateCache(userDocument, partnerId);
  }

  registerPassword(request: any): Observable<User> {
    return this.apiService
      .registerPassword(request)
      .pipe(map((responsePassword) => responsePassword.body));
  }

  registerUser(
    request: User,
    version: string
  ): Observable<User | HttpResponse<any>> {
    this.addGclidToRequest(request);
    this.facebookPixelService.addFacebookToRequest(request);
    return this.apiService.registerUser(request, version).pipe(
      flatMap((registerUserResponse) => {
        this.addTokenCookie(this.getTokenFromResponse(registerUserResponse));
        return this.apiService.currentUser();
      }),
      map((currentUserResponse) => {
        const user = this.recoveryUser(currentUserResponse.body);

        const w = window as any;
        let userParam = {
          email: user.email,
        };
        w.dataLayer.push(userParam);

        this.addSubjectCookie(user);
        this.subject.next(user);
        return user;
      })
    );
  }

  deprecatedRegisterCustomer(
    request: User
  ): Observable<User | HttpResponse<any>> {
    this.addGclidToRequest(request);
    this.facebookPixelService.addFacebookToRequest(request);
    return this.apiService.registerCustomer(request).pipe(
      flatMap((registerUserResponse) => {
        this.addTokenCookie(this.getTokenFromResponse(registerUserResponse));
        return this.apiService.currentUser();
      }),
      map((currentUserResponse) => {
        const user = this.recoveryUser(currentUserResponse.body);

        const w = window as any;
        let userParam = {
          email: user.email,
        };
        w.dataLayer.push(userParam);

        this.addSubjectCookie(user);
        this.subject.next(user);
        return user;
      })
    );
  }

  registerCustomer(
    payload: SignupPayload
  ): Observable<User | HttpResponse<any>> {
    this.addGclidToRequest(payload);
    this.facebookPixelService.addFacebookToRequest(payload);
    return this.http
      .post<any>(`${environment.apiUrlV2}/public/auth/signup`, payload, {
        observe: "response",
      })
      .pipe(
        map((currentUserResponse) => {
          const token = currentUserResponse.body.data.auth.access_token;
          this.addTokenCookie(token);
          const user = this.recoveryUser(currentUserResponse.body.data.user);
          this.addSubjectCookie(user);
          this.subject.next(user);

          const w = window as any;
          let userParam = {
            email: user.email,
          };
          w.dataLayer.push(userParam);

          return user as User;
        })
      );
  }

  registerTermsOfUseAccept(user: User) {
    let token = localStorage.getItem("tmp_token");
    return this.apiService.editUser(user, token).pipe(
      flatMap((_) => {
        this.addTokenCookie(token);
        localStorage.removeItem("tmp_token");
        return this.apiService.currentUser();
      }),
      map((currentUserResponse) => {
        const user = this.recoveryUser(currentUserResponse.body);
        this.addSubjectCookie(user);
        this.subject.next(user);
        return user;
      })
    );
  }

  sitePolicyUserAccept(
    request: SitePolicyUserAccept
  ): Observable<HttpResponse<any>> {
    return this.apiService.sitePolicyUserAccept(request);
  }

  getCurrentSitePolicy(): Observable<HttpResponse<any>> {
    return this.apiService.getCurrentSitePolicy();
  }

  getLastSitePolicyAccepted(cpf): Observable<HttpResponse<any>> {
    return this.apiService.getLastSitePolicyAccepted(cpf.replace(/\D/g, ""));
  }

  resetSitePolicyUserAccepted(
    request: SitePolicyUserAccept
  ): Observable<HttpResponse<any>> {
    return this.apiService.resetSitePolicyUserAccepted(request);
  }

  disableSitePolicyUserAccepted(
    request: SitePolicyUserAccept
  ): Observable<HttpResponse<any>> {
    return this.apiService.disableSitePolicyUserAccepted(request);
  }

  getKbaQuestion(request: KbaRegisterRequest): Observable<KbaRegisterResponse> {
    return this.apiService.kbaRegister(request);
  }

  shouldUseMarketplaceNewAPI(): boolean {
    return this.apiService.shouldUseMarketplaceNewAPI();
  }

  getKbaQuestionNewAPI(
    request: KbaRegisterRequest
  ): Observable<KbaRegisterResponseNewAPI> {
    return this.apiService.kbaRegisterNewAPI(request);
  }

  logout(): Observable<any> {
    this.removeCookies();
    this.removeLocalstorage();
    this.subject.next(null);
    return this.apiService.logout();
  }

  hasTokenCookie(): boolean {
    return this.cookieHelperService.check(AppConfig.auth.tokenCookie);
  }

  getToken(): string {
    return this.cookieHelperService.get(AppConfig.auth.tokenCookie, false);
  }

  public hasTokenAdminCookie(): boolean {
    return this.cookieHelperService.check(AppConfig.auth.tokenAdminCookie);
  }

  public getTokenAdminCookie(): string {
    return this.cookieHelperService.get(AppConfig.auth.tokenAdminCookie, false);
  }

  hasSubjectCookie(): boolean {
    return this.cookieHelperService.check(AppConfig.auth.subjectCookie);
  }

  getSubject(): User {
    let user: User = null;
    try {
      const subject = this.cookieHelperService.get(
        AppConfig.auth.subjectCookie
      );
      if (!subject) {
        return null;
      }
      user = JSON.parse(subject);
    } catch {
      this.removeCookies();
    }
    return user;
  }

  getTokenFromResponse(response: HttpResponse<any>): string {
    return response.headers.get(AppConfig.auth.tokenHeader) || (response?.body?.a_token || response.body.data.auth.access_token);
  }

  private addTokenCookie(token: string): void {
    if (!token) {
      return;
    }

    this.cookieHelperService.add(
      AppConfig.auth.tokenCookie,
      token,
      false,
      DateUtils.fromNow(59, "days")
    );
  }

  public addTokenAdminCookie(token: string): void {
    this.cookieHelperService.add(
      AppConfig.auth.tokenAdminCookie,
      token,
      false,
      DateUtils.fromNow(59, "days")
    );
  }

  public addSubjectCookie(user: User): void {
    if (!user) {
      return;
    }
    this.cookieHelperService.add(
      AppConfig.auth.subjectCookie,
      JSON.stringify(user),
      true,
      DateUtils.fromNow(59, "days")
    );
  }

  removeCookies(): void {
    this.cookieHelperService.remove(AppConfig.optimizedOptionCookie);
    this.cookieHelperService.remove(AppConfig.auth.tokenCookie);
    this.cookieHelperService.remove(AppConfig.auth.subjectCookie);
    this.cookieHelperService.remove(AppConfig.partner);
    this.cookieHelperService.remove(AppConfig.kbaScoreUuid);
    this.cookieHelperService.remove(AppConfig.implicitLogin);
    this.cookieHelperService.remove(AppConfig.auth.tokenAdminCookie);
  }

  removeLocalstorage(): void {
    // this.localStorageHelperService.remove(AppConfig.adminMode);
    const getUserHashId = this.localStorageHelperService.get(
      AppConfig.userHashId
    );

    this.localStorageHelperService.clear();
    if (getUserHashId) {
      this.localStorageHelperService.add(AppConfig.userHashId, getUserHashId);
    }
  }

  public currentUser(): Observable<CurrentUser> {
    return this.apiService.currentUser().pipe(
      map((currentUserResponse) => {
        return currentUserResponse.body;
      })
    );
  }

  recoveryUser(user: User): User {
    let currentUser = user;
    this.currentUser().subscribe((user) => {
      if (user.roles) {
        user.roles.forEach((role: string) => {
          if (role === AppConfig.roleAdmin) {
            currentUser["admin"] = true;
            this.addSubjectCookie(currentUser);
          }
        });
      }
    });
    return currentUser;
  }

  private addGclidToRequest(request: User): void {
    let gclid = this.moodService.getGclid();
    if (gclid) {
      request.parameters = request.parameters || {};
      request.parameters.gclid = gclid;
    }
  }
}
