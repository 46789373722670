type Time = "minutes" | "hours" | "days";

export default class DateUtils {
  /**
   *
   * @param value number
   * @param time 'minutes' | 'hours' | 'days'
   * @returns Date
   */
  static fromNow(value: number, time: Time): Date {
    const currentData = new Date();

    if (time === "minutes") {
      currentData.setMinutes(currentData.getMinutes() + value);
    }
    if (time === "hours") {
      currentData.setHours(currentData.getHours() + value);
    }
    if (time === "days") {
      currentData.setDate(currentData.getDate() + value);
    }

    return currentData;
  }

  /**
   *
   * @returns The current date at midnight, effectively representing the end of the current day.
   */
  static expiresTodayAtMidnight() {
    const expirationDate = new Date();
    expirationDate.setHours(23, 59, 0, 0);

    return expirationDate;
  }

  /**
   *  Formats a date string to the "dd/MM/yyyy" (Short Date) format.
   * @param date - The input date string in the "yyyy-MM-dd" format.
   * @returns A string representing the date in the "dd/MM/yyyy" format.
   */
  static formatDateToShortFormat(date: string): string {
    const parts: string[] = date.split("-");

    if (parts.length === 3) {
      const [year, month, day] = parts;
      return `${day}/${month}/${year}`;
    } else {
      console.error("Invalid date format:", date);
      return date;
    }
  }

  /**
   * Formats a date string in Brazilian Portuguese (pt-BR) style, considering the specified time zone.
   * @param {string} dateString - The input date string to be formatted.
   * @returns {string} - The formatted date string in "DD/MM/YYYY" format, or null if the input is invalid.
   * @example
   * const datePTBR = dateUtils.formatDatePTBR("2023-12-26T00:00:00.000Z");
   * Result: datePTBR is "26/12/2023"
   */
  static formatDatePTBR(dateString: string) {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) return "Invalid Date";

    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      timeZone: "UTC",
    };

    const dateFormatted = new Intl.DateTimeFormat("pt-BR", options).format(
      date
    );

    return dateFormatted;
  }
}
