import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  AnonymousGuard,
  AuthenticationGuard,
  PartnerGuard,
} from "@app/core/guards";
import { MinorErrorComponent } from "./modules/register/register-v4/minor-error/minor-error.component";
import { NoDataRegisterComponent } from "./modules/register/register-v4/no-data-register/no-data-register.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login-cadastro",
    pathMatch: "full",
  },
  {
    path: "redirect-handler",
    loadChildren: () =>
      import("./modules/redirect-handler/redirect-handler.module").then(
        (m) => m.RedirectHandlerModule
      ),
  },
  {
    path: "login-cadastro",
    loadChildren: () =>
      import("./modules/login-or-register/login-or-register.module").then(
        (m) => m.LoginOrRegisterModule
      ),
  },
  {
    path: "login-consumidor-positivo",
    loadChildren: () =>
      import("./modules/implicit-login/implicit-login.module").then(
        (m) => m.ImplicitLoginModule
      ),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./modules/login/login.module").then((m) => m.LoginModule),
    canActivate: [AnonymousGuard],
  },
  {
    path: "cadastro",
    loadChildren: () =>
      import("./modules/register/register.module").then(
        (m) => m.RegisterModule
      ),
    canActivate: [AnonymousGuard],
  },
  {
    path: "cadastro-consumidor-positivo",
    loadChildren: () =>
      import("./modules/consumidor-positivo/consumidor-positivo.module").then(
        (m) => m.ConsumidorPositivoModule
      ),
  },
  {
    path: "recuperar-senha",
    loadChildren: () =>
      import("./modules/recover-password/recover-password.module").then(
        (m) => m.RecoverPasswordModule
      ),
    canActivate: [AnonymousGuard],
  },
  {
    path: "leads",
    loadChildren: () =>
      import("./modules/lead-redirect/lead-redirect.module").then(
        (m) => m.LeadRedirectModule
      ),
    canActivate: [AuthenticationGuard],
  },
  {
    path: "monitoramento",
    loadChildren: () =>
      import("./modules/score/score.module").then((m) => m.ScoreModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: "ofertas",
    loadChildren: () =>
      import("./modules/offer/offer.module").then((m) => m.OfferModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: "descadastro",
    loadChildren: () =>
      import("./modules/unsubscribe/unsubscribe.module").then(
        (m) => m.UnsubscribeModule
      ),
  },
  {
    path: "bloqueio-contato",
    loadChildren: () =>
      import("./modules/contact-block/contact-block.module").then(
        (m) => m.ContactBlockModule
      ),
  },
  {
    path: "faq",
    loadChildren: () =>
      import("./modules/faq/faq.module").then((m) => m.FaqModule),
  },
  {
    path: "fale-conosco",
    loadChildren: () =>
      import("./modules/contact-us/contact-us.module").then(
        (m) => m.ContactUsModule
      ),
  },
  {
    path: "minha-conta",
    loadChildren: () =>
      import("./modules/my-account/my-account.module").then(
        (m) => m.MyAccountModule
      ),
    canActivate: [AuthenticationGuard],
    canLoad: [AuthenticationGuard],
  },
  {
    path: "conta-cancelada",
    loadChildren: () =>
      import("./modules/cancel-success/cancel-success.module").then(
        (m) => m.AccountCancelModule
      ),
  },
  {
    path: "minhas-dividas",
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthenticationGuard],
  },
  {
    path: "dashboard",
    redirectTo: "minhas-dividas",
    pathMatch: "full",
  },
  {
    path: "negociar-dividas",
    redirectTo: "minhas-dividas",
    pathMatch: "full",
  },
  {
    path: "minor-error",
    component: MinorErrorComponent,
  },
  {
    path: "no-data-register",
    component: NoDataRegisterComponent,
  },
  {
    path: "meus-acordos",
    loadChildren: () =>
      import("./modules/my-agreements/my-agreements.module").then(
        (m) => m.MyAgreementsModule
      ),
    canActivate: [AuthenticationGuard],
    canLoad: [AuthenticationGuard],
  },
  {
    path: "acordos-fechados",
    redirectTo: "meus-acordos",
    pathMatch: "full",
  },
  {
    path: "blog",
    loadChildren: () =>
      import("./modules/blog/blog.module").then((m) => m.BlogModule),
  },
  {
    path: "hit-captcha",
    loadChildren: () =>
      import("./modules/hit-captcha/hit-captcha.module").then(
        (m) => m.HitCaptchaModule
      ),
  },
  {
    path: "not-found",
    loadChildren: () =>
      import("./modules/not-found/not-found.module").then(
        (m) => m.NotFoundModule
      ),
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./modules/admin/admin.module").then((m) => m.AdminModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: "extrato",
    loadChildren: () =>
      import("./modules/extract/extract.module").then(
        (m) => m.DebtSearchModule
      ),
    canActivate: [AuthenticationGuard],
  },

  /*Claro*/
  {
    path: "netfatura",
    redirectTo: "claro",
  },
  {
    path: "clarofatura",
    redirectTo: "claro",
  },
  {
    path: "net",
    redirectTo: "claroresidencial",
  },

  /*Crediativos*/
  {
    path: "credigy",
    redirectTo: "crediativos",
  },
  {
    path: "fundos",
    redirectTo: "crediativos",
  },

  {
    path: ":partner",
    loadChildren: () =>
      import("./modules/partner/partner.module").then((m) => m.PartnerModule),
    canActivate: [PartnerGuard],
  },
  {
    path: "**",
    loadChildren: () =>
      import("./modules/not-found/not-found.module").then(
        (m) => m.NotFoundModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
      onSameUrlNavigation: "reload",
      scrollPositionRestoration: "enabled",
      scrollOffset: [0, 0],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
