import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AcButtonComponent } from "./atoms/ac-button/ac-button.component";
import { AcTagComponent } from "./atoms/ac-tag/ac-tag.component";
import { AcInfoButtonComponent } from "./atoms/ac-info-button/ac-info-button.component";
import { AcCardComponent } from "./atoms/ac-card/ac-card.component";
import { AcBaseCardComponent } from "./atoms/ac-base-card/ac-base-card.component";
import { AcBvsDisclaimerComponent } from "./atoms/ac-bvs-disclaimer/ac-bvs-disclaimer.component";
import { AcCardButtonComponent } from "./atoms/ac-card-button/ac-card-button.component";
import { AcStateProgressComponent } from "./molecules/ac-state-progress/ac-state-progress.component";
import { AcPageTagComponent } from "./atoms/ac-page-tag/ac-page-tag.component";
import { AcEmailSpellCheckerComponent } from './atoms/ac-email-spell-checker/ac-email-spell-checker.component'
import { AcNotifyComponent } from "./atoms/ac-notify/ac-notify.component";
import { AcCarouselModule } from "./molecules/ac-carousel/ac-carousel.module";
import { AcTextComponent } from "./atoms/ac-text/ac-text.component";
import { AcCardTitleComponent } from "./atoms/ac-card-title/ac-card-title.component";
import { AcIconComponent } from "./atoms/ac-icon/ac-icon.component";
import { AcModalComponent } from "./organisms/ac-modal/ac-modal.component";
import { AcIconCloseComponent } from "./atoms/ac-icon/ac-icon-close/ac-icon-close.component";
import { AcIconAttentionComponent } from "./atoms/ac-icon/ac-icon-attention/ac-icon-attention.component";
import { AcInfoCardComponent } from "./atoms/ac-info-card/ac-info-card.component";
import { AcOffersTriggersComponent } from './molecules/ac-offers-triggers/ac-offers-triggers.component';
import { AcChatbotComponent } from './organisms/ac-chatbot/ac-chatbot.component';
import { AcSelectSearchComponent } from './atoms/ac-select-search/ac-select-search.component';

@NgModule({
  declarations: [
    AcButtonComponent,
    AcTagComponent,
    AcInfoButtonComponent,
    AcCardComponent,
    AcBaseCardComponent,
    AcBvsDisclaimerComponent,
    AcCardButtonComponent,
    AcStateProgressComponent,
    AcPageTagComponent,
    AcEmailSpellCheckerComponent,
		AcNotifyComponent,
    AcTextComponent,
    AcCardTitleComponent,
    AcIconComponent,
    AcModalComponent,
    AcIconCloseComponent,
    AcIconAttentionComponent,
		AcInfoCardComponent,
    AcOffersTriggersComponent,
    AcChatbotComponent,
    AcSelectSearchComponent,
  ],
  imports: [CommonModule, AcCarouselModule, FormsModule,],
  exports: [
    AcButtonComponent,
    AcTagComponent,
    AcInfoButtonComponent,
    AcCardComponent,
    AcBaseCardComponent,
    AcBvsDisclaimerComponent,
    AcCardButtonComponent,
    AcStateProgressComponent,
    AcPageTagComponent,
    AcEmailSpellCheckerComponent,
    AcCarouselModule,
    AcNotifyComponent,
    AcTextComponent,
    AcCardTitleComponent,
    AcIconComponent,
    AcModalComponent,
    AcIconCloseComponent,
    AcIconAttentionComponent,
		AcInfoCardComponent,
    AcOffersTriggersComponent,
		AcChatbotComponent,
		AcSelectSearchComponent
  ],
})
export class GlobalComponentsModule {}
