import { FeedbackContext } from "@app/models";
import { SharerParams } from "@app/models/sharer/sharer.model";

export const AppConfig = {
	//#region response APIs
  sortingDebtsReponse: "SORTING_DEBTS_RES",
  //#endregion response APIs

  //#region A/B tests
  resultsAbPixCookie: "ab_pix",
  resultAbInvertNameBySourceCookie: "ab_invert_name_by_source",
  resultAbPreselecionStorage: "abc_pre_selection",
  hasOptionSelectedStorage: "HAS_OPTION_SELECTED",
  resultAbGrouppedDebtsCookie: "ab_ativos_groupped_debt",
  resultAbConcludeAgreementButton: "ab_conclude_agreement_button",
  resultAbMyDebtsPage: "ab_my_debts_page",
  //#endregion A/B tests

  //#region partner properties
  propsRiachuelo: {
    keyRiachueloNegotiationType: "RIACHUELO_NEGOTIATION_TYPE",
    valueStringOverduedOnly: "overdued_only",
    valueStringOverduedAndUpcoming: "overdued_and_upcoming",
  },

  debitAccount: "DEBITO_CONTA",
  //#endregion partner properties

   //#region REDLINE
   rlTracksAuthCookie: "rl_tracks_auth",
   rlCtrlDebtsCookieKey: "rl_ctrl_debts",
   rlCtrlPaymentCookieKey: "rl_ctrl_payment",
 
   rlCtrlDebtsCookieKeyReact: "rl_ctrl_debts_react", // temp
   //#endregion REDLINE

  auth: {
    tokenCookie: "TOKEN",
    tokenAdminCookie: "A_TOKEN",
    tokenHeader: "X-Auth-Token",
    utmHeader: "X-User-Context",
    subjectCookie: "SUBJECT",
    loginAttempts: "LOGIN_ATTEMPTS",
  },

  userHashId: "USER_HASH_ID",
  userConsult: "userConsult",
  blockAds: "blockAds",
  userChangePassword: "USER_CHANGE_PASSWORD",
  firstEnvContext: "FIRST_ENV_CONTEXT",
  credor: "CREDOR",
  directDebtPreferences: "DIRECT_DEBT_PREFS",

  debt: "DEBT",
  debts: "DEBTS",
  paymentMethod: "PAYMENT_METHOD",
  paymentOption: "PAYMENT_OPTION",

  creditCardData: "CREDIT_CARD_DATA",
  incomingOptions: "INCOMING_OPTIONS",
  incomingValue: "INCOMING_VALUE",
  startingAmount: "VALOR_ENTRADA",
  proposalParcel: "VALOR_PARCELA",
  numParcels: "NUMERO_PARCELAS",
  errorProposal: "ERRO_PROPOSTA",
  errorProposalLimit: "ERRO_PROPOSTA_LIMITE",
  errorHide: "ERRO_OCULTAR",
  agreement: "AGREEMENT",
  referralId: "REFERRALID",
  partner: "PARTNER",
  listPartners: "LIST_PARTNERS",
  closedAgreement: "CLOSED_AGREEMENT",
  complementaryInformation: "INFORMACAO_COMPLEMENTAR",
  directDebtComplementaryInformation: "INFORMACOES_DEBITO",
  hasTerm: "POSSUI_TERMO",
  partnerSystem: "PARTNER_SYSTEM",
  currentPartnerSystem: "CURRENT_PARTNER_SYSTEM",
  cessionDebtProperty: "CESSAO",
  canRemakeAgreement: "REACORDO",
  userForConsult: "USUARIO_PARA_CONSULTAR",
  validatedPhone: "VALIDATED_PHONE",
  roleAdmin: "ROLE_ADMIN",
  adminMode: "ADMIN_MODE",
  tokenUserConsult: "USERCONSULT",
  kbaScoreUuid: "UUIDKBASCORE",
  userWithoutDebt: "USERCOMPLIANT",
  userWithAds: "LOAD_ADS",
  newUser: "NEW_USER",
  abTestResponse: "GROWTHBOOK_RESPONSE",
  abTestExperimentValue: "GROWTHBOOK_EXPERIMENT",
  sessionHash: "SESSION_HASH",
  errorCreditCard: "ERRORCREDITCARD",
  implicitLogin: "IMPLICIT_LOGIN",
  debtsFound: "DEBTS_FOUND",
  debtFound: "DEBTS_FOUND",
  debtsViewed: "DEBTS_VIEWED",
  debtViewed: "DEBTS_VIEWED",
  simulationDone: "SIMULATION_DONE",
  trackedQueryParams: "TRACKED_QUERY_PARAMS",
  reminderScoreRead: "REMINDER_SCORE_READ",
  loadPartnersDebts: "LOAD_PARTNERS_DEBTS",
  noNegativePartnersDebts: "NO_NEGATIVE_PARTNERS_DEBTS",
  offerViewed: "OFFER_VIEWED",
  typeOfferShow: "TYPE_OFFER_SHOW",
  pixStatus: "PIX_STATUS",

  offline: {
    proposal: "OFFLINE_PROPOSAL",
    paymentOption: "OFFLINE_PAYMENT_OPTION",
    sentProposal: "OFFLINE_SENT_PROPOSAL",
  },

  partnersDomainRedirect: ["zema", "net", "netfatura", "clarofatura"],
  folderRedirectWebflow: ["picpay"],

  canAccessSimplicIntegratedFlow: "CAN_ACCESS_SIMPLIC_INTEGRATED_FLOW",
  canAccessNoverdeIntegratedFlow: "CAN_ACCESS_NOVERDE_INTEGRATED_FLOW",
  canAccessSupersimIntegratedFlow: "CAN_ACCESS_SUPERSIM_INTEGRATED_FLOW",

  partnerConsultDefault: {
    id: null,
    identificador: null,
    nome: null,
    show: null,
    status: null,
    externo: null,
    order: null,
    msUrl: null,
    logoUrl: null,
    tipo: null,
  },
  totemSystemPartners: ["riachuelototem"],

  CampaignPartners: [
    "pernambucanas",
    "santander",
    "credz",
    "anhanguera",
    "fama",
    "pitagoras",
    "unic",
    "unopar",
    "unime",
    "uniderp",
  ],

  paramsButtonSharedWhatsapp: new SharerParams(
    "button btn btn-icon border-shared",
    "whatsapp",
    "Você recebeu uma indicação para conhecer a Acordo Certo. Renegocie suas dívidas de forma 100% online, transparente e segura. Seu bem-estar financeiro está aqui!",
    "Recomende a Acordo Certo",
    "https://app.acordocerto.com.br/minhas-dividas?utm_source=recomendacao&utm_medium=recomendacao&utm_campaign=recomende_para_um_amigo",
    true,
    "icon-whatsapp"
  ),

  socialNetworks: [
    { nome: "facebook", href: "https://www.facebook.com/oficialacordocerto" },
    {
      nome: "youtube",
      href: "https://www.youtube.com/channel/UCCMYRKpWD4aC_t3UUz3uaPQ",
    },
    { nome: "instagram", href: "https://www.instagram.com/acordocerto/" },
    { nome: "twitter", href: "https://www.twitter.com/acordo_certo" },
    { nome: "linkedin", href: "https://www.linkedin.com/company/acordo-certo" },
  ],

  feedbackContext: new FeedbackContext(
    "PESQUISA_NOVO_CADASTRO",
    "Sua opinião vale muito para gente :)",
    "De modo geral, como você avalia o nosso processo de cadastro?",
    "Muito obrigado!",
    "Nos ajude a melhorar contando mais sobre a sua experiência. Sua opinião é muito importante :)",
    "Responder pesquisa de satisfação",
    "Pesquisa - Satisfacao novo cadastro",
    "btn_bom",
    "btn_ruim",
    "btn_abriu_pesquisa",
    "btn_fechou_card"
  ),

  contactPartner: [
    {
      partner: "bradesco",
      metropolitanas: "4002 0022",
      demaisRegioes: "0800 570 0022",
      exterior: "55 11 4002 0022",
      deficienciaAuditiva: "0800 722 0099",
      sac: "0800 704 8383",
      ouvidoria: "0800 727 9933",
      description:
        'A partir da primeira parcela, o Bradesco ficará responsável pelo seu acordo e <a href="https://banco.bradesco/html/classic/produtos-servicos/mais-produtos-servicos/segunda-via-boleto.shtm#segunda-via-boleto" target="_blank">acessando essa página você poderá obter seus boletos</a> diretamente com eles.',
    },
  ],

  paymentMehod: [
    {
      id: "BILLET",
      params: {
        id: "BILLET",
        name: "Boleto",
        icon: "ticket",
        text: '<strong class="-semi">Boleto</strong>',
        maxInstallments: 1,
        subtext: "Pague à vista ou parcelado",
      },
    },
    {
      id: "DEBIT_ACCOUNT",
      params: {
        id: "DEBIT_ACCOUNT",
        name: "Débito em conta",
        icon: "icon-account-debit",
        text: '<strong class="-semi">Débito em conta</strong>',
        maxInstallments: 1,
        subtext: "Débito em conta",
      },
    },
    {
      id: "CREDIT_CARD",
      params: {
        id: "CREDIT_CARD",
        name: "Cartão de crédito",
        icon: "credit-card",
        text: '<strong class="-semi">Cartão de Crédito</strong>',
        maxInstallments: 1,
        subtext: "Economize dinheiro parcelando no cartão!",
      },
    },
    {
      id: "PIX",
      params: {
        id: "PIX",
        name: "Pix",
        icon: "icon-pix-2",
        text: '<strong class="-semi">Pix</strong>',
        maxInstallments: 1,
        subtext: "Pague à vista ou parcelado",
      },
    },
  ],

  //#region A/B tests
  inFlowWithDetails: "FLOW_WITH_DETAILS",
  withDetailsEvents: "WITH_DETAILS_EVENTS",
  inExpressFlow: "EXPRESS_FLOW",
  withPix: "WITH_PIX",
  withPartial: "WITH_PARTIAL",
  optimizedOptionCookie: "ab_optimized_option",
  //#region A/B tests

  //#region termsOfUse
  termsOfUse: `<p><strong>1. INTRODUÇÃO:</strong></p>

  <p>1.1. A Acordo Certo Ltda. (“ <strong>ACORDO CERTO</strong>”), inscrita no CNPJ sob o nº 08.702.298/0001-93, com sede na Avenida Tamboré nº 267, Edifício Canopus Corporate Alphaville, 14º pavimento da Torre Sul, Conjunto 141 A, Bairro Tamboré, na cidade de Barueri, Estado de São Paulo, CEP 06460-000 é proprietária do <em>site</em> <a href="https://www.acordocerto.com.br/" target="_blank">www.acordocerto.com.br</a> (“Site”).</p>

  <p><strong>1.2. A utilização de qualquer área do Site está condicionada à leitura e concordância deste Termo de Uso (“Termo de Uso” ou “Termo”). Você deve lê-lo integralmente antes de navegar em nosso Site. O acesso a qualquer área do Site será interpretado como a aceitação deste Termo. Caso você não concorde com alguma disposição deste Termo, favor interromper o acesso ao Site imediatamente.</strong></p>

  <p>1.3. É importante esclarecer que poderemos atualizar este Termo a qualquer tempo e divulgar a sua nova versão por meio de publicação no Site. Se você continuar a usar o Site depois da publicação das alterações, entenderemos que você aceitou as novas disposições existentes no Termo de Uso, ao qual você ficará vinculado. Recomendamos que você verifique periodicamente o Site para tomar ciência do Termo de Uso mais atualizado. </p>

  <p>1.4. Nós nos esforçaremos para garantir que o Site esteja disponível para você utilizar 24 horas por dia, mas, eventualmente, poderá acontecer alguma indisponibilidade em determinado período. Nós te informaremos quando o Site estiver suspenso temporariamente, nos casos de falha do sistema, manutenção, reparo ou qualquer outra causa razoável. </p>

  <p>1.5 Precisamos te informar que o uso comercial da expressão "Acordo Certo" como marca, nome empresarial ou nome de domínio, os conteúdos das telas relativas aos serviços da Acordo Certo e os programas, bancos de dados, redes e arquivos, que permitem que você acesse as telas de negociação de dívidas, são propriedade da Acordo Certo e estão protegidos pela legislação aplicável. </p>

  <p>1.6. Além disso, que o Site poderá ter <em>links</em> de outros <em>sites</em>, o que não significará que esses outros <em>sites</em> sejam de propriedade ou operados pela Acordo Certo. Nós não nos responsabilizamos pelos conteúdos, práticas, uso de informações pessoais e serviços ofertados em <em>sites</em> de terceiros. &nbsp; </p>

  <p>1.7. E que é expressamente proibido o uso indevido ou a reprodução total ou parcial do Site ou qualquer parte do seu conteúdo sem a nossa expressa permissão. </p>

  <p><strong>2. DEFINIÇÕES:</strong></p>

  <p>(i) Empresas Parceiras: são conjuntamente as Parceiras de Débito e as Parceiras de Crédito; &nbsp;</p>
  <p>(ii) Parceira de Crédito: empresa do setor financeiro que oferece serviços de crédito aos Usuários; </p>
  <p>(iii) Parceira de Débito: empresa que é credora da dívida; </p>
  <p>(iv) Usuário: pessoa que se cadastrada no Site; </p>
  <p>(v) Visitante: pessoa que não é cadastrada no Site; </p>
  <p>(vi) Site: <a href="https://www.acordocerto.com.br/" target="_blank">www.acordocerto.com.br</a> e demais páginas deste domínio. </p>

  <p><strong>3. OBJETIVO E SERVIÇOS PRESTADOS:</strong></p>

  <p>3.1. O Site é uma plataforma que tem como objetivo possibilitar que você possa: (i) negociar a sua dívida com algumas das empresas que são nossas parceiras (“Parceira de Débito”) de modo simples e ágil; ou (ii) obter crédito com empresas que são nossas parceiras (“Parceiras de Crédito”) (em conjunto “Empresas Parceiras”) (“Serviços”). </p>

  <p>3.2. Você deverá realizar seu cadastro no Site para utilizar o Serviço. Neste momento, você deverá aceitar expressamente as condições deste Termo e da Política de Privacidade e informar alguns dados pessoais para garantir que é você mesmo quem está acessando as informações fornecidas no Site. &nbsp;</p>

  <p>3.3. Para a proteção de informações existentes no Site, poderemos negar o acesso a sua área restrita caso não seja possível realizar a confirmação das informações fornecidas. </p>

  <p>3.4. Uma vez realizado seu cadastro, você poderá: (i) visualizar os débitos existentes nos Parceiras de Débito e optar pela negociação que melhor atenda seus interesses, dentre as opções disponíveis no Site e que forem fornecidas pelas próprias Parceiras de Débito e/ou (ii) pleitear a obtenção de crédito perante Parceiras de Crédito. </p>

  <p><strong>(I) SERVIÇO DE RECUPERAÇÃO DE CRÉDITO:</strong></p>

  <p>3.5. Você poderá escolher a Parceira de Débito com o qual deseja negociar para obter mais informações sobre a dívida, forma de negociação e opção de pagamento. </p>

  <p>3.6. Ao realizar o acordo com a Parceira de Débito, você poderá pagar o débito por boleto ou por outros meios de pagamento disponibilizados pela Parceira de Débito. Se você escolher pagar por meio de boleto, poderá, a critério da Parceira de Débito, emiti-lo diretamente no Site e recebê-lo no seu <em>e-mail</em> cadastrado. </p>

  <p>3.7. Eventualmente, poderemos cobrar uma taxa pelo serviço de recuperação de crédito, a qual lhe será informada previamente à realização do acordo. <strong>ORIENTAMOS SEMPRE CONFERIR OS DADOS DO BENEFICIÁRIO ANTES DE EFETUAR A TRANSAÇÃO DE PAGAMENTO</strong>. </p>

  <p>3.8. No caso de pagamentos parcelados, os boletos serão disponibilizados de acordo com a política interna da Parceira de Débito. Ao aceitar os termos do acordo, você deverá acessar o Site ou entrar em contato direto com a Parceira de Débito para obter os boletos de pagamento. Caso o Usuário não receba os boletos por problemas de acesso no Site, você dever entrar em contato conosco para a solução do problema. </p>

  <p>3.9. É nosso dever te informar que a formalização do acordo não será considerada quitação da dívida e que a Parceira de Débito é a única responsável pela baixa dos registros de débito existentes nas empresas de proteção ao crédito, caso a Parceira tenha negativado o seu nome naquelas empresas. </p>

  <p>3.10. Você está ciente de que os juros, multas e outros acréscimos serão calculados pela Parceira de Débito, de acordo com as políticas de descontos dela, portanto, que as condições para pagamento da dívida deverão ser apresentadas pela própria Parceira de Débito e que não nos caberá interferir nessas condições para a quitação dos débitos e formalização de acordos. </p>

  <p>3.11. Você está ciente de que não somos responsáveis pelas informações e pelas dívidas fornecidas diretamente pela Parceira de Débito e que a intermediação é realizada com base nas informações fornecidas pela própria Parceira de Débito, conforme disposições contratuais específicas existentes no contrato que formalizamos com esta. </p>

  <p>3.12. Informamos que é responsabilidade da Parceira de Débito assegurar a veracidade das informações que nos fornecer. Não nos responsabilizamos pela veracidade, exatidão ou data de atualização das dívidas/ informações fornecidas pela Parceira de Débito. Não inserimos, alteramos, excluímos ou bloqueamos qualquer informação do banco de dados da Parceira de Débito com relação à dívida. Somos apenas um intermediador da informação. &nbsp;</p>

  <p>3.13. Você está ciente de que a inexistência de informações acerca de débitos no Site não significará que você não tenha dívida com a Parceira de Débito, pois a base de dados dela no Site pode estar desatualizada no momento de sua consulta ou ela pode não ter nos fornecido esses dados. Você poderá contatar a Parceira de Débito diretamente para providenciar a correção dos dados. </p>

  <p><strong>3.14. Recomendamos que a negociação e celebração do acordo com as Parceiras seja realizada com cautela e bom senso e que você analise a sua condição financeira e capacidade de cumprir o acordo. É importante informar que não será possível cancelar o acordo realizado no Site. Nesse caso, solicitamos que você procure diretamente a Parceira de Débito.</strong></p>

  <p><strong>II) SERVIÇO DE CRÉDITO:</strong></p>

  <p>3.15. Intermediamos a simulação, cotação e contratação de crédito das Parceiras de Crédito. Realizado cadastro específico, nosso sistema cria automaticamente um perfil de crédito do Usuário com base nas informações disponibilizadas em seu cadastro e apresenta as opções de Parceiras de Crédito que possam se adequar à sua necessidade. Você selecionará a Parceira de Crédito de seu interesse e será redirecionado para o <em>site</em> desta Parceira, onde deverá ler suas respectivas políticas e termos de uso. </p>

  <p>3.16. Você poderá receber respostas das Parceiras de Crédito para a obtenção de crédito, mas não garantimos o sucesso na aprovação ou na contratação do empréstimo. A obtenção do crédito depende da avaliação de suas informações pela Parceira de Crédito, de acordo com as políticas internas delas próprias. </p>

  <p>3.17. Não somos responsáveis pelos termos da contratação realizada entre você e as Parceiras de Crédito. Como apenas intermediamos o seu contato com as Parceiras de Crédito, o acordo realizado entre as partes não nos envolve. </p>

  <p><strong>4. AVISOS GERAIS:</strong></p>

  <p>4.1. Não nos responsabilizamos por quaisquer danos e/ou prejuízos que você eventualmente possa sofrer com relação às negociações que realizar com as Empresas Parceiras, exceto se agirmos por dolo ou culpa exclusiva e comprovados na prestação dos nossos Serviços. </p>

  <p>4.2. Não nos responsabilizamos por qualquer problema técnico relacionado a <em>malwares</em> que possa afetar o seu equipamento em decorrência do seu acesso, utilização ou navegação no Site ou como consequência da transferência de dados. </p>

  <p>4.3. É importante informar que o Site, eventualmente, poderá não estar disponível para a sua utilização por motivos técnicos, falhas na <em>internet</em> ou por qualquer outro evento fortuito ou de força maior, alheio ao nosso controle. </p>

  <p>4.4. Por caso fortuito ou força maior, entendemos ser as circunstâncias imprevisíveis e inevitáveis que impedem, total ou parcialmente, a execução dos Serviços. </p>

  <p>4.5. Nós nos resguardamos o direito de, a qualquer momento, advertir, suspender ou cancelar, em caráter temporário ou permanente, o seu acesso ao Site e tomar as medidas legais cabíveis, caso alguma das seguintes situações ocorra: </p>

  <p>1. o desrespeito aos dispositivos neste Termo de Uso e/ou na Política de Privacidade; </p>
  <p> 2. a prática de atos fraudulentos ou ilegais; </p>
  <p> 3. a impossibilidade de verificação de sua identidade ou a constatação do fornecimento de informação inexata, incorreta ou incompleta; e/ou </p>
  <p> 4. o não cumprimento do acordo firmado no Site. &nbsp;</p>

  <p>4.6. Você deve garantir a veracidade, exatidão e atualização dos dados que fornecer no Site e nos eximir de qualquer responsabilidade por inexatidão ou inclusão de dados inverídicos que possam induzir a erro a Acordo Certo, as Parceiras ou terceiros. </p>

  <p>4.7. Se você fornecer informações falsas, incorretas, desatualizadas ou caso tenhamos razões para suspeitar que as informações fornecidas por você no Site sejam falsas, incorretas, desatualizadas ou incompletas, poderemos suspender ou cancelar o seu cadastro imediatamente e independente de notificação, o que te impedirá de utilizar, presente ou futuramente, os Serviços do Site que requererem cadastro específico. </p>

  <p>4.8. Você é o único responsável pelo sigilo de sua senha de acesso ao Site, a qual é de uso pessoal, exclusivo e intransferível. Assim, não é permitido divulgar, transferir, ceder ou repassar a terceiros, a título oneroso ou gratuito, seu acesso ao Site. Você será integralmente responsável perante a Acordo Certo e terceiros por qualquer reclamação relacionada ao uso indevido das informações obtidas no Site e, quando cabível, poderemos tomar as medidas judiciais e comunicar as autoridades competentes do ocorrido. Nesse caso, você deverá indenizar a Acordo Certo, suas filiais, empresas controladas e controladoras, diretores, administradores, sócios, colaboradores, representantes e empregados, incluindo honorários advocatícios, por quaisquer demandas promovidas por terceiros e que sejam resultantes de práticas indevidas decorrentes de suas atividades no Site, por seu descumprimento deste Termo de Uso ou pela violação da legislação aplicável. </p>

  <p><strong>5. COMUNICAÇÃO PARA FINS DE PUBLICIDADE:</strong></p>

  <p>5.1. Informamos que poderemos lhe enviar ofertas, promoções ou qualquer outro tipo de publicidade relacionada ao Serviço para lhe proporcionar a melhor experiência possível conosco. Esta comunicação poderá ser realizada por meio de <em>e-mail</em>, SMS ou outros meios semelhantes. </p>

  <p>5.2. Você poderá cancelar o recebimento de conteúdo publicitário a qualquer momento, seja por meio do nosso site <a href="https://app.acordocerto.com.br/fale-conosco" target="_blank">www.acordocerto.com.br/fale-conosco</a> selecionando a opção “Deixar de Receber SMS ou E-mail” ou seguindo as instruções de cancelamento descritas nas próprias mensagens. &nbsp; </p>

  <p>5.3. Ao aceitar receber o nosso boletim <em></em>informativo ( <em>newsletters</em>), você concorda que o seu endereço de <em>e-mail</em> seja utilizado para fins de publicidade até o cancelamento da assinatura. Não utilizamos <em>spam</em> para divulgar os nossos serviços. </p>

  <p><strong>6. DISPOSITIVOS GERAIS:</strong></p>

  <p>6.1. Eventual tolerância por descumprimento de quaisquer condições deste Termos de Uso não constituirá novação das obrigações aqui estipuladas e não impedirá ou inibirá sua exigibilidade a qualquer tempo. Poderemos optar por não exercer determinados direitos e faculdades conferidos por este Termo, o que não implicará a renúncia de nossos direitos e faculdades. </p>

  <p>6.2. Caso alguma disposição deste Termo de Uso seja considerada ilegítima por autoridade local, as demais condições permanecerão em pleno vigor e efeito até segunda ordem. Caso seja declarada a nulidade de qualquer cláusula deste Termo de Uso, as demais cláusulas permanecerão vigentes. </p>

  <p>6.3. Ao acessar o Site, você aceita a nossa Política de Cookies. Você poderá, a qualquer momento, desativar parte ou a totalidade dos nossos <em>cookies</em>, exceto aqueles essenciais ao funcionamento do Site. Para configurar o uso de <em>cookies</em>, você dever ir ao menu “opções” ou “preferências” do seu <em>browser</em>. </p>

  <p>6.4. Importante informar que ao desativar os <em>cookies</em>, isso poderá afetar, parcial ou totalmente, a navegação no Site. </p>

  <p>6.5. Informamos que coletamos e armazenamos seu Internet Protocol - IP para fins de rastreabilidade dos acessos. </p>

  <p>6.6. Nosso Site é protegido com o maior grau de zelo e com a melhores práticas de mercado. Eventuais tentativas de invasão serão levadas para a autoridade competente investigá-las e aplicar a legislação disponível sobre o assunto. </p>

  <p>6.7. Este Termo de Uso é regido pela legislação vigente na República Federativa do Brasil. Eventuais divergências envolvendo as disposições deste Termo serão resolvidas pelo Foro Central da Capital de São Paulo.</p>

  <p>Publicado em 24 de novembro de 2022.</p>`,
  //#endregion termsOfUse

  termsOfUseCurrentVersion: 4,

  termsOfUseLastUpdate: "24/11/2022",

  //#region privacyPolicy
  privacyPolicy: `<p><strong><u>1. INTRODUÇÃO</u></strong><br />

  <p>1.1. A Acordo Certo Ltda. (“<strong>ACORDO CERTO</strong>”), inscrita no CNPJ sob o nº 08.702.298/0001-93, com sede na Avenida Tamboré nº 267, Edifício Canopus Corporate Alphaville, 14º pavimento da Torre Sul, Conjunto 141-A, Bairro Tamboré, na cidade de Barueri, Estado de São Paulo, CEP 06460-000 é proprietária do <em>site</em> <a href="www.acordocerto.com.br">www.acordocerto.com.br</a> e demais páginas deste domínio (“Site”).</p>

  <p><strong>1.2. A utilização de qualquer área do Site está condicionada à leitura e concordância desta Política de Privacidade (“Política”). Você deverá lê-la integralmente antes de navegar em nosso Site. O acesso a qualquer área do Site será interpretado como a aceitação desta Política de Privacidade. Caso você não concorde com alguma disposição desta Política, favor interromper o acesso ao Site imediatamente.</strong></p>

  <p>1.3. É importante esclarecer que poderemos atualizar esta Política a qualquer tempo e divulgar a sua nova versão por meio da publicação no Site. Se você continuar utilizando o Site depois da publicação das alterações, entenderemos que você aceitou as novas disposições da Política de Privacidade integralmente, à qual você ficará vinculado. Recomendamos que você verifique periodicamente o Site para tomar ciência da Política de Privacidade mais atualizada.</p>

  <p>1.4. O Site é uma plataforma que tem como objetivo possibilitar que você possa utilizar nossos serviços (“Serviços”) que são: (i) negociar a sua dívida com algumas das empresas que seja nossas parceiras (“Parceiras de Débitos”) e/ou (ii) obter crédito com empresas parcerias (“Parceiras de Crédito”)”.</p>

  <p><strong><u>2. DEFINIÇÕES:</u></strong></p>

  <p>(i)	<u>Banco de dados</u>: conjunto estruturado de dados, estabelecido em um ou em vários locais, em suporte eletrônico ou físico; </p>

  <p>(ii)	<u>Browser</u>: software que possibilita a navegação na internet;</p>

  <p>(iii)	<u>Cookies</u>: pequeno arquivo de computador ou pacote de dados enviados por um site de Internet para o navegador do usuário quando este visitar o <em>site;</em></p>

  <p>(iv)	<u>Consentimento</u>: manifestação livre, informada e inequívoca pela qual o titular concorda com o tratamento de seus dados pessoais para uma finalidade determinada;</p>

  <p>(v)	<u>Controlador</u>: pessoa natural ou jurídica, de direito público ou privado, a quem compete as decisões referentes ao tratamento de dados pessoais; </p>

  <p>(vi)	<u>Dado pessoal</u>: informação relacionada a pessoa natural identificada ou identificável;</p>

  <p>(vii)	<u>Dado pessoal sensível</u>: dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural;</p>

  <p>(viii)	<u>Empresas parceiras</u>: são conjuntamente as Parceiras de Débitos e as Parceiras de Crédito;</p>

  <p>(ix)	<u>Lei Geral de Proteção de Dados Pessoais</u>: Lei nº 13.709/2018, incluindo suas alterações posteriores (“LGPD”);</p>

  <p>(x)	<u>Operador</u>: pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador;</p>

  <p>(xi)	<u>Parceira de Crédito</u>: empresa do setor financeiro que oferece serviços de crédito aos Usuários;</p>

  <p>(xii)	<u>Parceira de Débito</u>: empresa que é credora da dívida;</p>

  <p>(xiii)	<u>Serviços</u>: intermediação de negociação de dívidas entre os Usuários e as Parceiras de Débito ou de obtenção de crédito pelos Usuários com as Parceiras de Crédito;</p>

  <p>(xiv)	<u>Titular dos dados</u>: pessoa natural a quem se referem os dados pessoais que são objeto de tratamento;</p>

  <p>(xv)	<u>Tratamento de dados</u>: toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração;</p>

  <p>(xvi)	<u>Usuário</u>: pessoa que se cadastrada no Site;</p>

  <p>(xvii)	<u>Visitante</u>: pessoa que não é cadastrada no Site;</p>

  <p>(xviii)	<u>Site</u>: <a href="www.acordocerto.com.br">www.acordocerto.com.br</a> e demais páginas deste domínio.</p>

  <p><strong><u>3. CONSENTIMENTOS GERAIS DOS USUÁRIOS:</u></strong></p>

  <p>3.1. Você declara que está ciente e de acordo que:</p>

  <p>(i)	a finalidade de uso do Site é exclusiva para utilização dos Serviços descritos nesta Política;</p>

  <p>(ii)	é o titular das informações que fornecer à Acordo Certo e, assim, responsável por sua veracidade, exatidão e atualização;</p>

  <p>(iii)	que seus dados pessoais, fornecidos por meio do cadastro do Site e aqueles que forem fornecidos à Acordo Certo pelas Empresas Parceiras, sejam tratados conforme descrito nesta Política;</p>

  <p>(iv)	isentará a Acordo Certo de qualquer responsabilidade por inexatidão dos dados que lhe fornecer, por omissão de informação e/ou por inserção de dados falsos no Site. Se qualquer informação fornecida for falsa, incorreta, desatualizada ou incompleta, ou caso tenhamos razões para suspeitar que as informações fornecidas por você sejam falsas, incorretas, desatualizadas ou incompletas, poderemos suspender ou cancelar a sua conta de cadastro imediatamente, independente de notificação, e recusar toda e qualquer utilização, presente ou futuramente, dos Serviços que requerem cadastro específico;</p>

  <p>(v)	os acordos firmados por meio do Site não interferirão ou descaracterizarão as relações jurídicas previamente estabelecidas entre você e as Empresas Parceiras;</p>

  <p>(vi)	cumprirá o acordo que celebrar com a Parceira de Débito, sob pena de, em caso de inadimplência, ficar sujeito ao cancelamento do acordo e às medidas judiciais e extrajudiciais que a Parceira de Débito entender cabíveis;</p>

  <p>(vii)	a Acordo Certo é uma empresa que presta o serviço de intermediação e, por isso, não garante o êxito na negociação entre você e as Empresas Parceiras;</p>

  <p>(viii)	o <em>e-mail</em> e telefone celular que você cadastrar no Site serão os principais canais de comunicação com a Acordo Certo e por meio dos quais poderemos enviar informações sobre seus débitos, campanhas de desconto, avisos de vencimento de parcelas do acordo formalizado, entre outras mensagens de natureza similar;</p>

  <p>(viii.1)	toda informação que for enviada do <em>e-mail</em> ou telefone celular que você cadastrar no Site, entenderemos como uma mensagem enviada por você e que você se responsabiliza pela integridade da mensagem, bem como por seus efeitos;</p>

  <p>(ix)	a Acordo Certo se reserva o direito de utilizar dos meios juridicamente permitidos para lhe identificar apropriadamente e, durante este processo, poderá lhe solicitar dados e documentos adicionais para confirmar as informações fornecidas anteriormente. Os dados pessoais que poderão ser coletados durante este procedimento estão detalhados no item 6.3.1 desta Política;</p>

  <p>(x)	a Acordo Certo não se responsabiliza pelas obrigações tributárias relacionadas ao pagamento dos débitos do Usuário e/ou dos créditos concedidos aos Usuários. Qualquer responsabilidade tributária é exclusividade do Usuário;</p>

  <p>(xi)	o login e senha cadastrados por você para acessar os nossos Serviços são para seu pessoal, intransferível e exclusivo uso. Você concorda que é o único responsável pela guarda e por qualquer uso indevido em seu login e senha;</p>

  <p>(xii)	não é permitido divulgar, transferir, ceder ou repassar a terceiros, a título oneroso ou gratuito, seu acesso ao site. Você será integralmente responsável perante a Acordo Certo e terceiros por qualquer reclamação relacionada ao uso indevido das informações obtidas no Site. E, quando cabível, poderemos tomar as medidas judiciais e comunicar as autoridades competentes do ocorrido.;</p>

  <p>(xiii)	você indenizará a Acordo Certo, suas filiais, empresas controladas e controladoras, diretores, administradores, sócios, colaboradores, representantes e empregados, incluindo honorários advocatícios, por quaisquer demandas promovidas por terceiros e que sejam resultantes de práticas indevidas decorrentes de suas atividades no Site, por seu descumprimento desta Política ou pela violação da legislação aplicável.</p>

  <p><strong><u>4. PROTEÇÃO DE DADOS PESSOAIS:</u></strong></p>

  <p>4.1. Você tem ciência que somos controladores dos dados pessoais fornecidos no Site para a prestação dos Serviços, operadora do Site quanto às propostas fornecidas pelas Empresas Parceiras e que podemos contratar empresas terceiras que podem nos auxiliar na execução dos Serviços, as quais atuam como operadoras dos dados pessoais.</p>

  <p>4.2. Caso você tenha alguma dúvida relacionada à proteção de seus dados pessoais, pedimos a gentileza que entre em contato com o nosso encarregado Dr. Glauco Alves Costa da Silva pelo seguinte endereço de <em>e-mail:</em> dpo.acordocerto@boavistascpc.com.br.</p>

  <p><strong><u>5. DE QUE FORMA A ACORDO CERTO COLETA DADOS PESSOAIS?</u></strong></p>

  <p>5.1. Poderemos coletar os seus dados pessoais das seguintes formas:</p>

  <p>(i)	pelo recebimento dos dados fornecidos por empresas terceiras e pelas Parceiras;</p>

  <p>(ii) pelo fornecimento no momento do seu cadastro;</p>

  <p>(iii)	pelo uso de serviços de terceiros que disponibilizam ferramentas de confirmação de dados por meio de buscas de informações em fontes públicas;</p>

  <p>(iv)	pelo uso de ferramenta que registra em vídeo os movimentos do mouse dos Visitantes e dos Usuários assim que acessam o Site;</p>

  <p>(v) por registro de cookies; e/ou</p>

  <p>(vi)	pela gravação das ligações telefônicas e do histórico dos chats mantidos com a Acordo Certo.</p>

  <p>5.2. A única forma de coleta dos dados pessoais de Visitantes do Site é por meio de cookies e do registro em vídeo dos movimentos de seu <em>mouse</em> no momento de acesso ao Site, caso dados pessoais sejam fornecidos na tela. Apesar de os dados pessoais poderem ser coletados por esse tipo de ferramenta, nós utilizamos as informações apenas para o estudo e melhoria da acessibilidade ao Site.</p>

  <p>5.3. A coleta de dados pessoais pela Acordo Certo é limitada ao mínimo necessário para a prestação dos Serviços.</p>

  <p><strong><u>6. PARA QUAL FINALIDADE A ACORDO CERTO TRATA OS DADOS PESSOAIS DOS USUÁRIOS?</u></strong></p>

  <p>6.1. Realizamos o tratamento de seus dados pessoais para as seguintes finalidades:</p>

  <p>(i) identificar os Usuários e seus débitos perante as Parceiras de Débitos;</p>

  <p>(ii) contatar os Usuários na condução dos Serviços;</p>

  <p>(iii) para responder às dúvidas e solicitações do Usuário;</p>

  <p>(iv) confirmar a titularidade dos dados para legitimar os acordos;</p>

  <p>(v) comunicar os Usuários a respeito de novos produtos e serviços, promoções, notícias e pesquisas da Acordo Certo, das empresas do mesmo grupo econômico ao qual a Acordo Certo pertence e/ou de seus clientes e parceiros;</p>

  <p>(vi) identificar o perfil de consumo e de crédito do Usuário para qualificar ofertas de <em>marketing</em> da Acordo Certo, das empresas do mesmo grupo econômico ao qual a Acordo Certo pertence e/ou de seus clientes e parceiros, e envio de <em>marketing</em> qualificado da Acordo Certo, empresas do mesmo grupo econômico ao qual a Acordo Certo pertence e/ou de seus clientes e parceiros, e de seus respectivos grupos econômicos e sucessores, podendo utilizar os dados de forma apartada e/ou combinada com informações obtidas junto a clientes, parceiros e/ou fornecedores que tenham obtido dados em observância à legislação aplicável, notadamente à LGPD e suas alterações posteriores;</p>

  <p>(vii) para fins estatísticos e análise de audiência no Site para a Acordo Certo e para as empresas do mesmo grupo econômico ao qual a Acordo Certo pertence;</p>

  <p>(viii) nos produtos e serviços da Acordo Certo e das empresas do mesmo grupo econômico ao qual a Acordo Certo pertence com a finalidade de subsidiar o ciclo de crédito, proteção do crédito, análise de risco de crédito, prevenção à fraude, cálculo de <em>score</em>, cálculo de variáveis analíticas e outros correlatos;</p>

  <p>(ix) atualizar os seus dados cadastrais na Acordo Certo e nas empresas do mesmo grupo econômico ao qual a Acordo Certo pertence, podendo, a Acordo Certo, a seu critério, também eventualmente compartilhar os dados com seus clientes e parceiros;</p>

  <p>(x) oferecer os Serviços e identificar clientes em potencial;</p>

  <p>(xi) realizar pesquisas internas de satisfação;</p>

  <p>(xii) monitorar o desempenho, acessibilidade e o tráfego do Usuário no Site e aprimorar experiência de uso deles;</p>

  <p>(xiii) intermediar a contratação de serviços de crédito entre os Usuários e as Parceiras de Crédito;</p>

  <p>(xiv) aprimorar os seus Serviços e das nas empresas do mesmo grupo econômico ao qual a Acordo Certo pertence por meio de análise estatística.</p>

  <p>6.2. A foto coletada em processos de validação, quando solicitada, será mantida em base segura e utilizada para a finalidade de realizarmos a sua autenticação e, também, poderá ser utilizada nos produtos e serviços de validação cadastral e prevenção e combate à fraude que empresas do mesmo grupo econômico ao qual a Acordo Certo comercializam para clientes e parceiros delas no formato de validação positiva do consumidor.</p>

  <p>6.3. Para aumentar as chances de conseguir boas condições de crédito, você poderá indicar pessoas naturais que poderão fornecer referências positivas sobre seu histórico como consumidor inadimplente.</p>

  <p>6.3.1. As referências serão fornecidas por você mediante o envio de dados pessoais da pessoa natural indicado como nome, <em>e-mail</em>, perfil de redes sociais e telefone. Ao fornecer estas informações, você declara ter a autorização destas pessoas naturais para o tratamento desses dados pessoais pela Acordo Certo e pelas Parceiras de Crédito. Os dados pessoais coletados serão armazenados pela Acordo Certo e utilizados para contatar estas pessoas como parte do processo de avaliação de crédito, confirmação cadastral ou contratação do crédito solicitado.</p>

  <p><strong><u>7. COM QUEM A ACORDO CERTO PODE COMPARTILHAR OS DADOS PESSOAIS E POR QUÊ?</u></strong></p>

  <p>7.1. Compartilhamos os seus dados pessoais com empresas do mesmo grupo econômico que a Acordo Certo pertence, com as empresas que contratamos como operadoras ou, eventualmente, com as Empresas Parceiras para fins de execução, monitoramento e melhoria de serviços.</p>

  <p>7.1.1. As empresas do mesmo grupo econômico da Acordo Certo, as operadoras e as Parceiras estão sujeitas à nossa Política de Segurança da Informação, aos termos contratuais específicos e às responsabilidades legais cabíveis.</p>

  <p>7.2. As empresas do mesmo grupo econômico ao qual a Acordo Certo pertence, as operadoras e as Parceiras possuem termos de uso e políticas de privacidade específicas com relação à proteção de dados pessoais, de forma que você poderá consultá-las para ter mais informações sobre o tratamento de dados pessoais realizados por essas empresas.</p>

  <p>7.3. Informamos que também haverá situações em que seremos autorizados a compartilhar os dados pessoais com terceiros, sendo elas:</p>

  <p>(i)	no caso de venda, fusão, cisão ou incorporação da Acordo Certo, de um negócio dela ou de um ativo;</p>

  <p>(ii)	por obrigação legal ou regulatória; e/ou</p>

  <p>(iii)	por suspeita de conduta potencialmente criminal ou fraudulenta do Usuário, do Visitante ou de um terceiro.</p>

  <p><strong><u>8. A ACORDO CERTO REALIZA A TRANSFERÊNCIA INTERNACIONAL DE DADOS PESSOAIS?</u></strong></p>

  <p>8.1. Poderemos realizar transferência internacional de dados pessoais por meio da contratação de empresas de <em>cloud computing</em> que estejam localizadas no exterior ou no Brasil, mas com o serviço de armazenamento no exterior. Em ambas as situações, estas empresas atuarão como operadoras dos dados pessoais e nos responsabilizaremos pela segurança dos dados pessoais que estarão sob a custódia delas. Garantimos que todos os países para os quais transferimos dados pessoais, possuem legislações com grau de proteção similares à LGPD. Também estabelecemos cláusulas de confidencialidade em nossos contratos junto às empresas que provem o serviço de armazenamento.</p>

  <p><strong><u>9. O QUE ACONTECE QUANDO A ACORDO CERTO TERMINAR DE TRATAR SEUS DADOS PESSOAIS?</u></strong></p>

  <p>9.1. Após o término do tratamento, manteremos os dados pessoais arquivados pelo período exigido pela legislação aplicável para cumprimento de dever legal, para controles internos, auditoria e exercício de direito de defesa em eventual demanda judicial e administrativa ou nas hipóteses permitidas pela LGPD. Tomaremos todas as medidas de segurança de informação necessárias para garantir a proteção destes dados.</p>

  <p><strong><u>10. A ACORDO CERTO TRATA DADOS PESSOAIS SENSÍVEIS?</u></strong></p>

  <p>10.1. Coletamos e tratamos os dados pessoais sensíveis nos casos mencionados no item 6.2 desta Política e para subsidiar a obtenção de crédito com as Parceiras de Crédito. Desta forma, você não deve nos fornecer qualquer tipo de informação desta natureza se não for nessas hipóteses. Caso você forneça dados pessoais sensíveis por engano, interpretaremos que você nos autorizou expressamente a tratá-los com objetivo de remoção ou destruição.</p>

  <p><strong><u>11. A ACORDO CERTO TRATA DADOS PESSOAIS DE MENORES DE 18 ANOS?</u></strong></p>

  <p>11.1. Não coletamos informações de menores de 16 (dezesseis) anos e solicitamos que você não nos forneça esse tipo de dado. Caso estes dados sejam fornecidos por engano, interpretaremos que o titular dos dados nos autorizou expressamente a tratá-los com o único objetivo de remoção e destruição.</p>

  <p>11.2. Poderemos coletar dados pessoais de pessoas naturais maiores de 16 (dezesseis) e menores de 18 (dezoito) anos em casos específicos e desde que os responsáveis desses titulares concordem com o tratamento ou caso estas pessoas naturais maiores de 16 (dezesseis) e menores de 18 (dezoito) anos atenderem a um dos requisitos da lei em que há a cessação da menoridade (artigo 5º, parágrafo único do Código Civil).</p>

  <p><strong><u>12. QUAIS SÃO SEUS DIREITOS COMO TITULAR DOS DADOS PESSOAIS?</u></strong></p>

  <p>12.1. Listamos alguns dos direitos que você tem de acordo com as disposições da LGPD:</p>

  <p>(i)	requerer a confirmação de quais são os seus dados pessoais que coletamos e tratamos;</p>

  <p>(ii)	requisitar, a qualquer momento, uma cópia dos dados pessoais de sua titularidade sob a nossa custódia;</p>

  <p>(iii)	solicitar a correção de seus dados pessoais. Para efetivar a correção, pode ser que precisemos checar a veracidade e titularidade dos dados pessoais fornecidos;</p>

  <p>(iv)	requerer a suspensão temporária do tratamento de seus dados pessoais fornecidos por meio de consentimento e solicitar sua exclusão de nossa base de dados quando entender que são desnecessários ou excessivos;</p>

  <p>(v)	requerer a exclusão de seus dados pessoais fornecidos por meio de consentimento;</p>

  <p>(vi)	revogar seu consentimento fornecido à Acordo Certo, o que poderá resultar na impossibilidade do uso dos Serviços;</p>

  <p>(vii)	solicitar a revisão das decisões tomadas unicamente com base em tratamento automatizado de seus dados pessoais. Este tipo de tratamento ocorre na avaliação automática do perfil de crédito do Usuário que utiliza os Serviços de crédito.</p>

  <p>12.2. Você poderá ter livre acesso a seus dados pessoais, pedir a sua atualização, retificação, modificação, exclusão e/ou revogação dos consentimentos que nos foram fornecidos. Para isto, solicitamos que entre em contato com o encarregado pela proteção de dados pessoais da Acordo Certo pelo <em>e-mail</em> dpo.acordocerto@boavistascpc.com.br. Neste e-mail, você deverá enviar uma cópia simples do RG com CPF ou CNH ou RNE e uma foto do rosto (selfie) e, nesta selfie, você deverá estar com os seus documentos pessoais em mãos, visíveis. Responderemos às solicitações dentro do período disposto na LGPD. </p>

  <p><strong><u>13. COOKIES:</u></strong></p>

  <p>13.1. Durante sua navegação, os <em>cookies</em> serão gravados no seu dispositivo com objetivo de personalizar produtos, serviços e ofertas, melhorando sua experiência, além de permitir a visualização e negociação de suas dívidas e demais serviços oferecidos.</p>

  <p>13.2. Você poderá, a qualquer momento, desativar parte ou a totalidade dos nossos <em>cookies</em>, exceto aqueles essenciais ao funcionamento do Site. Para configurar o uso de cookies, você dever ir ao menu “opções” ou “preferências” do seu <em>browser</em>.</p>

  <p>13.3. Importante informar que ao desativar os <em>cookies</em>, isso poderá afetar, parcial ou totalmente, a navegação no Site.</p>

  <p>13.4. Informamos que coletamos e armazenamos seu Internet Protocol - IP para cumprimento de disposições legais para fins de rastreabilidade dos acessos. </p>

  <p>13.5. Os terceiros ou Empresas Parceiras não são autorizados a coletar, utilizar ou tratar dados pessoais obtidos por meio de <em>cookies</em>.</p>

  <p>13.6. Nosso Site é protegido com o maior grau de zelo e com as melhores práticas de mercado. Eventuais tentativas de invasão serão levadas para a autoridade competente investigá-las e aplicar a legislação disponível sobre o assunto.</p>

  <p><strong><u>14. SITES DE TERCEIROS:</u></strong></p>

  <p>14.1. O Site poderá ter em seu conteúdo <em>links</em> de outros <em>sites</em>, o que não significará que esses outros sites sejam de nossa propriedade ou por operados pela Acordo Certo. Ao clicar nestes anúncios ou <em>links</em>, você será direcionado para o site destes anunciantes e deverá observar as políticas e termos destes sites. </p>

  <p><strong><u>15. DÚVIDAS OU RECLAMAÇÕES:</u></strong></p>

  <p>15.1. Caso você tenha alguma dúvida relacionada a esta Política ou queria fazer alguma sugestão ou reclamação sobre como usamos suas informações, entre em contato com o nosso encarregado que ele fará o possível para ajudá-lo.</p>

  <p>15.2. O nosso encarregado pela proteção de seus dados pessoais pode ser contatado pelo <em>e-mail</em>: dpo.acordocerto@boavistascpc.com.br.  </p>

  <p><strong><u>16. COMO CANCELO MEU CADASTRO? COMO REVOGO O MEU CONSENTIMENTO?</u></strong></p>

  <p>16.1. Para sua segurança, não é possível acessar os nossos Serviços sem um prévio cadastro. Isto porque precisamos te identificar para que você tenha acesso às informações.</p>

  <p>16.2. Caso, porém, você quiser cancelar o seu cadastro, você deverá clicar na opção “Dúvidas” para acessar o seguinte link: “Central de Ajuda”, “Envie sua Solicitação”. Porém, se você cancelar o seu cadastro, não poderá ter acesso aos Serviços.</p>

  <p>16.3. <strong>ATENÇÃO! INFORMAÇÃO IMPORTANTE</strong>: O cancelamento de seu cadastro e a revogação de seu consentimento não significa exclusão das informações contidas no nosso banco de dados ou no banco de dados das empresas do mesmo grupo econômico ao qual a Acordo Certo pertente, que poderão ser tratados e mantidos conforme as demais bases legais permitidas na legislação aplicável. </p>

  <p>16.4. Após o cancelamento de seu cadastro, caso você queira usar os Serviços novamente, deverá fazer um novo cadastro no site <a href="www.acordocerto.com.br">www.acordocerto.com.br.</a></p>

  <p><strong><u>17. PRIVACIDADE E SEGURANÇA:</u></strong></p>

  <p>17.1. Adotamos os melhores padrões existentes de tecnologia e processos de segurança e privacidade de dados e pertencemos ao grupo da Boa Vista Serviços S.A. que possui camadas de proteção equivalentes às das instituições financeiras, além de realizar auditorias periódicas para manutenção das certificações ISO 27001 (Segurança da Informação) e ISO 9001 (Qualidade).</p>

  <p><strong><u>18. RESPONSABILIDADES:</u></strong></p>

  <p>18.1. Nós nos responsabilizamos por eventuais danos e/ou prejuízos decorrentes de falhas, vírus ou invasões ocorridas em nosso sistema, desde que sejam decorrentes de dolo ou de nossa culpa exclusivamente comprovados.</p>

  <p>18.2. Cumpre reiterarmos, por oportuno, que nos comprometemos a assegurar que os seus dados arquivados em nosso banco de dados estejam sempre seguros. Para prevenir o acesso ou divulgação não autorizada dos seus dados, implementamos procedimentos físicos, eletrônicos e administrativos adequados para assegurar e proteger os dados que coletamos.</p>

  <p><strong><u>19. DIREITOS AUTORAIS:</u></strong></p>

  <p>19.1.  Precisamos te informar que o uso comercial da expressão "Acordo Certo" como marca, nome empresarial ou nome de domínio, os conteúdos das telas relativas aos serviços da Acordo Certo e os programas, bancos de dados, redes e arquivos, que permitem que você acesse as telas de negociação de dívidas, são propriedade da Acordo Certo e estão protegidos pela legislação aplicável.</p>

  <p><strong><u>20. ALTERAÇÕES NESTA POLÍTICA:</u></strong></p>

  <p>20.1.  É importante esclarecer que poderemos atualizar esta Política a qualquer tempo e divulgar a sua nova versão por meio de publicação no Site. Se você continuar a usar o Site depois da publicação das alterações, entenderemos que você aceitou as novas disposições existentes na Política de Privacidade, ao qual você ficará vinculado. Recomendamos que você verifique periodicamente o Site para tomar ciência do Termo de Uso mais atualizado.</p>

  <p><strong><u>21. LEGISLAÇÃO APLICÁVEL E FORO:</u></strong></p>

  <p>21.1. Esta Política é regida pelas leis da República Federativa do Brasil. Eventuais divergências envolvendo as disposições deste Termo serão resolvidas pelo Foro Central da Capital de São Paulo.</p>

  <p>Publicada em 24 de março de 2022.</p>
  `,
  //#endregion privacyPolicy
};
