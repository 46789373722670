import { EnvContext } from "@app/models";

export const environment = {
  production: true,
  domain: "https://app.acordocerto.com.br",
  rootDomain: "acordocerto.com.br",
  shouldUseMarketplaceNewAPI: true,
  apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-marketplace.acordocerto.com.br",
  apiUrlV2: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-marketplace-api.acordocerto.com.br/marketplace/v1",
  sortingDebtApiUrl:
    "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-debts-api.acordocerto.com.br/sorting-debt/v1",
  apiDebtUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-has-debt-proxy.acordocerto.com.br",
  apiSimulateProxyUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-simulate-proxy.acordocerto.com.br",
  apiEngageUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://engage-api-v2.iq.com.br",
  paymentApi: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-payment-api.acordocerto.com.br",
  redlineWriteKey: "ade8a331-802d-459f-a276-56dc11900ae8",
  engageApiKey: "5c55290d-c9f0-ed11-a4e0-42f456818483",
  engageWorkspace: "acordocerto-sao-paulo",
  apiReminders: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-notifications.acordocerto.com.br",
  sortingHatUrl: "https://sh.iq.com.br",
  sortingHatKey: "i4eyjzfn9zq6l9lcgl63lhzvf2gaoW0lux8p54nlu",
  oneSignalAppId: "74e30a78-07f7-47d1-b911-5ff01f4bf9a6",
  uxLensToken: "3b3efc10-59e0-11e8-8231-2e2dc35b75b8",
  recaptchaKey: "6LcKSR4TAAAAAO2npF8P9nfN_edZkkSImlify6pb",
  indicativeKey: "4628a96e-8249-46ec-83b1-216949b99f9b",
  growthbookKey: "sdk-Xr0lYLLTP4A1t7E",
  growthbookKeyConfig: "key_e0fbf153ea482709",
  googleClientId:
    "449381345135-tghkcls9a97qr7599npovqikk8vjdsm2.apps.googleusercontent.com",
  financialDataApi: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-financialdata-api.acordocerto.com.br",
  fireboltApi: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://firebolt-api.iq.com.br",
  loansApi: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://wasntlkp1j.execute-api.sa-east-1.amazonaws.com/prd",
  cohesionSourceKey: "src_2P3lzEkniWKAIGnpdpkvatbfwRI",
  cohesionWriteKey: "wk_2P3lzGoDdZS8gPszDHkxHYUV6Aj",
 envs: [
    {
      hasPaymentUpdate: false,
      id: "marketplace",
      partnerId: "marketplace",
      domains: ["ac", "marketplace"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://api.acordocerto.com.br",
      isPartner: false,
      name: "Acordo Certo",
      friendlyName: "a Acordo Certo",
      partnerColor: "#0098FF",
      parentPartnerId: "marketplace",
      logoName: "ac",
    },

    {
      hasPaymentUpdate: false,
      id: "biva",
      partnerId: "biva",
      domains: ["psbiva"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-biva.acordocerto.com.br",
      isPartner: true,
      name: "Biva",
      friendlyName: "a Biva",
      partnerColor: "#0098FF",
      parentPartnerId: "biva",
      logoName: "biva",
      hasAds: false,
    },

    {
      hasPaymentUpdate: false,
      id: "claro",
      partnerId: "claro",
      domains: ["claro"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/claro",
      isPartner: true,
      name: "Claro",
      friendlyName: "a Claro",
      partnerColor: "#E71F2A",
      parentPartnerId: "claro",
      logoName: "claro",
      isRedirectSimulate: false,
    },

    {
      hasPaymentUpdate: false,
      id: "clarogevenue",
      partnerId: "clarogevenue",
      domains: ["clarogevenue"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/claro",
      isPartner: true,
      name: "Claro",
      friendlyName: "a Claro",
      partnerColor: "#E71F2A",
      parentPartnerId: "claro",
      logoName: "claro",
      isRedirectSimulate: false,
    },

    {
      hasPaymentUpdate: false,
      id: "colombo",
      partnerId: "colombo",
      domains: ["colombo"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-colombo.acordocerto.com.br",
      isPartner: true,
      name: "Colombo",
      friendlyName: "a Colombo",
      partnerColor: "#082D75",
      parentPartnerId: "colombo",
      logoName: "colombo",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "credigy",
      partnerId: "credigy",
      domains: ["crediativos"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-integration-crediativos.acordocerto.com.br",
      isPartner: true,
      name: "Crediativos",
      friendlyName: "a Crediativos",
      partnerColor: "#1C97C4",
      parentPartnerId: "credigy",
      logoName: "crediativos",
      hasAds: false,
      showVerifyDebtMessage: true,
    },

    {
      hasPaymentUpdate: false,
      id: "fundo-atlantico",
      partnerId: "fundo-atlantico",
      domains: ["fundo-atlantico"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-integration-crediativos.acordocerto.com.br",
      isPartner: true,
      name: "Fundo Atlântico",
      friendlyName: "a Fundo Atlântico",
      partnerColor: "#082D75",
      parentPartnerId: "credigy",
      logoName: "fundo-atlantico",
      hasAds: false,
      showVerifyDebtMessage: true,
    },

    {
      hasPaymentUpdate: false,
      id: "mgw-ativos",
      partnerId: "mgw-ativos",
      domains: ["mgw-ativos"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-integration-crediativos.acordocerto.com.br",
      isPartner: true,
      name: "MGW Ativos",
      friendlyName: "a MGW Ativos",
      partnerColor: "#1C97C4",
      parentPartnerId: "credigy",
      logoName: "mgw-ativos",
      hasAds: false,
      showVerifyDebtMessage: true,
    },

    {
      hasPaymentUpdate: false,
      id: "credz",
      partnerId: "credz",
      domains: ["credz"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-integration-credz.acordocerto.com.br",
      isPartner: true,
      name: "Credz",
      friendlyName: "a Credz",
      partnerColor: "#00D0D2",
      parentPartnerId: "credz",
      logoName: "credz",
      isRedirectSimulate: true,
      hasAds: false,
    },

    {
      hasPaymentUpdate: false,
      id: "ipanema",
      partnerId: "ipanema",
      domains: ["ipanema"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-integration-ipanema.acordocerto.com.br",
      isPartner: true,
      name: "Ipanema",
      friendlyName: "a Ipanema",
      partnerColor: "#0098FF",
      parentPartnerId: "ipanema",
      logoName: "ipanema",
      isRedirectSimulate: false,
      hasAds: true,
      showVerifyDebtMessage: true,
      pages: {
        simulation: {
          debtCard: {
            hasCurrentDebtValue: true,
          },
        },
      },
    },

    {
      hasPaymentUpdate: false,
      id: "kroton",
      partnerId: "kroton",
      domains: ["educacional", "kroton"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/kroton",
      isPartner: true,
      name: "Kroton",
      friendlyName: "a Kroton",
      partnerColor: "#0098FF",
      parentPartnerId: "kroton",
      logoName: "kroton",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "anhangueraead",
      partnerId: "anhangueraead",
      domains: ["anhangueraead"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/kroton",
      isPartner: true,
      name: "Anhanguera",
      friendlyName: "a Anhanguera",
      partnerColor: "#FF9933",
      parentPartnerId: "kroton",
      logoName: "anhanguera-ead",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "anhanguera",
      partnerId: "anhanguera",
      domains: ["anhanguera"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/kroton",
      isPartner: true,
      name: "Anhanguera",
      friendlyName: "a Anhanguera",
      partnerColor: "#FF9933",
      parentPartnerId: "kroton",
      logoName: "anhanguera",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "pitagoras",
      partnerId: "pitagoras",
      domains: ["pitagoras"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/kroton",
      isPartner: true,
      name: "Pitágoras",
      friendlyName: "a Pitágoras",
      partnerColor: "#EC7000",
      parentPartnerId: "kroton",
      logoName: "pitagoras",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "fama",
      partnerId: "fama",
      domains: ["fama"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/kroton",
      isPartner: true,
      name: "Fama",
      friendlyName: "a Fama",
      partnerColor: "#0098FF",
      parentPartnerId: "kroton",
      logoName: "fama",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "unic",
      partnerId: "unic",
      domains: ["unic"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/kroton",
      isPartner: true,
      name: "Unic",
      friendlyName: "a Unic",
      partnerColor: "#0098FF",
      parentPartnerId: "kroton",
      logoName: "unic",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "uniderp",
      partnerId: "uniderp",
      domains: ["uniderp"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/kroton",
      isPartner: true,
      name: "Uniderp",
      friendlyName: "a Uniderp",
      partnerColor: "#00C240",
      parentPartnerId: "kroton",
      logoName: "uniderp",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "unime",
      partnerId: "unime",
      domains: ["unime"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/kroton",
      isPartner: true,
      name: "Unime",
      friendlyName: "a Unime",
      partnerColor: "#EC4343",
      parentPartnerId: "kroton",
      logoName: "unime",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "unopar",
      partnerId: "unopar",
      domains: ["unopar"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/kroton",
      isPartner: true,
      name: "Unopar",
      friendlyName: "a Unopar",
      partnerColor: "#082D75",
      parentPartnerId: "kroton",
      logoName: "unopar",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "ampli",
      partnerId: "ampli",
      domains: ["ampli"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/kroton",
      isPartner: true,
      name: "Ampli",
      friendlyName: "a Ampli",
      partnerColor: "#00B6C4",
      parentPartnerId: "kroton",
      logoName: "ampli",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "platos",
      partnerId: "platos",
      domains: ["platos"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/kroton",
      isPartner: true,
      name: "Platos",
      friendlyName: "a Platos",
      partnerColor: "#02B1C0",
      parentPartnerId: "kroton",
      logoName: "platos",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "pagbank",
      partnerId: "pagbank",
      domains: ["pagbank"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-biva.acordocerto.com.br",
      isPartner: true,
      name: "PagBank",
      friendlyName: "o PagBank",
      partnerColor: "#9FCF3A",
      parentPartnerId: "biva",
      logoName: "pag-bank",
      hasAds: false,
    },

    {
      hasPaymentUpdate: false,
      id: "claroresidencial",
      partnerId: "net",
      domains: ["claroresidencial"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-net.acordocerto.com.br",
      isPartner: true,
      name: "Minha Claro Residencial",
      friendlyName: "a Minha Claro Residencial",
      partnerColor: "#E71F2A",
      parentPartnerId: "net",
      logoName: "claro",
      hasAds: false,
    },

    {
      hasPaymentUpdate: false,
      id: "portoseguro",
      partnerId: "portoseguro",
      domains: ["portoseguro"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-portoseguro.acordocerto.com.br",
      isPartner: true,
      name: "Porto Seguro",
      friendlyName: "a Porto Seguro",
      partnerColor: "#EAEAEA",
      parentPartnerId: "portoseguro",
      logoName: "porto-seguro",
      isRedirectSimulate: true,
      hasAds: false,
    },

    {
      hasPaymentUpdate: false,
      id: "riachuelo",
      partnerId: "riachuelo",
      domains: ["riachuelo"],
      apiUrl:
        "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/riachuelo",
      isPartner: true,
      name: "Riachuelo",
      friendlyName: "a Riachuelo",
      partnerColor: "#333332",
      parentPartnerId: "riachuelo",
      logoName: "riachuelo",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "riachuelo-v2",
      partnerId: "riachuelo-v2",
      domains: ["riachuelo-v2"],
      apiUrl:
        "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/riachuelo",
      isPartner: true,
      name: "Riachuelo",
      friendlyName: "a Riachuelo",
      partnerColor: "#333332",
      parentPartnerId: "riachuelo",
      logoName: "riachuelo",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "tribanco",
      partnerId: "tribanco",
      domains: ["tribanco"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-tribanco.acordocerto.com.br",
      isPartner: true,
      name: "Tricard",
      friendlyName: "a Tricard",
      partnerColor: "#082D75",
      parentPartnerId: "tribanco",
      logoName: "tribanco",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "bvfinanceira",
      partnerId: "bvfinanceira",
      domains: ["bv"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-bv.acordocerto.com.br",
      isPartner: true,
      name: "BV",
      friendlyName: "a BV",
      partnerColor: "#0098FF",
      parentPartnerId: "bvfinanceira",
      logoName: "bv-financeira",
      hasAds: false,
    },

    {
      hasPaymentUpdate: false,
      id: "dscards",
      partnerId: "dscards",
      domains: ["disantinni", "dscards"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-dscards.acordocerto.com.br",
      isPartner: true,
      name: "Di Santinni",
      friendlyName: "a Di Santinni",
      partnerColor: "#0098FF",
      parentPartnerId: "di-santinni",
      logoName: "di-santinni",
      hasAds: false,
    },

    {
      hasPaymentUpdate: true,
      id: "itau",
      partnerId: "itau",
      domains: ["itau"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-itau.acordocerto.com.br",
      isPartner: true,
      name: "Itaú",
      friendlyName: "o Itaú",
      partnerColor: "#EC7000",
      parentPartnerId: "itau",
      logoName: "itau",
      isRedirectSimulate: false,
      hasAds: false,
    },

    {
      hasPaymentUpdate: true,
      id: "itau-rdhi",
      partnerId: "itau-rdhi",
      domains: ["itau-rdhi"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/itau",
      isPartner: true,
      name: "Itaú",
      friendlyName: "o Itaú",
      partnerColor: "#EC7000",
      parentPartnerId: "itau",
      logoName: "itau",
      isRedirectSimulate: false,
      hasAds: false,
    },

    {
      hasPaymentUpdate: false,
      id: "via",
      partnerId: "via",
      domains: ["via"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-via.acordocerto.com.br",
      isPartner: true,
      name: "Via",
      friendlyName: "a Via",
      partnerColor: "#7832AF",
      parentPartnerId: "via",
      logoName: "via",
      isRedirectSimulate: true,
      hasAds: false,
    },

    {
      hasPaymentUpdate: false,
      id: "casas-bahia",
      partnerId: "casas-bahia",
      domains: ["casas-bahia"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-via.acordocerto.com.br",
      isPartner: true,
      name: "Casas Bahia",
      friendlyName: "a Casas Bahia",
      partnerColor: "#0033C6",
      parentPartnerId: "via",
      logoName: "casas-bahia",
      isRedirectSimulate: true,
      hasAds: false,
    },

    {
      hasPaymentUpdate: false,
      id: "ponto",
      partnerId: "ponto",
      domains: ["ponto"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-via.acordocerto.com.br",
      isPartner: true,
      name: "Casas Bahia",
      friendlyName: "o Ponto",
      partnerColor: "#0098FF",
      parentPartnerId: "via",
      logoName: "ponto",
      isRedirectSimulate: true,
      hasAds: false,
    },

    {
      hasPaymentUpdate: false,
      id: "itapeva",
      partnerId: "itapeva",
      domains: ["itapeva"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-integration-itapeva.acordocerto.com.br",
      isPartner: true,
      name: "Itapeva",
      friendlyName: "a Itapeva",
      partnerColor: "#9FCF3A",
      parentPartnerId: "itapeva",
      logoName: "itapeva",
      isRedirectSimulate: false,
      hasAds: true,
      showVerifyDebtMessage: true,
    },

    {
      hasPaymentUpdate: false,
      id: "recovery",
      partnerId: "recovery",
      domains: ["recovery"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/recovery",
      isPartner: true,
      name: "Recovery",
      friendlyName: "a Recovery",
      partnerColor: "#082D75",
      parentPartnerId: "recovery",
      logoName: "recovery",
      isRedirectSimulate: false,
      hasAds: true,
      showVerifyDebtMessage: true,
      pages: {
        simulation: { debtCard: { title: "Dívida sob gestão da Recovery" } },
        paymentMethods: { tag: "Dívida sob gestão da Recovery" },
        paymentOptions: { tag: "Dívida sob gestão da Recovery" },
        creditCard: { tag: "Dívida sob gestão da Recovery" },
        agreementResume: { tag: "Dívida sob gestão da Recovery" },
      },
    },

    {
      hasPaymentUpdate: false,
      id: "brk",
      partnerId: "brk",
      domains: ["brk"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-brk.acordocerto.com.br",
      isPartner: true,
      name: "BRK Ambiental",
      friendlyName: "a BRK Ambiental",
      partnerColor: "#088426",
      parentPartnerId: "brk",
      logoName: "brk-ambiental",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "brkmairinque",
      partnerId: "brkmairinque",
      domains: ["saneaquamairinque"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-brk.acordocerto.com.br",
      isPartner: true,
      name: "BRK Ambiental",
      friendlyName: "a BRK Ambiental",
      partnerColor: "#1C97C4",
      parentPartnerId: "brkmairinque",
      logoName: "saneaqua-mairinque",
      hasAds: false,
    },

    {
      hasPaymentUpdate: false,
      id: "vivo",
      partnerId: "vivo",
      domains: ["vivo"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-vivo.acordocerto.com.br",
      isPartner: true,
      name: "Vivo",
      friendlyName: "a Vivo",
      partnerColor: "#7832AF",
      parentPartnerId: "vivo",
      logoName: "vivo",
      isRedirectSimulate: false,
    },

    {
      hasPaymentUpdate: false,
      id: "vivonext",
      partnerId: "vivonext",
      domains: ["vivonext"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-vivo.acordocerto.com.br",
      isPartner: true,
      name: "Vivo",
      friendlyName: "a Vivo",
      partnerColor: "#7832AF",
      parentPartnerId: "vivo",
      logoName: "vivonext",
      isRedirectSimulate: false,
    },

    {
      hasPaymentUpdate: false,
      id: "lojasrenner",
      partnerId: "lojasrenner",
      domains: ["lojasrenner"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-lojasrenner.acordocerto.com.br",
      isPartner: true,
      name: "Lojas Renner",
      friendlyName: "a Lojas Renner",
      partnerColor: "#EC4343",
      parentPartnerId: "lojasrenner",
      logoName: "lojas-renner",
      isRedirectSimulate: false,
    },

    {
      hasPaymentUpdate: false,
      id: "bancopan",
      partnerId: "bancopan",
      domains: ["bancopan"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-bancopan.acordocerto.com.br",
      isPartner: true,
      name: "Lojas Renner",
      friendlyName: "a Lojas Renner",
      partnerColor: "#0098FF",
      parentPartnerId: "bancopan",
      logoName: "banco-pan",
      isRedirectSimulate: false,
    },

    {
      hasPaymentUpdate: false,
      id: "havan",
      partnerId: "havan",
      domains: ["havan"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-integration-havan.acordocerto.com.br",
      isPartner: true,
      name: "Havan",
      friendlyName: "a Havan",
      partnerColor: "#135DF5",
      parentPartnerId: "havan",
      logoName: "havan",
      isRedirectSimulate: false,
    },

    {
      hasPaymentUpdate: false,
      id: "uzecartoes",
      partnerId: "uzecartoes",
      domains: ["uzecartoes"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-uzecartoes.acordocerto.com.br",
      isPartner: true,
      name: "Uze Cartões",
      friendlyName: "a Uze Cartões",
      partnerColor: "#7832AF",
      parentPartnerId: "uzecartoes",
      logoName: "uzecartoes",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "ativossa",
      partnerId: "ativossa",
      domains: ["ativossa"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/ativos",
      isPartner: true,
      name: "Ativos S.A.",
      friendlyName: "a Ativos S.A.",
      partnerColor: "#135DF5",
      parentPartnerId: "ativossa",
      logoName: "ativossa",
      isRedirectSimulate: false,
      hasAds: true,
      showVerifyDebtMessage: true,
    },

    {
      hasPaymentUpdate: false,
      id: "ativossa-nsd",
      partnerId: "ativossa-nsd",
      domains: ["ativossa-nsd"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/ativos",
      isPartner: true,
      name: "Ativos S.A.",
      friendlyName: "a Ativos S.A.",
      partnerColor: "#135DF5",
      parentPartnerId: "ativossa",
      logoName: "ativossa",
      isRedirectSimulate: false,
      hasAds: true,
      showVerifyDebtMessage: true,
    },

    {
      hasPaymentUpdate: true,
      id: "bradesco",
      partnerId: "bradesco",
      domains: ["bradesco"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-integration-bradesco.acordocerto.com.br",
      isPartner: true,
      name: "Bradesco",
      friendlyName: "o Bradesco",
      partnerColor: "#D52147",
      parentPartnerId: "bradesco",
      logoName: "bradesco",
      hasAds: true,
      isRedirectSimulate: false,

      pages: {
        simulation: {
          debtCard: {
            hasCurrentDebtValue: true,
          },
        },
        closedAgreement: {
          shouldShowClosedAgreementDisclaimer: true,
          closedAgreementDisclaimerText:
            "Caso o seu endereço no Bradesco esteja desatualizado, entre em contato com eles para realizar a mudança, ok?",
          shouldShowStepByStepAndIof: true,
          iofDisclamerText:
            "*O IOF (Imposto sobre Operações Financeiras) foi calculado conforme determinações do Banco Central do Brasil. A taxa foi financiada e já está inclusa no valor das parcelas do seu acordo.",
        },
      },
    },

    {
      hasPaymentUpdate: true,
      id: "bradesco-eavm",
      partnerId: "bradesco-eavm",
      domains: ["bradesco-eavm"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-integration-bradesco.acordocerto.com.br",
      isPartner: true,
      name: "Bradesco",
      friendlyName: "o Bradesco",
      partnerColor: "#D52147",
      parentPartnerId: "bradesco",
      logoName: "bradesco",
      hasAds: true,
      isRedirectSimulate: false,

      pages: {
        closedAgreement: {
          shouldShowClosedAgreementDisclaimer: true,
          closedAgreementDisclaimerText:
            "Caso o seu endereço no Bradesco esteja desatualizado, entre em contato com eles para realizar a mudança, ok?",
        },
      },
    },

    {
      hasPaymentUpdate: true,
      id: "banco-next",
      partnerId: "banco-next",
      domains: ["banco-next"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-integration-bradesco.acordocerto.com.br",
      isPartner: true,
      name: "Banco Next",
      friendlyName: "o Banco Next",
      partnerColor: "#00FF5F",
      parentPartnerId: "bradesco",
      logoName: "banco-next",
      hasAds: true,
      isRedirectSimulate: false,
    },

    {
      hasPaymentUpdate: false,
      id: "bancodobrasil",
      partnerId: "bancodobrasil",
      domains: ["bancodobrasil"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-bancodobrasil.acordocerto.com.br",
      isPartner: true,
      name: "Banco do Brasil",
      friendlyName: "o Banco do Brasil",
      partnerColor: "#465eff",
      parentPartnerId: "bancodobrasil",
      logoName: "banco-do-brasil",
      showBVSPartnership: true,
      hasAds: false,
      pages: {
        agreementDetails: {
          shouldUpdateAgreement: true,
        },
      },
    },

    {
      hasPaymentUpdate: false,
      id: "credsystem",
      partnerId: "credsystem",
      domains: ["credsystem"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-credsystem.acordocerto.com.br",
      isPartner: true,
      name: "Credsystem",
      friendlyName: "a Credsystem",
      partnerColor: "#333333",
      parentPartnerId: "credsystem",
      logoName: "credsystem",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "hoepers",
      partnerId: "hoepers",
      domains: ["hoepers"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-hoepers.acordocerto.com.br",
      isPartner: true,
      name: "Hoepers",
      friendlyName: "a Hoepers",
      partnerColor: "#FFC72A",
      parentPartnerId: "hoepers",
      logoName: "hoepers",
      isRedirectSimulate: false,
      hasAds: true,
      showVerifyDebtMessage: true,
    },

    {
      hasPaymentUpdate: false,
      id: "dacasa",
      partnerId: "dacasa",
      domains: ["dacasa"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-dacasa.acordocerto.com.br",
      isPartner: true,
      name: "DaCasa",
      friendlyName: "a DaCasa",
      partnerColor: "#088426",
      parentPartnerId: "dacasa",
      logoName: "dacasa",
      isRedirectSimulate: false,
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "bradescard",
      partnerId: "bradescard",
      domains: ["bradescard"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-bradescard-api.acordocerto.com.br",
      isPartner: true,
      name: "Bradescard",
      friendlyName: "o Bradescard",
      partnerColor: "#D52147",
      parentPartnerId: "bradescard",
      logoName: "bradescard",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "bradescard-losango",
      partnerId: "bradescard-losango",
      domains: ["bradescard-losango"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-bradescard-api.acordocerto.com.br",
      isPartner: true,
      name: "Losango",
      friendlyName: "a Losango",
      partnerColor: "#E71F2A",
      parentPartnerId: "bradescard",
      logoName: "bradescard-losango",
      hasAds: false,
      pages: {
        simulation: {
          debtCard: {
            title: "Dívida do Bradesco Financiamentos",
            cardDisclaimer:
              "Este contrato está disponível para negociação " +
              "apenas nos canais do produto Losango, com uma condição especial para você. " +
              "Para mais informações sobre a dívida, ou em caso de não reconhecimento, entre " +
              "em contato diretamente com o Bradesco Financiamentos.",
            ctaButtonLabel: "Negocie com o Bradesco Financiamentos",
          },
        },
      },
    },

    {
      hasPaymentUpdate: false,
      id: "bradescard-p1",
      partnerId: "bradescard-p1",
      domains: ["bradescard-p1"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-bradescard-api.acordocerto.com.br",
      isPartner: true,
      name: "Bradescard",
      friendlyName: "o Bradescard",
      partnerColor: "#D52147",
      parentPartnerId: "bradescard",
      logoName: "bradescard",
      hasAds: false,
    },

    {
      hasPaymentUpdate: false,
      id: "sky",
      partnerId: "sky",
      domains: ["sky"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-sky.acordocerto.com.br",
      isPartner: true,
      name: "Sky",
      friendlyName: "a Sky",
      partnerColor: "#E71F2A",
      parentPartnerId: "sky",
      logoName: "sky",
      isRedirectSimulate: false,
    },

    {
      hasPaymentUpdate: false,
      id: "dmcard",
      partnerId: "dmcard",
      domains: ["dmcard"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-dmcard.acordocerto.com.br",
      isPartner: true,
      name: "DM",
      friendlyName: "a DM",
      partnerColor: "#202AD0",
      parentPartnerId: "dmcard",
      logoName: "dmcard",
      hasAds: true,
    },

    {
      hasPaymentUpdate: false,
      id: "carrefour",
      partnerId: "carrefour",
      domains: ["carrefour"],
      apiUrl:
        "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/carrefour",
      isPartner: true,
      name: "Banco Carrefour",
      friendlyName: "o Banco Carrefour",
      partnerColor: "#00529C",
      parentPartnerId: "carrefour",
      logoName: "carrefour",
    },

    {
      hasPaymentUpdate: false,
      id: "carrefour-atacadao",
      partnerId: "carrefour-atacadao",
      domains: ["carrefour-atacadao"],
      apiUrl:
        "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/carrefour",
      isPartner: true,
      name: "Atacadão",
      friendlyName: "o Atacadão",
      partnerColor: "#EC6419",
      parentPartnerId: "carrefour",
      logoName: "carrefour-atacadao",
    },

    {
      hasPaymentUpdate: false,
      id: "carrefour-samsclub",
      partnerId: "carrefour-samsclub",
      domains: ["carrefour-samsclub"],
      apiUrl:
        "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/carrefour",
      isPartner: true,
      name: "Sam's Club",
      friendlyName: "a Sam's Club",
      partnerColor: "#11224B",
      parentPartnerId: "carrefour",
      logoName: "carrefour-samsclub",
    },

    {
      hasPaymentUpdate: true,
      id: "mercadopago",
      partnerId: "mercadopago",
      domains: ["mercadopago"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-mercadopago.acordocerto.com.br",
      isPartner: true,
      name: "Mercado Pago",
      friendlyName: "o Mercado Pago",
      partnerColor: "#00B1EA",
      parentPartnerId: "mercadopago",
      logoName: "mercadopago",
      isRedirectSimulate: false,
    },

    {
      hasPaymentUpdate: false,
      id: "trigg",
      partnerId: "trigg",
      domains: ["trigg"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-trigg.acordocerto.com.br",
      isPartner: true,
      name: "Trigg",
      friendlyName: "a Trigg",
      partnerColor: "#0BBAB2",
      parentPartnerId: "trigg",
      logoName: "trigg",
      isRedirectSimulate: false,
    },

    {
      hasPaymentUpdate: false,
      id: "btg",
      partnerId: "btg",
      domains: ["btg"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-default.acordocerto.com.br",
      isPartner: true,
      name: "BTG Pactual",
      friendlyName: "o BTG Pactual",
      partnerColor: "#081C64",
      parentPartnerId: "btg",
      logoName: "btg",
      isRedirectSimulate: false,
    },

    {
      hasPaymentUpdate: false,
      id: "liftcred",
      partnerId: "liftcred",
      domains: ["liftcred"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-default.acordocerto.com.br",
      isPartner: true,
      name: "Liftcred",
      friendlyName: "a Liftcred",
      partnerColor: "#24ff00",
      parentPartnerId: "liftcred",
      logoName: "liftcred",
      isRedirectSimulate: false,
      showVerifyDebtMessage: true,
    },

    {
      hasPaymentUpdate: false,
      id: "original",
      partnerId: "original",
      domains: ["original"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-original.acordocerto.com.br",
      isPartner: true,
      name: "Banco Original",
      friendlyName: "o Banco Original",
      partnerColor: "#20ac44",
      parentPartnerId: "original",
      logoName: "original",
      isRedirectSimulate: false,

      pages: {
        simulation: {
          debtCard: { shouldShowOrigin: true },
          debtDetailsModal: { shouldShowOrigin: true },
        },
      },
    },
    {
      hasPaymentUpdate: false,
      id: "nubank",
      partnerId: "nubank",
      domains: ["nubank"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-nubank.acordocerto.com.br",
      isPartner: true,
      name: "Nubank",
      friendlyName: "o Nubank",
      partnerColor: "#771AC8",
      parentPartnerId: "nubank",
      logoName: "nubank",
      isRedirectSimulate: false,
      showVerifyDebtMessage: true,
    },
    {
      hasPaymentUpdate: false,
      id: "vivocyber",
      partnerId: "vivocyber",
      domains: ["vivocyber"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-vivo.acordocerto.com.br",
      isPartner: true,
      name: "Vivo",
      friendlyName: "a Vivo",
      partnerColor: "#7832AF",
      parentPartnerId: "vivo",
      logoName: "vivo",
      isRedirectSimulate: false,
    },
    {
      hasPaymentUpdate: false,
      id: "edp",
      partnerId: "edp",
      domains: ["edp"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-partner-edp.acordocerto.com.br",
      isPartner: true,
      name: "EDP",
      friendlyName: "a EDP",
      partnerColor: "#3772f5",
      parentPartnerId: "edp",
      logoName: "edp",
      isRedirectSimulate: false,
    },
    {
      hasPaymentUpdate: false,
      id: "cielo",
      partnerId: "cielo",
      domains: ["cielo"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/cielo",
      isPartner: true,
      name: "Cielo",
      friendlyName: "a Cielo",
      partnerColor: "#1eb4e6",
      parentPartnerId: "cielo",
      logoName: "cielo",
      isRedirectSimulate: false,
    },
		{
      hasPaymentUpdate: false,
      id: "sophus",
      partnerId: "sophus",
      domains: ["sophus"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-integration-sophus.acordocerto.com.br",
      isPartner: true,
      name: "Sophus",
      friendlyName: "a Sophis",
      partnerColor: "#1eb4e6",
      parentPartnerId: "sophus",
      logoName: "sophus",
      isRedirectSimulate: false,
    },
    {
      hasPaymentUpdate: false,
      id: "asp",
      partnerId: "asp",
      domains: ["asp"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/asp",
      isPartner: true,
      name: "ASP",
      friendlyName: "a ASP",
      partnerColor: "#1eb4e6",
      parentPartnerId: "asp",
      logoName: "asp",
      isRedirectSimulate: false,
    },
    {
      hasPaymentUpdate: false,
      id: "omni",
      partnerId: "omni",
      domains: ["omni"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/omni",
      isPartner: true,
      name: "Omni",
      friendlyName: "a Omni",
      partnerColor: "#1eb4e6",
      parentPartnerId: "omni",
      logoName: "omni",
      isRedirectSimulate: false,
    },
    {
      hasPaymentUpdate: false,
      id: "cencosud",
      partnerId: "cencosud",
      domains: ["cencosud"],
      apiUrl: "https://ac-reneg-balcao-api.acordocerto.com.br/balcao-api/v1/proxy/https://ac-providers-api.acordocerto.com.br/v1/partner/cencosud",
      isPartner: true,
      name: "Cencosud",
      friendlyName: "a Cencosud",
      partnerColor: "#1eb4e6",
      parentPartnerId: "cencosud",
      logoName: "cencosud",
      isRedirectSimulate: false,
    },
  ] as EnvContext[],
};
