export default class EncoderUtils {
  private static base64: any = (window as any).Base64;

  static encode(value: string): string {
    return this.base64.encode(value);
  }

  static decode(value: string): any {
    return this.base64.decode(value);
  }

  static convertBase64ToBlob(
    value: string,
    type: string = "application/pdf;base64"
  ): Blob {
    var byteCharacters = atob(value);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: `${type};charset=UTF-8` });
  }
}
