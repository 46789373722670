<div class="register-page">
    <div class="register-container">
      <img
        src="assets/icons/ac-icon.svg"
        alt="acordo-certo-logo"
        class="ac-logo -m-bottom-3"
      />
      <h2 class="description">
        Ops! <strong>Não conseguimos prosseguir com seu cadastro por aqui</strong>
      </h2>
      <p class="error-description">
        Precisamos de mais informações para validar sua identidade.
      </p>
      <div>
        <img
        class="img-icone"
        width="25" height="25"
        src="assets/icons/adicionar_Usuário.svg"
        alt="Link externo" />
        <span class="titulo-form">Solicitação de cadastro</span>
        <div div style="padding-left: 20px;">
            <p class="error-description">
                Esse processo vai acontecer no site do Consumidor Positivo, após seu
                cadastro efetuado e validado, você poderá pela própria plataforma
                <strong>ter acesso aos serviços da Acordo Certo.</strong>
            </p>
        </div>
      </div>

      <div>
        <img
        class="img-icone"
        width="23" height="23"
        src="assets/icons/edicao_lapis_regua.svg"
        alt="Link externo" />
        <span class="titulo-form">Preencha o formulário</span>
        <div style="padding-left: 20px;">
            <p class="error-description">
              Selecione o assunto <strong>"Validar identidade"</strong>, em 
              anexo coloque:<br>
              <li style="padding-left: 15px;">Foto frente e verso do seu RG e CPF ou da sua CNH;</li>
              <li style="padding-left: 15px;">Sua selfie segurando o respectivo documento de identificação.</li>
            </p>
        </div>
      </div>
  
      <a href="https://painel.consumidorpositivo.com.br/atendimento">
        <button
          type="submit"
          class="button-primary -m-auto"
          (click)="handleOkClick()">
          <img
          style="margin-right: 5px"
          class="img"
          width="25" height="25"
          src="assets/icons/icone-link-externo.svg"
          alt="Link externo" />
          <span>Fazer solicitação</span>
        </button>
      </a>
  
      <div class="error-image-container">
        <img class="error-image" src="assets/img/pesquisa-erro.svg" alt="" />
      </div>
    </div>
  
    <div class="side-container">
      <img class="side-image" src="assets/img/pesquisa-erro.svg" alt="" />
    </div>
  </div>
  